import type { ReactElement } from 'react';
import { Grid, Typography } from '@mui/joy';

const KeyValuePair = ({ keyData, value }: { keyData: string; value: string }): ReactElement => {
  return (
    <>
      <Grid xs={4}>
        <Typography level="body-xs">{keyData}:</Typography>
      </Grid>
      <Grid xs={8}>
        <Typography
          level="title-xs"
          sx={{
            textAlign: 'right',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
          }}
        >
          {value}
        </Typography>
      </Grid>
    </>
  );
};

const GCardKeyValuePairs = ({ data }: { data: { key: string; value: string }[] }): ReactElement => {
  return (
    <Grid container rowGap={1.5} rowSpacing={0}>
      {data
        .filter(({ value }) => !!value)
        .map(({ key, value }) => (
          <KeyValuePair key={`${key}-${value}`} keyData={key} value={value} />
        ))}
    </Grid>
  );
};

export default GCardKeyValuePairs;
