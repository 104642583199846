import type { ReactElement } from 'react';
import { Stack, Typography } from '@mui/joy';
import { defaultRowSpacing } from '../../StackSpacing.ts';
import { UserSettings } from '../../management/UserSettings.types.ts';
import GAgGridPresets from '../../technical/grids/GAgGridPresets.tsx';
import { customColumnTypes } from '../../technical/grids/customColumnTypes.ts';
import capitalize from 'lodash/fp/capitalize';
import type { Response } from './UnifiedStressTest.types.ts';
import { unifiedStressTestRefValuesDefaultPresets } from './unifiedStressTestPresets.ts';

const UnifiedStressTestResultReferenceValues = ({ response }: { response: Response }): ReactElement => {
  return (
    <Stack gap={defaultRowSpacing}>
      <Typography level="h3">Summary</Typography>
      <GAgGridPresets
        presetSettingsKey={UserSettings.StressTestResultsRefValues}
        defaultPresets={unifiedStressTestRefValuesDefaultPresets}
        rowData={response}
        enableCharts
        cellSelection
        columnTypes={customColumnTypes}
        height="300px"
        defaultColDef={{
          resizable: true,
          sortable: true,
        }}
        columnDefs={[
          {
            headerName: 'Portfolio',
            colId: 'portfolio',
            type: 'textColumn',
            field: 'name',
          },
          {
            headerName: 'P&L',
            colId: 'pnl',
            marryChildren: true,
            children: [
              {
                headerName: 'P&L (net present value)',
                type: 'cashColumn',
                field: 'referenceEvaluation.fairValue',
              },
              {
                headerName: 'P&L (market value)',
                type: 'cashColumn',
                field: 'referenceEvaluation.marketValue',
              },
            ],
          },
          {
            headerName: 'Greeks',
            colId: 'greeks',
            marryChildren: true,
            children: (['delta', 'gamma', 'rho', 'vega', 'theta'] as const).map((field) => ({
              headerName: capitalize(field),
              type: 'extendedNumericColumn',
              field: `referenceEvaluation.greeks.${field}`,
            })),
          },
        ]}
      />
    </Stack>
  );
};

export default UnifiedStressTestResultReferenceValues;
