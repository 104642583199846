import type { DefaultColorPalette, Theme } from '@mui/joy/styles/types';
import dayjs from 'dayjs';
import { isNil } from 'lodash/fp';
import { bignumber, isNegative, isPositive, isZero } from 'mathjs';
import {
  type PredefinedRange,
  type PredefinedRangeLabel,
  getRangeByLabel,
  range7Days,
} from 'components/predefinedDateRanges';
import type { IUserSettingsQuery } from 'generated/graphql';

export type TileValues =
  | {
      change: number | undefined;
      minValue: number | undefined;
      maxValue: number | undefined;
      progressValue: number | undefined;
      latestValue: number | undefined;
    }
  | undefined;

const RETURNS_COLORING_THRESHOLD = 0.005;

export const getReturnsColor = (value: string | number, theme: Theme): string => {
  const numValue = bignumber(value).toNumber();
  if (numValue >= RETURNS_COLORING_THRESHOLD) {
    return theme.palette.success.plainColor;
  }

  if (numValue <= -RETURNS_COLORING_THRESHOLD) {
    return theme.palette.danger.plainColor;
  }

  return theme.palette.primary.softHoverBg;
};

export const getChangeColor = (value: number): DefaultColorPalette => {
  if (isPositive(value)) {
    return 'success';
  }

  if (isNegative(value)) {
    return 'danger';
  }
  return 'neutral';
};

export const getTileValues = (data: { value: number | string }[] | undefined): TileValues => {
  if (!data || data.length === 0) {
    return;
  }

  const latestValue = bignumber(data.at(-1)?.value).toNumber()!;

  const yesterdayValue = data.length > 1 ? bignumber(data.at(-2)?.value).toNumber() : undefined;

  const change =
    yesterdayValue && !isZero(yesterdayValue)
      ? bignumber(latestValue).minus(yesterdayValue).div(yesterdayValue).toNumber()
      : undefined;

  const values = data.map((row) => bignumber(row.value).toNumber());

  const minValue = Math.min(...values);
  const maxValue = Math.max(...values);

  // how far is the latest value from minimum, latest = minimum -> 0%, latest = maximum -> 100%
  let progressValue: number | undefined = undefined;
  if (!isNil(latestValue) && minValue !== maxValue) {
    progressValue = (100 * (latestValue - minValue)) / (maxValue - minValue);
  }

  return {
    change,
    minValue,
    maxValue,
    progressValue,
    latestValue,
  };
};

export function getSavedDaysRange(userSettingsQueryData: IUserSettingsQuery): {
  savedTimeFrameInDays: number;
  savedDateRange: PredefinedRange;
} {
  const dateRangeLabel: PredefinedRangeLabel | undefined = userSettingsQueryData.management.userSettings;
  let savedDateRange = dateRangeLabel ? getRangeByLabel(dateRangeLabel) : undefined;
  if (!savedDateRange || !savedDateRange.value()) {
    savedDateRange = range7Days;
  }
  const since = savedDateRange.value()![0]; // default range value() is always defined
  const savedTimeFrameInDays = dayjs.utc().diff(since, 'day') + 1;
  return {
    savedTimeFrameInDays,
    savedDateRange,
  };
}
