import type { ReactElement } from 'react';
import { Controller, type FieldValues, type Path, useFormContext } from 'react-hook-form';
import type { DistributiveOmit } from '../../type.utils.ts';
import FileUploadInput, { type FileUploadInputProps } from '../inputs/FileUploadInput.tsx';

function FormFileUploadInput<T extends FieldValues>(
  props: DistributiveOmit<FileUploadInputProps, 'name' | 'onChange'> & {
    name: Path<T>;
    onChange?: (file: File) => Awaited<void>;
  }
): ReactElement {
  /* jscpd:ignore-start */
  const { control } = useFormContext();

  return (
    <Controller
      name={props.name}
      control={control}
      /* jscpd:ignore-end */
      render={({ field: { value, onChange, onBlur, ref }, fieldState, formState }): ReactElement => (
        <FileUploadInput
          {...props}
          ref={ref}
          onChange={(text): void => {
            onChange(text);
            props.onChange?.(text);
          }}
          onBlur={(e): void => {
            props?.onBlur?.(e);
            onBlur();
          }}
          error={fieldState.error?.message}
          variant={value ? 'solid' : 'outlined'}
          disabled={(props.disabled ?? false) || formState.isSubmitting || formState.disabled}
        >
          {value ? 'File selected' : 'Select a file'}
        </FileUploadInput>
      )}
    />
  );
}

export default FormFileUploadInput;
