import dayjs, { type Dayjs } from 'dayjs';
import { isEmpty, sortBy } from 'lodash/fp';
import { type FunctionComponent, useMemo } from 'react';
import Message from 'components/technical/Message';
import type { SubAccountAssetFilters } from 'components/technical/SubAccountAssetFilterDrawer/UseSubAccountAssetFilters.tsx';
import {
  IBrinsonAttributionType,
  type IPerformanceAttributionQuery,
  usePerformanceAttributionSuspenseQuery,
} from 'generated/graphql.tsx';
import { AggregationMethod } from './PerformanceAttributionService.ts';
import { convertDateRangeToSinceToDate } from 'components/technical/inputs/date/dateRange.utils.ts';
import GAgGrid from 'components/technical/grids/GAgGrid.tsx';
import type {
  ColDef,
  ColGroupDef,
  GetDetailRowDataParams,
  ValueFormatterParams,
  ValueGetterParams,
} from 'ag-grid-community';
import { customColumnTypes } from 'components/technical/grids/customColumnTypes.ts';
import { DateTimeFormat, formatDate, formatPercentage } from 'components/formatter.utils.ts';
import { Grid, Typography } from '@mui/joy';
import TitleValueTile from 'components/technical/Tile/TitleValueTile.tsx';
import { parseUtcDate } from 'components/date.utils.ts';

const detailGridHeight = 500;

type BrinsonAttributionReportProps = {
  aggregationMethod: AggregationMethod.BrinsonFachler | AggregationMethod.BrinsonHoodBeebower;
  subAccountAssetFilters: SubAccountAssetFilters;
  dateRange: [Dayjs, Dayjs] | null;
  classification: string;
  benchmark: null | { id: string };
};

const defaultColDef = {
  resizable: true,
  sortable: true,
  filter: true,
};

type PerformanceClusterAttributions = IPerformanceAttributionQuery['portfolio']['performanceClusterAttributions'];

type DateLevelRow = {
  date: UtcDate;
  cumulativeAllocationEffect: number | null;
  cumulativeSelectionEffect: number | null;
  cumulativeInteractionEffect: number | null;
  dailyAllocationEffect: number | null;
  dailySelectionEffect: number | null;
  dailyInteractionEffect: number | null;
};

type TopLevelRow = {
  category: string; // group name in reality
  effectsByDate: DateLevelRow[];
};

type Effect =
  | 'cumulativeAllocationEffect'
  | 'cumulativeSelectionEffect'
  | 'cumulativeInteractionEffect'
  | 'dailyAllocationEffect'
  | 'dailySelectionEffect'
  | 'dailyInteractionEffect';

function mergeEffect(
  byCategory: Map<string, Map<UtcDate, DateLevelRow>>,
  performanceClusterAttributions: PerformanceClusterAttributions,
  effect: Effect
): void {
  for (const allocationForDate of performanceClusterAttributions[effect]) {
    for (const categoryValue of allocationForDate.categoryValues) {
      const categoryRecord = byCategory.get(categoryValue.category) ?? new Map<UtcDate, DateLevelRow>();
      const date = allocationForDate.date;
      const dateRecord =
        categoryRecord.get(date) ??
        ({
          date,
          cumulativeAllocationEffect: null,
          cumulativeSelectionEffect: null,
          cumulativeInteractionEffect: null,
          dailyAllocationEffect: null,
          dailySelectionEffect: null,
          dailyInteractionEffect: null,
        } satisfies DateLevelRow);

      dateRecord[effect] = categoryValue.value;

      categoryRecord.set(date, dateRecord);
      byCategory.set(categoryValue.category, categoryRecord);
    }
  }
}

function mergeDataByCategory(performanceClusterAttributions: PerformanceClusterAttributions): TopLevelRow[] {
  // category -> date (string) -> values
  const byCategory = new Map<string, Map<UtcDate, DateLevelRow>>();

  mergeEffect(byCategory, performanceClusterAttributions, 'cumulativeAllocationEffect');
  mergeEffect(byCategory, performanceClusterAttributions, 'cumulativeSelectionEffect');
  mergeEffect(byCategory, performanceClusterAttributions, 'cumulativeInteractionEffect');
  mergeEffect(byCategory, performanceClusterAttributions, 'dailyAllocationEffect');
  mergeEffect(byCategory, performanceClusterAttributions, 'dailySelectionEffect');
  mergeEffect(byCategory, performanceClusterAttributions, 'dailyInteractionEffect');

  return Array.from(byCategory.entries()).map(([category, dateLevelRows]) => ({
    category,
    effectsByDate: sortBy((x) => parseUtcDate(x.date), Array.from(dateLevelRows.values())),
  }));
}

const columnDefs: (ColDef<TopLevelRow, unknown> | ColGroupDef<TopLevelRow>)[] = [
  {
    field: 'category',
    headerName: 'Group',
    type: 'textColumn',
    cellRenderer: 'agGroupCellRenderer',
  },
  {
    headerName: 'Allocation',
    type: ['numericColumn', 'percentageColumn'],
    valueGetter: (params: ValueGetterParams<TopLevelRow>): number | undefined | null =>
      params.data?.effectsByDate.at(-1)?.cumulativeAllocationEffect,
  },
  {
    headerName: 'Selection',
    type: ['numericColumn', 'percentageColumn'],
    valueGetter: (params: ValueGetterParams<TopLevelRow>): number | undefined | null =>
      params.data?.effectsByDate.at(-1)?.cumulativeSelectionEffect,
  },
  {
    headerName: 'Interaction',
    type: ['numericColumn', 'percentageColumn'],
    valueGetter: (params: ValueGetterParams<TopLevelRow>): number | undefined | null =>
      params.data?.effectsByDate.at(-1)?.cumulativeInteractionEffect,
  },
];

const detailGridParams = {
  getDetailRowData: (params: GetDetailRowDataParams<TopLevelRow>): void =>
    params.successCallback(params.data.effectsByDate),
  detailGridOptions: {
    columnTypes: customColumnTypes,
    columnDefs: [
      {
        headerName: 'Date',
        valueGetter: (params: ValueGetterParams<DateLevelRow>): Date | undefined => {
          if (!params.data) {
            return undefined;
          }

          return dayjs(params.data.date.toString()).toDate();
        },
        valueFormatter: (params: ValueFormatterParams<DateLevelRow>): string =>
          formatDate(params.value, DateTimeFormat.Date),
        sort: 'desc',
      },
      {
        headerName: 'Allocation',
        marryChildren: true,
        children: [
          {
            headerName: 'Daily',
            type: ['numericColumn', 'percentageColumn'],
            valueGetter: (params: ValueGetterParams<DateLevelRow>): number | undefined | null =>
              params.data?.dailyAllocationEffect,
          },
          {
            headerName: 'Cumulative',
            type: ['numericColumn', 'percentageColumn'],
            valueGetter: (params: ValueGetterParams<DateLevelRow>): number | undefined | null =>
              params.data?.cumulativeAllocationEffect,
          },
        ],
      },
      {
        headerName: 'Selection',
        marryChildren: true,
        children: [
          {
            headerName: 'Daily',
            type: ['numericColumn', 'percentageColumn'],
            valueGetter: (params: ValueGetterParams<DateLevelRow>): number | undefined | null =>
              params.data?.dailySelectionEffect,
          },
          {
            headerName: 'Cumulative',
            type: ['numericColumn', 'percentageColumn'],
            valueGetter: (params: ValueGetterParams<DateLevelRow>): number | undefined | null =>
              params.data?.cumulativeSelectionEffect,
          },
        ],
      },
      {
        headerName: 'Interaction',
        marryChildren: true,
        children: [
          {
            headerName: 'Daily',
            type: ['numericColumn', 'percentageColumn'],
            valueGetter: (params: ValueGetterParams<DateLevelRow>): number | undefined | null =>
              params.data?.dailyInteractionEffect,
          },
          {
            headerName: 'Cumulative',
            type: ['numericColumn', 'percentageColumn'],
            valueGetter: (params: ValueGetterParams<DateLevelRow>): number | undefined | null =>
              params.data?.cumulativeInteractionEffect,
          },
        ],
      },
    ],
  },
};

const BrinsonAttributionReport: FunctionComponent<BrinsonAttributionReportProps> = ({
  aggregationMethod,
  subAccountAssetFilters,
  dateRange,
  classification,
  benchmark,
}) => {
  const performanceAttributionQueryResult = usePerformanceAttributionSuspenseQuery({
    variables: {
      filters: {
        attributionType:
          aggregationMethod === AggregationMethod.BrinsonFachler
            ? IBrinsonAttributionType.BrinsonFachler
            : IBrinsonAttributionType.BrinsonHoodBeebower,
        clusterType: classification,
        dateRange: convertDateRangeToSinceToDate(dateRange),
        subAccountAssetFilter: subAccountAssetFilters,
        benchmarkId: benchmark?.id,
      },
    },
  });

  const attribution = performanceAttributionQueryResult.data?.portfolio.performanceClusterAttributions;

  const isAttributionDataEmpty = Object.values(attribution ?? {})
    .filter((item) => Array.isArray(item))
    .every((item) => isEmpty(item));

  const data = performanceAttributionQueryResult.data?.portfolio.performanceClusterAttributions;

  const mergedData = useMemo(() => {
    if (!data) {
      return [];
    }
    return mergeDataByCategory(data);
  }, [data]);

  if (isAttributionDataEmpty) {
    return <Message>No data</Message>;
  }

  return (
    <>
      <Grid container alignItems="stretch" spacing={1.5}>
        <TitleValueTile
          loading={false}
          title={<Typography lineHeight={2}>Portfolio TWR</Typography>}
          value={formatPercentage(data.cumulativePortfolioReturns.at(-1)?.value)}
        />
        <TitleValueTile
          loading={false}
          title={<Typography lineHeight={2}>Benchmark TWR</Typography>}
          value={formatPercentage(data.cumulativeBenchmarkReturns.at(-1)?.value)}
        />
        <TitleValueTile
          loading={false}
          title={<Typography lineHeight={2}>Overperformance</Typography>}
          value={formatPercentage(data.cumulativeOverperformance.at(-1)?.value)}
        />
        <TitleValueTile
          loading={false}
          title={<Typography lineHeight={2}>Trading activity</Typography>}
          value={formatPercentage(data.cumulativeTradingActivity.at(-1)?.value)}
        />
      </Grid>
      <GAgGrid<TopLevelRow>
        rowData={mergedData}
        sideBar={{
          toolPanels: ['columns', 'filters'],
        }}
        enableCharts
        cellSelection
        defaultColDef={defaultColDef}
        masterDetail
        detailRowHeight={detailGridHeight}
        detailCellRendererParams={detailGridParams}
        columnDefs={columnDefs}
      />
    </>
  );
};

export default BrinsonAttributionReport;
