import type { ColGroupDef, GetDetailRowDataParams, ValueFormatterParams, ValueGetterParams } from 'ag-grid-community';
import { isNil } from 'lodash/fp';
import type { FunctionComponent } from 'react';
import { DateTimeFormat, formatDate } from 'components/formatter.utils';
import { UserSettings } from 'components/management/UserSettings.types';
import GAgGridPresets from 'components/technical/grids/GAgGridPresets';
import type { IAssetContributionsQuery } from 'generated/graphql';

import { assetContributionDefaultPresets } from './assetContributionDefaultPresets';
import type { UseReportAssetGroupResultLoaded } from '../../../UseReportAssetGroups';
import dayjs from 'dayjs';
import { type CustomColumnTypes, customColumnTypes } from 'components/technical/grids/customColumnTypes';
import { assetCellRenderer, assetNameGetter } from 'components/technical/grids/agGrid.utils';
import { clusterColumn, underlyingAssetColumn } from 'components/technical/grids/SharedReportColumns';

type OriginalContributionRow =
  IAssetContributionsQuery['portfolio']['performanceAssetContributions']['assetContributions'][number];

type DayData = OriginalContributionRow['dailySnapshotValues'][number];
type RowData = OriginalContributionRow & {
  lastDay: DayData;
  firstDay: DayData;
};

type AssetAttributionGridProps = {
  reportAssetGroup: UseReportAssetGroupResultLoaded;
  assetContributions: RowData[];
};

const reportGridHeight = '85vh'; // give almost all space to the grid

type ValueFieldType = Exclude<keyof RowData['firstDay'], '__typename' | 'date'>;

function createFirstLastDeltaColumns<TRow extends RowData>({
  initialField,
  headerName,
  columnType,
  finalField = initialField,
  sort,
}: {
  initialField: ValueFieldType;
  headerName: string;
  columnType: CustomColumnTypes;
  finalField?: ValueFieldType;
  sort?: 'asc' | 'desc';
}): ColGroupDef<TRow> {
  return {
    headerName,
    marryChildren: true,
    children: [
      {
        headerName: 'Initial',
        // biome-ignore lint/suspicious/noExplicitAny:
        field: `firstDay.${initialField}` as any,
        type: ['numericColumn', columnType],
      },
      {
        headerName: 'Final',
        // biome-ignore lint/suspicious/noExplicitAny:
        field: `lastDay.${finalField}` as any,
        type: ['numericColumn', columnType],
        sort,
      },
      {
        headerName: 'Delta',
        type: ['numericColumn', columnType],
        valueGetter: (params: ValueGetterParams<TRow>): number | undefined => {
          if (!params.data) {
            return undefined;
          }

          const firstDayValue = params.data.firstDay[initialField];
          const lastDayValue = params.data.lastDay[finalField];
          return isNil(firstDayValue) || isNil(lastDayValue) ? undefined : lastDayValue - firstDayValue;
        },
      },
    ],
  };
}

const AssetAttributionGrid: FunctionComponent<AssetAttributionGridProps> = ({
  assetContributions,
  reportAssetGroup,
}) => {
  return (
    <GAgGridPresets<RowData>
      presetSettingsKey={UserSettings.AssetContributionPresets}
      defaultPresets={assetContributionDefaultPresets}
      rowData={assetContributions}
      sideBar={{
        toolPanels: ['columns', 'filters'],
      }}
      enableCharts
      cellSelection
      height={reportGridHeight}
      defaultColDef={{
        resizable: true,
        sortable: true,
        filter: true,
      }}
      masterDetail
      detailCellRendererParams={{
        detailGridOptions: {
          columnDefs: [
            {
              headerName: 'Date',
              valueGetter: (params: ValueGetterParams<DayData>): Date | undefined => {
                if (!params.data) {
                  return undefined;
                }

                return dayjs(params.data.date.toString()).toDate();
              },
              valueFormatter: (params: ValueFormatterParams<DayData>): string =>
                formatDate(params.value, DateTimeFormat.Date),
              sort: 'desc',
            },
            {
              headerName: 'Contribution to returns',
              field: 'cumulativeContributionsToReturn',
              type: ['numericColumn', 'percentageColumn'],
            },
            {
              headerName: 'Daily contribution to returns',
              field: 'dailyContributionToReturn',
              type: ['numericColumn', 'percentageColumn'],
            },
            {
              headerName: 'Cumulative returns',
              field: 'cumulativeAssetReturn',
              type: ['numericColumn', 'percentageColumn'],
            },
            {
              headerName: 'Daily Returns',
              field: 'dailyAssetReturn',
              type: ['numericColumn', 'percentageColumn'],
            },
            {
              headerName: 'Cash weight',
              field: 'cashWeight',
              type: ['numericColumn', 'percentageColumn'],
            },
            {
              headerName: 'Amount',
              field: 'amount',
              type: ['numericColumn', 'extendedNumericColumn'],
            },

            {
              headerName: 'Exposure',
              field: 'exposure',
              type: ['numericColumn', 'cashColumn'],
            },

            {
              headerName: 'Open price',
              field: 'openPrice',
              type: ['numericColumn', 'cashColumn'],
            },
            {
              headerName: 'Close Price',
              field: 'closePrice',
              type: ['numericColumn', 'cashColumn'],
            },
          ],
          columnTypes: customColumnTypes,
        },
        getDetailRowData: (params: GetDetailRowDataParams<RowData>) =>
          params.successCallback(params.data.dailySnapshotValues),
      }}
      columnDefs={[
        {
          headerName: 'Asset Details',
          marryChildren: true,
          children: [
            {
              // we can not override cellRenderer because of subgrid, so we can not use shared symbolColumn
              colId: 'symbol',
              headerName: 'Symbol',
              type: 'textColumn',
              cellRenderer: 'agGroupCellRenderer',
              valueGetter: assetNameGetter,
              cellRendererParams: {
                innerRenderer: assetCellRenderer,
              },
            },
            underlyingAssetColumn(),
            ...reportAssetGroup.clusters.map((cluster) =>
              clusterColumn<RowData>(cluster, reportAssetGroup.assetAndGroupClusterMapToGroup)
            ),
          ],
        },
        {
          headerName: 'Performance',
          marryChildren: true,
          children: [
            {
              headerName: 'Contribution to returns',
              field: 'lastDay.cumulativeContributionsToReturn',
              type: ['numericColumn', 'percentageColumn'],
            },
            {
              headerName: 'Asset returns',
              field: 'lastDay.cumulativeAssetReturn',
              type: ['numericColumn', 'percentageColumn'],
            },
            createFirstLastDeltaColumns({
              initialField: 'cashWeight',
              headerName: 'Cash weight',
              columnType: 'percentageColumn',
            }),
            createFirstLastDeltaColumns({ initialField: 'exposure', headerName: 'Exposure', columnType: 'cashColumn' }),
            createFirstLastDeltaColumns({
              initialField: 'amount',
              headerName: 'Amount',
              columnType: 'extendedNumericColumn',
            }),
            createFirstLastDeltaColumns({
              initialField: 'openPrice',
              finalField: 'closePrice',
              headerName: 'Price',
              columnType: 'cashColumn',
            }),
          ],
        },
      ]}
    />
  );
};
export default AssetAttributionGrid;
