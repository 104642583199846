import { Add, DeleteOutline, Edit } from '@mui/icons-material';
import { Stack, Tab, TabList, TabPanel, Tabs, Typography } from '@mui/joy';
import { isNil, uniq } from 'lodash/fp';
import { type FunctionComponent, type ReactElement, useState } from 'react';
import { useFeedback } from 'components/technical/Feedback/UseFeedback.tsx';
import ConfirmationDialog from 'components/technical/form/dialog/ConfirmationDialog';
import DialogButton from 'components/technical/inputs/GButton/DialogButton';
import { useSyncSubAccountAssetFilterWithCurrentSubFunds } from 'components/technical/SubAccountAssetFilterDrawer/UseSubAccountAssetFilters.tsx';
import { useDefaultErrorHandling } from 'components/technical/UseDefaultErrorHandling';
import {
  type ISubFundsQuery,
  SubFundsDocument,
  useCreateSubFundMutation,
  useDeleteDimensionSubFundsMutation,
  useRenameDimensionMutation,
  useSubFundsQuery,
} from 'generated/graphql';

import { defaultRowSpacing } from 'components/StackSpacing.ts';
import CreateRenameDimensionDialog from './CreateRenameDimensionDialog.tsx';
import DimensionSubFundsTable from './DimensionSubFundsTable.tsx';
import ChangeSubFundAccountsDialog from './ChangeSubFundAccountsDialog.tsx';

export type SubFund = ISubFundsQuery['portfolio']['subFunds']['list'][number];

export type SubFundCorrelationAssets = ISubFundsQuery['assets']['feature'];

const SubFundsDashboardContainer: FunctionComponent = () => {
  const subFundsInputQueryResult = useDefaultErrorHandling(useSubFundsQuery());

  if (!subFundsInputQueryResult.loaded) {
    return <subFundsInputQueryResult.Fallback />;
  }

  const subFunds = subFundsInputQueryResult.data.portfolio.subFunds.list;
  const accounts = subFundsInputQueryResult.data.portfolio.accounts;
  const correlationAssets = subFundsInputQueryResult.data.assets.feature;

  return <SubFundsDashboard subFunds={subFunds} accounts={accounts} correlationAssets={correlationAssets} />;
};

type SubFundsDashboardProps = {
  subFunds: SubFund[];
  accounts: ISubFundsQuery['portfolio']['accounts'];
  correlationAssets: SubFundCorrelationAssets;
};

const tabsWidth = 300;

const SubFundsDashboard: FunctionComponent<SubFundsDashboardProps> = ({ subFunds, accounts, correlationAssets }) => {
  useSyncSubAccountAssetFilterWithCurrentSubFunds(subFunds);

  const refetchFunds = { refetchQueries: [SubFundsDocument] };
  const [createSubFund] = useCreateSubFundMutation(refetchFunds);
  const [renameDimension] = useRenameDimensionMutation(refetchFunds);
  const [deleteDimensionSubFunds] = useDeleteDimensionSubFundsMutation(refetchFunds);

  const { showGraphqlError, showSuccessMessage } = useFeedback();

  const dimensions = uniq(subFunds.map((subFund) => subFund.dimension));
  const [selectedDimension, setSelectedDimension] = useState<string | null>(dimensions[0] ?? null);
  const selectedDimensionSubFunds = subFunds.filter((subFund) => subFund.dimension === selectedDimension);

  const existingSubFunds = new Set(subFunds.map((subFund) => subFund.name));

  return (
    <>
      <Stack direction="row" gap={defaultRowSpacing} alignItems="center">
        <Stack direction="row" minWidth={tabsWidth} gap={defaultRowSpacing} justifyContent="space-between" pl={2}>
          <Typography level="h4">Dimensions</Typography>
          <DialogButton
            startDecorator={<Add />}
            variant="outlined"
            renderDialog={({ onClose }): ReactElement => (
              <CreateRenameDimensionDialog
                onClose={onClose}
                title="New dimension"
                existingDimensions={dimensions}
                submitButtonText="Create"
                handleFormSubmit={async (newDimensionFormData, onErrorAndThrow): Promise<void> => {
                  try {
                    await createSubFund({
                      variables: {
                        subFund: {
                          dimension: newDimensionFormData.dimension,
                          accounts: [],
                          subAccounts: [],
                        },
                      },
                    });
                  } catch (e) {
                    onErrorAndThrow(e);
                  }

                  setSelectedDimension(newDimensionFormData.dimension);
                  onClose();
                }}
              />
            )}
          >
            Add
          </DialogButton>
        </Stack>

        {!isNil(selectedDimension) && (
          <>
            <Typography level="h4" noWrap flexShrink={0}>
              {selectedDimension}
            </Typography>
            <DialogButton
              paddingVariant="short"
              variant="plain"
              startDecorator={<Edit />}
              renderDialog={({ onClose }): ReactElement => (
                <CreateRenameDimensionDialog
                  onClose={onClose}
                  title={`Rename dimension ${selectedDimension}`}
                  existingDimensions={dimensions}
                  submitButtonText="Rename"
                  currentDimension={selectedDimension}
                  handleFormSubmit={async (subFund, onErrorAndThrow): Promise<void> => {
                    try {
                      await renameDimension({
                        variables: {
                          oldDimension: selectedDimension,
                          newDimension: subFund.dimension,
                        },
                      });
                    } catch (e) {
                      onErrorAndThrow(e);
                    }

                    setSelectedDimension(subFund.dimension);
                    onClose();
                    showSuccessMessage('Dimension successfully renamed');
                  }}
                />
              )}
            />

            <Stack direction="row-reverse" gap={defaultRowSpacing} width="100%" px={2} alignItems={'center'}>
              <DialogButton
                startDecorator={<DeleteOutline />}
                variant="soft"
                color="danger"
                paddingVariant="short"
                renderDialog={({ onClose }): ReactElement => (
                  <ConfirmationDialog
                    onClose={onClose}
                    onApprove={async (): Promise<void> => {
                      try {
                        await deleteDimensionSubFunds({
                          variables: {
                            dimension: selectedDimension,
                          },
                        });
                      } catch (e) {
                        onClose();
                        showGraphqlError(e);
                        return;
                      }

                      setSelectedDimension(dimensions.find((dimension) => dimension !== selectedDimension) ?? null);

                      showSuccessMessage(`All sub-funds in dimension ${selectedDimension} successfully deleted`);
                      onClose();
                    }}
                  >
                    Are you sure you want to remove all {selectedDimension} dimension sub-funds?
                  </ConfirmationDialog>
                )}
              />

              <DialogButton
                startDecorator={<Add />}
                variant="outlined"
                renderDialog={({ onClose }): ReactElement => (
                  <ChangeSubFundAccountsDialog
                    onClose={onClose}
                    correlationAssets={correlationAssets}
                    accounts={accounts.filter(
                      (account) =>
                        !selectedDimensionSubFunds.some(
                          (subFund) => !subFund.isDefault && subFund.accounts.some((acc) => acc.id === account.id)
                        )
                    )}
                    initialState={{
                      name: '',
                      accounts: [],
                      referenceAsset: {
                        id: '',
                        name: '',
                      },
                      dimension: selectedDimension,
                      isDefault: false,
                      id: 0,
                      createdAt: '',
                    }}
                    existingSubFunds={existingSubFunds}
                    handleFormSubmit={async (subFundFormData, onErrorAndThrow): Promise<void> => {
                      try {
                        await createSubFund({
                          variables: {
                            subFund: {
                              name: subFundFormData.name,
                              dimension: selectedDimension,
                              accounts: subFundFormData.accounts.map((account) => account.id),
                              subAccounts: [],
                              referenceAsset: subFundFormData.referenceAsset,
                            },
                          },
                        });
                      } catch (e) {
                        onErrorAndThrow(e);
                      }

                      showSuccessMessage('Sub-fund successfully updated');
                      onClose();
                    }}
                  />
                )}
              >
                Add sub-fund
              </DialogButton>
            </Stack>
          </>
        )}
      </Stack>

      {dimensions.length === 0 && (
        <Typography px={2} level="body-md">
          You haven&apos;t added any dimensions yet. Click &quot;Add&quot; to get started.
        </Typography>
      )}

      {dimensions.length > 0 && (
        <Tabs
          orientation="vertical"
          onChange={(_e, newSelectedDimension) => setSelectedDimension(String(newSelectedDimension))}
          value={selectedDimension}
          size="lg"
          sx={{ height: '100%' }}
        >
          <TabList sx={{ width: tabsWidth, flexShrink: 0 }}>
            {dimensions.map((dimension) => (
              <Tab key={dimension} value={dimension}>
                <Typography level="body-md" sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
                  {dimension}
                </Typography>
              </Tab>
            ))}
          </TabList>

          {!isNil(selectedDimension) && (
            <TabPanel value={selectedDimension} sx={{ padding: 0, height: '100%' }}>
              <DimensionSubFundsTable
                correlationAssets={correlationAssets}
                dimensionSubFunds={selectedDimensionSubFunds}
                existingSubFunds={existingSubFunds}
                accounts={accounts}
                dimensions={dimensions}
                setSelectedDimension={setSelectedDimension}
              />
            </TabPanel>
          )}
        </Tabs>
      )}
    </>
  );
};

export default SubFundsDashboardContainer;
