import type { SxProps } from '@mui/system';
import type { SystemStyleObject } from '@mui/system/styleFunctionSx/styleFunctionSx';
import type { Theme } from '@mui/joy';

export const selectableItemsClass = 'genie-portfolioDefList-item';

export const calculateItemProps = (
  selected: boolean,
  id: string
): { className: string; cardSx: SxProps<Theme>; dataset: Record<string, string>; selectable: true } => {
  return {
    className: selectableItemsClass,
    dataset: {
      id,
    },
    selectable: true,
    cardSx: (theme: Theme): SystemStyleObject<Theme> => ({
      border: `2px solid ${selected ? theme.palette.primary[500] : 'transparent'}`,
      '& > *': {
        userSelect: 'none',
      },
    }),
  };
};
