import { type ReactElement, useState } from 'react';
import type { SelectOption } from '../../../technical/inputs/Select/Select.props.ts';
import {
  dateTimeAxisFormat,
  dateTimeExportFormat,
  type HighchartSeries,
  noYAxisTitle,
  tooltipFormat,
} from '../../../technical/charts/HighChartsWrapper/Highchart.utils.ts';
import { formatNumber } from '../../../formatter.utils.ts';
import { visibleStats } from '../FactorRegressionService.ts';
import type { PortfoliosResult } from './PortfolioResult.types.ts';
import dayjs from 'dayjs';
import { bignumber } from 'mathjs';
import type * as Highcharts from 'highcharts';
import HighChartsContainer from '../../../technical/charts/HighChartsWrapper/HighChartsWrapper.tsx';
import HeaderBar from '../../../technical/HeaderBar/HeaderBar.tsx';
import { Select } from '../../../technical/inputs';
import { Stack } from '@mui/joy';

const selectStatOptions: SelectOption<'fValue' | 'rSquared' | 'alphaValue'>[] = [
  {
    value: 'fValue',
    label: 'F Value',
    key: 'fvalue',
  },
  {
    value: 'rSquared',
    label: 'R Squared',
    key: 'rSquared',
  },
  {
    value: 'alphaValue',
    label: 'Alpha',
    key: 'alphaValue',
  },
];

const calculateOptions = (): Highcharts.Options => {
  return {
    ...dateTimeAxisFormat,
    ...tooltipFormat,
    ...dateTimeExportFormat('historical-factor-stats'),
    ...noYAxisTitle,
  };
};

const calculateChartData = (
  data: PortfoliosResult,
  selectedStat: 'fValue' | 'rSquared' | 'alphaValue'
): HighchartSeries[] => {
  return data.map((portfolioResult) => ({
    data: portfolioResult.results.map((row) => ({
      x: dayjs.utc(row.date.toString()).valueOf(),
      y: bignumber(row[selectedStat]).toNumber(),
      textValue: formatNumber(bignumber(row[selectedStat]).toNumber()),
    })),
    name: portfolioResult.portfolioDefinition.name,
    type: 'line',
  }));
};

const FactorHistoricalChart = (props: { result: PortfoliosResult }): ReactElement => {
  const [selectedStat, setSelectedStat] = useState<'fValue' | 'rSquared' | 'alphaValue'>(visibleStats[0]);
  return (
    <>
      <HeaderBar title={'Historical factor stats'} />
      <Stack spacing={2}>
        <Select
          label="Stat"
          value={selectedStat}
          onChange={(value): void => setSelectedStat(value)}
          options={selectStatOptions}
          width="normal"
        />
        <HighChartsContainer<PortfoliosResult>
          loading={false}
          data={props.result}
          calculateOptions={calculateOptions}
          calculateChartData={(data): HighchartSeries[] => calculateChartData(data, selectedStat)}
        />
      </Stack>
    </>
  );
};

export default FactorHistoricalChart;
