import { Stack } from '@mui/joy';
import { createColumnHelper } from '@tanstack/react-table';
import isNil from 'lodash/fp/isNil';
import type { ReactElement } from 'react';
import { formatCash, formatPercentage } from 'components/formatter.utils';
import AssetLabel from 'components/market/asset/AssetLabel';
import { getAssetCategory } from 'components/market/asset/AssetService';
import { priceActionClusterId } from 'components/market/asset/groups/GroupService';
import { defaultRowSpacing } from 'components/StackSpacing';
import GTable from 'components/technical/GTable/GTable';
import {
  type IAssetOptimizerResultQuery,
  IConstraintFormulation,
  ILeverageType,
  type IPortfolioOptimizerResultQuery,
} from 'generated/graphql';

import CardWithHeader from './CardWithHeader';
import {
  type AssetResultInput,
  formatConstraintBound,
  type PortfolioResultInput,
  type ResultInput,
} from './OptimizerInputForResult.utils.ts';
import PrimaryPortfolioConstraint from './PrimaryPortfolioConstraint';
import SecondaryPortfolioConstraint from './SecondaryPortfolioConstraint';
import ValueWithLabel from './ValueWithLabel';
import {
  returnMeasureLabels,
  shouldShowRiskBudgetAllocation,
} from '../../wizard/assumptionsAndOutlook/AssumptionsAndOutlook.validation';
import { secondaryConstraintQuantityLabels } from '../../wizard/portfolioConstraints/PortfolioSecondaryConstraint.validation';
import { sortBy } from 'lodash/fp';
import { primaryConstraintQuantityLabels } from '../../wizard/portfolioConstraints/PortfolioPrimaryConstraint.validation.ts';
import { getObjectiveLabel } from '../../objective.utils.ts';
import { groupConstrainedQuantityLabels } from '../../wizard/allocationConstraints/AllocationConstraintsStep.validation.ts';
import { OptimizationType } from '../../optimization.utils.ts';
import { PortfolioDefinitionLabel } from 'components/copilot/lab/PortfolioDefinitionLabel.tsx';
import type { GColumnDef } from 'components/technical/GTable/GTable.props.ts';
import { portfolioRebalancingRuleGetter } from '../../wizard/portfolio/PortfolioDefinitionSharedColumns.ts';
import type { TupleKeyMap } from '../../../../TupleKeyMap.ts';

type GroupRowType = IAssetOptimizerResultQuery['optimization']['getOptimizationInput']['groupConstraints'][number];

const columnHelper = createColumnHelper<RowType>();
const groupsColumnHelper = createColumnHelper<{
  group: GroupRowType['group'];
  constrainedQuantity: GroupRowType['allocationConstraints'][number]['constrainedQuantity'];
  constraint: GroupRowType['allocationConstraints'][number]['constraint'];
}>();

type RowType = Pick<
  (PortfolioResultInput | AssetResultInput)['constraints'][number],
  'userExpectedReturn' | 'riskBudget' | 'constraint'
> & {
  id?: string;
  name?: string;
  maxLeverage?: number | null;
  yield?: number;
  cashWeight?: number;
  asset?: AssetResultInput['givenPortfolio'][number]['asset'] | null;
  portfolio?: PortfolioResultInput['givenPortfolio'][number]['fund'] | null;
};

const getConstraintsId = (optimizationType: OptimizationType, data: ResultInput['constraints'][number]): string => {
  if (optimizationType === OptimizationType.portfolio) {
    return (data as PortfolioResultInput['constraints'][number]).fund.id;
  }

  return (data as AssetResultInput['constraints'][number]).asset.id;
};

const getRowFromConstraints = (
  optimizationType: OptimizationType,
  data: ResultInput['constraints'][number]
): RowType => {
  if (optimizationType === OptimizationType.portfolio) {
    const row = data as PortfolioResultInput['constraints'][number];
    return {
      id: row.fund.id,
      name: row.fund.id,
      asset: null,
      portfolio: row.fund,
      ...row,
    };
  }

  const row = data as AssetResultInput['constraints'][number];
  return {
    id: row.asset.id,
    name: row.asset.symbol,
    portfolio: null,
    ...row,
  };
};

const getRowFromGivenPortfolio = (
  optimizationType: OptimizationType,
  data: ResultInput['givenPortfolio'][number]
): Omit<RowType, 'cashWeight'> => {
  if (optimizationType === OptimizationType.portfolio) {
    const row = data as PortfolioResultInput['givenPortfolio'][number];
    return {
      id: row.fund.id,
      name: row.fund.id,
      asset: null,
      portfolio: row.fund,
    };
  }

  const row = data as AssetResultInput['givenPortfolio'][number];
  return {
    id: row.asset.id,
    name: row.asset.symbol,
    asset: row.asset,
    portfolio: null,
  };
};

const OptimizerInputForResultTab = ({
  optimizationType,
  data,
  clusters,
  assetAndGroupClusterMapToGroup,
}: {
  optimizationType: OptimizationType;
  data: IAssetOptimizerResultQuery | IPortfolioOptimizerResultQuery;
  clusters: string[];
  assetAndGroupClusterMapToGroup: TupleKeyMap<[string, string], string> | undefined;
}): ReactElement => {
  const isAssetOptimization = optimizationType === OptimizationType.asset;
  const optimizationInput = data.optimization.getOptimizationInput;
  const optimization = data.optimization.getOptimization;

  const mergedConstraints: Map<string, RowType> = new Map(
    optimizationInput.constraints.map((constraint) => [
      getConstraintsId(optimizationType, constraint),
      getRowFromConstraints(optimizationType, constraint),
    ])
  );

  for (const row of optimizationInput.givenPortfolio) {
    const { cashWeight } = row;
    const { asset, portfolio, name } = getRowFromGivenPortfolio(optimizationType, row);
    const id = asset?.id ?? portfolio?.id ?? '';
    const existingItemOrUndefined = mergedConstraints.get(id);
    mergedConstraints.set(id, { ...existingItemOrUndefined, cashWeight, asset, portfolio, name, id });
  }

  const items = sortBy((item) => item.name, Array.from(mergedConstraints.values()));

  const validClusters = clusters.filter((cluster) => cluster !== priceActionClusterId);

  const showRiskBudgetAllocation = shouldShowRiskBudgetAllocation(
    optimization.objectives.map((o) => ({ type: o.objectiveType }))
  );

  const leverageType = optimizationType === 'portfolio' ? null : (optimizationInput as AssetResultInput).leverageType;

  return (
    <Stack gap={defaultRowSpacing}>
      <Stack flexWrap="wrap" direction="row" gap={defaultRowSpacing}>
        <CardWithHeader title="Description">
          <ValueWithLabel label="Name">{optimization.name}</ValueWithLabel>
          <ValueWithLabel label="Description">{optimization.description}</ValueWithLabel>
        </CardWithHeader>

        <CardWithHeader title="Objectives">
          <ValueWithLabel label="Portfolio equity">{formatCash(optimizationInput.portfolioAmount)}</ValueWithLabel>
          {leverageType && (
            <ValueWithLabel label="Allow short and leverage">
              {leverageType === ILeverageType.LongShort ? 'Yes' : 'No, long only'}
            </ValueWithLabel>
          )}
          <ul>
            {optimization.objectives.map((objective, index) => (
              <li key={index}>{getObjectiveLabel(objective)}</li>
            ))}
          </ul>
        </CardWithHeader>
      </Stack>
      {!isNil(optimizationInput.portfolioConstraints?.primary) ||
      !isNil(optimizationInput.portfolioConstraints?.secondary) ? (
        <CardWithHeader title="Portfolio level constraints">
          <ul>
            {optimizationInput.portfolioConstraints?.primary && (
              <li>
                <PrimaryPortfolioConstraint
                  constraint={optimizationInput.portfolioConstraints.primary}
                  quantityLabel={
                    primaryConstraintQuantityLabels[optimizationInput.portfolioConstraints.primary.constrainedQuantity]
                  }
                />
              </li>
            )}
            {optimizationInput.portfolioConstraints?.secondary && (
              <li>
                <SecondaryPortfolioConstraint
                  constraint={optimizationInput.portfolioConstraints.secondary}
                  quantityLabel={
                    secondaryConstraintQuantityLabels[
                      optimizationInput.portfolioConstraints.secondary.constrainedQuantity
                    ]
                  }
                />
              </li>
            )}
          </ul>
        </CardWithHeader>
      ) : undefined}

      <CardWithHeader title="Assets">
        {optimizationInput.returnMeasureName && (
          <ValueWithLabel label="Forecasts">{returnMeasureLabels[optimizationInput.returnMeasureName]}</ValueWithLabel>
        )}

        <GTable
          data={items}
          columns={[
            ...(isAssetOptimization
              ? ([
                  columnHelper.accessor('asset.symbol', {
                    header: 'Asset',
                    cell: (info) => <AssetLabel asset={info.row.original.asset!} wrap={false} />,
                  }),
                  columnHelper.accessor((info) => getAssetCategory(info.asset!), {
                    header: 'Type',
                  }),
                ] satisfies GColumnDef<RowType>[])
              : ([
                  columnHelper.accessor('portfolio.name', {
                    header: 'Fund',
                    cell: (info) => <PortfolioDefinitionLabel definition={info.row.original.portfolio!} />,
                  }),
                  columnHelper.accessor((info) => info.portfolio!.createdFrom?.name ?? '', {
                    header: 'Rebalancing rule',
                    cell: (info) => portfolioRebalancingRuleGetter(info.row.original.portfolio!),
                  }),
                ] satisfies GColumnDef<RowType>[])),
            columnHelper.accessor('cashWeight', {
              header: 'Initial portfolio',
              cell: (info) => formatPercentage(info.getValue()),
            }),
            columnHelper.accessor('userExpectedReturn', {
              header: 'Expected return',
              cell: (info) => formatPercentage(info.getValue()),
            }),
            ...(isAssetOptimization
              ? [
                  // @ts-expect-error expectedYield is only present in one type - for asset optimization
                  columnHelper.accessor('expectedYield', {
                    header: 'Expected yield',
                    cell: (info) => formatPercentage(info.getValue() as number),
                  }),
                  ...(optimizationInput.constraintFormulation === IConstraintFormulation.AbsoluteValue
                    ? [columnHelper.accessor('maxLeverage', { header: 'Max leverage' })]
                    : []),
                ]
              : []),
            ...(showRiskBudgetAllocation
              ? [
                  columnHelper.accessor('riskBudget', {
                    header: 'Risk weight',
                    cell: (info) => formatPercentage(info.getValue()),
                  }),
                ]
              : []),
            columnHelper.accessor(
              (info) =>
                info.constraint
                  ? formatConstraintBound(info.constraint, optimizationInput.constraintFormulation)
                  : undefined,
              {
                header: 'Constraint',
              }
            ),
            ...(isAssetOptimization
              ? validClusters.map((cluster) =>
                  columnHelper.accessor((info) => assetAndGroupClusterMapToGroup?.get([info.asset!.id, cluster]), {
                    header: cluster,
                  })
                )
              : []),
          ]}
        />
      </CardWithHeader>

      {isAssetOptimization && (optimizationInput as AssetResultInput).groupConstraints.length > 0 && (
        <CardWithHeader title="Group constraints">
          <GTable
            data={(optimizationInput as AssetResultInput).groupConstraints.flatMap((group) =>
              group.allocationConstraints.map((constr) => ({
                ...constr,
                group: group.group,
              }))
            )}
            columns={[
              groupsColumnHelper.accessor('group.groupName', {
                header: 'Group',
              }),
              groupsColumnHelper.accessor('group.clusterName', {
                header: 'Cluster',
              }),
              groupsColumnHelper.accessor((info) => groupConstrainedQuantityLabels[info.constrainedQuantity], {
                header: 'Type',
              }),
              groupsColumnHelper.accessor(
                (info) => formatConstraintBound(info.constraint, optimizationInput.constraintFormulation),
                {
                  header: 'Constraint',
                }
              ),
            ]}
          />
        </CardWithHeader>
      )}
    </Stack>
  );
};

export default OptimizerInputForResultTab;
