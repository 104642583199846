import { Card, Stack } from '@mui/joy';
import dayjs from 'dayjs';
import type * as Highcharts from 'highcharts';
import isNil from 'lodash/fp/isNil';
import sortBy from 'lodash/fp/sortBy';
import { bignumber } from 'mathjs';
import { type FunctionComponent, useRef, useState } from 'react';
import { useParams } from 'react-router';
import { convertDateInUtcToUTCISODate } from 'components/date.utils';
import { formatCash, formatNumber } from 'components/formatter.utils';
import { TwoThirdsLayout } from 'components/TwoThirdsLayout.tsx';
import ActionsContextProvider from 'components/technical/actions/ActionsContextProvider.tsx';
import ActionsHeaderBar from 'components/technical/actions/ActionsHeaderBar';
import {
  cashFormatter,
  dateTimeAxisFormat,
  dateTimeExportFormat,
  type HighchartSeries,
  numberFormatter,
  tooltipFormat,
} from 'components/technical/charts/HighChartsWrapper/Highchart.utils';
import HighChartsContainer from 'components/technical/charts/HighChartsWrapper/HighChartsWrapper';
import LineChartSkeleton from 'components/technical/charts/LineChart/LineChartSkeleton';
import { Select } from 'components/technical/inputs';
import SectionColumn from 'components/technical/layout/Column/SectionColumn';
import { type IPublicAssetHoldingsQuery, usePublicAssetHoldingsQuery } from 'generated/graphql';

import EventSectionContainer from '../event/EventSectionContainer';

const DATE_RANGE = 30;

const OPTIONS = [
  { label: 'Value', key: 'value', value: 'value' as const },
  { label: 'Amount', key: 'amount', value: 'amount' as const },
];

const getChartValues = (
  rows: IPublicAssetHoldingsQuery['portfolio']['snapshot'],
  selectedOption: string
): HighchartSeries[] => {
  const chartData: Record<
    string,
    {
      data: { x: number; y: number; textValue: string }[];
      name: string;
      type: 'area';
    }
  > = {};

  for (const { positions, date } of rows) {
    for (const { spot, subAccount } of positions) {
      if (!isNil(spot)) {
        const subAccountName = subAccount.name;
        if (!chartData[subAccountName]) {
          chartData[subAccountName] = {
            data: [],
            name: subAccountName,
            type: 'area',
          };
        }

        const yValue =
          selectedOption === 'value' ? bignumber(spot.balance).toNumber() : bignumber(spot.amount).toNumber();

        chartData[subAccountName].data.push({
          x: dayjs.utc(date.toString()).valueOf(),
          y: yValue,
          textValue: selectedOption === 'value' ? formatCash(yValue) : formatNumber(yValue),
        });
      }
    }
  }

  return Object.values(chartData);
};

const sortSnapshotByDate = (data: IPublicAssetHoldingsQuery): IPublicAssetHoldingsQuery['portfolio']['snapshot'] => {
  return sortBy((row) => dayjs.utc(row.date.toString()).toDate(), data.portfolio.snapshot);
};

const PublicAssetHoldingsTab: FunctionComponent = () => {
  const [selectedOption, setSelectedOption] = useState<'value' | 'amount'>(OPTIONS[0].value);

  const { assetId } = useParams();
  const startDate = useRef(dayjs.utc().subtract(DATE_RANGE, 'days'));

  const query = usePublicAssetHoldingsQuery({
    variables: {
      assetId: assetId!,
      fromTime: convertDateInUtcToUTCISODate(startDate.current),
    },
  });

  const showChartPlaceholder =
    !query.loading && !query.error && !!query.data?.portfolio.snapshot.every((row) => row.positions.length === 0);

  return (
    <TwoThirdsLayout
      left={
        <SectionColumn>
          <ActionsContextProvider>
            <ActionsHeaderBar title={<>Balance and amount</>} />
            <Card>
              <Stack spacing={2}>
                <Select
                  label="Option"
                  value={selectedOption}
                  onChange={(value): void => setSelectedOption(value)}
                  options={OPTIONS}
                  width="normal"
                />
                {showChartPlaceholder ? (
                  <LineChartSkeleton />
                ) : (
                  <HighChartsContainer
                    {...query}
                    key={selectedOption}
                    calculateChartData={(data: IPublicAssetHoldingsQuery): HighchartSeries[] => {
                      const subAccountSnapshot = sortSnapshotByDate(data);
                      return getChartValues(subAccountSnapshot, selectedOption);
                    }}
                    calculateOptions={(data: IPublicAssetHoldingsQuery): Highcharts.Options => {
                      const subAccountSnapshots = sortSnapshotByDate(data);

                      const selectedAssetSymbol = subAccountSnapshots.find((row) => row.positions.length > 0)!
                        .positions[0].asset.symbol;

                      return {
                        ...dateTimeAxisFormat,
                        ...dateTimeExportFormat(
                          `${selectedAssetSymbol.toLowerCase()}-holdings-${
                            selectedOption === 'value' ? 'values' : 'amounts'
                          }`
                        ),
                        ...tooltipFormat,
                        yAxis: {
                          labels: {
                            formatter: selectedOption === 'value' ? cashFormatter : numberFormatter,
                          },
                          title: {
                            text: selectedOption === 'value' ? 'Value' : 'Amount',
                          },
                        },
                        plotOptions: {
                          area: {
                            stacking: 'normal',
                            fillOpacity: 0.5,
                            marker: {
                              symbol: 'circle',
                            },
                          },
                        },
                      };
                    }}
                  />
                )}
              </Stack>
            </Card>
          </ActionsContextProvider>
        </SectionColumn>
      }
      right={<EventSectionContainer assetId={assetId!} />}
    />
  );
};
export default PublicAssetHoldingsTab;
