import { useMemo, type ReactElement } from 'react';
import FormSingleAutocomplete from '../../../technical/form/FormSingleAutocomplete.tsx';
import { createAssetAutocompleteProps, useAccountAssetPaginatedOptions } from '../../../market/asset/AssetService.tsx';
import {
  type FormInputFields,
  type FormOutputFields,
  positionSide,
  slotType,
  transactionLegType,
} from './TransactionForm.validation.ts';
import FormInput from '../../../technical/form/FormInput.tsx';
import { Card, Grid, Stack } from '@mui/joy';
import { FormDateTimeInput } from '../../../technical/form/FormDateTimeInput.tsx';
import dayjs from 'dayjs';
import RemoveButton from '../../../technical/RemoveButton.tsx';
import { useFormState, useWatch } from 'react-hook-form';
import { useMarketValueAutocomplete } from '../UseMarketValueAutocomplete.tsx';
import type { SubAccountLabelInputAccount } from '../../../portfolio/account/SubAccountLabel.tsx';
import type { FormInputType } from 'components/technical/form/Form.types.ts';
import { DateTimeFormat } from 'components/formatter.utils.ts';
import { formatDate } from '../../../formatter.utils.ts';
import { useUserTimezone } from '../../../technical/UseUserTimezone.tsx';
import FormSelect from '../../../technical/form/FormSelect.tsx';
import InputSuggestion from '../../../technical/form/InputSugestion.tsx';
import { isValidNumber } from 'components/number.utils.ts';
import { TotalMarketValueSuggestion } from '../TotalMarketValueSuggestion.tsx';
import type { BigNumber } from 'mathjs';
import FormStaticSingleAutocomplete from '../../../technical/form/FormStaticSingleAutocomplete.tsx';

// Type guard to narrow down MarketAutocompleteQuery
function isPriceAvailable(
  query: ReturnType<typeof useMarketValueAutocomplete>
): query is { loading: false; error: false; price: { value: BigNumber; time: string } } {
  return !query.loading && !query.error && query.price !== undefined;
}

const TransactionLeg = ({
  index,
  onRemove,
  subAccount,
}: {
  index: number;
  onRemove: () => void;
  subAccount: FormInputType<SubAccountLabelInputAccount> | null;
}): ReactElement => {
  const timezone = useUserTimezone();
  const { isSubmitting } = useFormState<FormInputFields>();
  const pathPrefix = `legs.${index}` as const;
  const amount = useWatch<FormInputFields, `${typeof pathPrefix}.amount`>({
    name: `${pathPrefix}.amount`,
  });

  const marketValue = useWatch<FormInputFields, `${typeof pathPrefix}.marketValue`>({
    name: `${pathPrefix}.marketValue`,
  });

  const { getOptions } = useAccountAssetPaginatedOptions({
    account: subAccount?.account,
  });

  const transactionTime = useWatch<FormInputFields, 'time'>({
    name: 'time',
  });

  const legTime = useWatch<FormInputFields, `${typeof pathPrefix}.time`>({
    name: `${pathPrefix}.time`,
  });

  const asset = useWatch<FormInputFields, `${typeof pathPrefix}.asset`>({
    name: `${pathPrefix}.asset`,
  });

  const sharedInputProps = {
    width: 'fullWidth',
  } as const;

  const autocompleteValue = useMarketValueAutocomplete(legTime ?? transactionTime, asset?.id);

  const suggestedValue = useMemo(() => {
    if (isPriceAvailable(autocompleteValue) && isValidNumber(amount)) {
      return autocompleteValue.price.value.mul(amount).toString();
    }
    return null;
  }, [autocompleteValue, amount]);

  const isUserOverride = useMemo(() => {
    return marketValue !== suggestedValue && marketValue !== undefined;
  }, [marketValue, suggestedValue]);

  return (
    <Card>
      <Stack direction={'row'} alignItems={'stretch'} gap={3}>
        <Stack gap={3} flexGrow={1}>
          <Grid container spacing={3}>
            <Grid md={6} xs={12}>
              <FormSingleAutocomplete<FormInputFields>
                name={`${pathPrefix}.asset` as const}
                menuWidth="xl2"
                label="Asset"
                required
                {...createAssetAutocompleteProps()}
                getOptions={getOptions}
                {...sharedInputProps}
              />
            </Grid>
            <Grid md={6} xs={12}>
              <FormDateTimeInput<FormInputFields>
                label="Date and time (UTC)"
                showClearable
                name={`${pathPrefix}.time`}
                placeholder={formatDate(transactionTime, DateTimeFormat.ShortDateTime, timezone)}
                maxDate={dayjs.utc()}
                {...sharedInputProps}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid md={3} xs={12}>
              <FormInput name={`${pathPrefix}.amount`} type="number" label="Amount" required {...sharedInputProps} />
            </Grid>
            <Grid md={9} xs={12}>
              <InputSuggestion autoFill suggestion={suggestedValue}>
                <FormInput<FormOutputFields>
                  type="number"
                  name={`${pathPrefix}.marketValue` as const}
                  label="Total market value"
                  startDecorator="$"
                  endDecorator={
                    !isUserOverride && (
                      <TotalMarketValueSuggestion
                        suggestionData={
                          isPriceAvailable(autocompleteValue)
                            ? {
                                value: autocompleteValue.price.value.toString(),
                                time: autocompleteValue.price.time,
                              }
                            : undefined
                        }
                        loading={autocompleteValue.loading}
                        error={autocompleteValue.error}
                        // amount={amount}
                      />
                    )
                  }
                  {...sharedInputProps}
                />
              </InputSuggestion>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid md={4} xs={12}>
              <FormSelect<FormInputFields>
                options={slotType}
                name={`${pathPrefix}.slot`}
                label="State"
                required
                {...sharedInputProps}
              />
            </Grid>
            <Grid md={4} xs={12}>
              <FormStaticSingleAutocomplete<FormInputFields>
                options={transactionLegType}
                placeholder={'Type'}
                name={`${pathPrefix}.type`}
                label="Type"
                required
                {...sharedInputProps}
              />
            </Grid>
            <Grid md={4} xs={12}>
              <FormSelect<FormInputFields>
                options={positionSide}
                name={`${pathPrefix}.side`}
                label="Side"
                showClearable
                {...sharedInputProps}
              />
            </Grid>
          </Grid>
        </Stack>
        <RemoveButton
          color={'danger'}
          disabled={isSubmitting}
          onClick={(): void => {
            onRemove();
          }}
        />
      </Stack>
    </Card>
  );
};

export default TransactionLeg;
