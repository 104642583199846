import { NO_LABEL_HEIGHT_PX } from '../inputs/GInput/GInput';

const gridWithInputPadding = 5;

export const gridWithInputStyles = {
  inputCellStyle: {
    padding: gridWithInputPadding,
    border: 0,
  },
  rowHeight: NO_LABEL_HEIGHT_PX + 2 * gridWithInputPadding,
};
