import type { ReactElement } from 'react';
import { Controller, type FieldPathValue, type FieldValues, type Path, useFormContext } from 'react-hook-form';

import Select from '../inputs/Select/Select';
import type { SingleSelectProps } from '../inputs/Select/Select.props';
import type { DistributiveOmit } from '../../type.utils.ts';

const FormSelect = <T extends FieldValues, N extends Path<T> = Path<T>>(
  props: DistributiveOmit<SingleSelectProps<FieldPathValue<T, N>>, 'value'> & {
    name: N;
  }
): ReactElement => {
  const { control } = useFormContext<T>();
  return (
    <Controller
      name={props.name}
      control={control}
      render={({ field: { value, onChange, onBlur, ref }, fieldState, formState }): ReactElement => {
        return (
          <Select<FieldPathValue<T, N> | null>
            {...props}
            ref={ref}
            value={value}
            error={fieldState.error?.message ?? props.error}
            onChange={(value): void => {
              onChange(value);
              props.onChange?.(value as FieldPathValue<T, N>);
            }}
            onBlur={onBlur}
            disabled={(props.disabled ?? false) || formState.isSubmitting || formState.disabled}
          />
        );
      }}
    />
  );
};

export default FormSelect;
