import type { Dayjs } from 'dayjs';
import GAgGrid from 'components/technical/grids/GAgGrid';
import { isNil } from 'lodash/fp';
import type { FunctionComponent } from 'react';
import { formatNumber } from 'components/formatter.utils';
import { logErrorOnce } from 'components/log.utils';
import AssetLabel from 'components/market/asset/AssetLabel';
import { IconVariant } from 'components/market/asset/cryptocurrencies/CryptocurrenciesData';
import type { SubAccountAssetFilters } from 'components/technical/SubAccountAssetFilterDrawer/UseSubAccountAssetFilters';
import { useDefaultErrorHandling } from 'components/technical/UseDefaultErrorHandling.tsx';
import WarningMessage from 'components/technical/WarningMessage/WarningMessage';
import {
  IMultiLevelAttributionType,
  type IReturnsType,
  useBottomUpPerformanceAttributionQuery,
} from 'generated/graphql.tsx';
import { type BottomUpRowData, flatTreeDataAddPathHierarchy } from './BottomUpAttribution.utils';
import { convertDateRangeToSinceToDate } from 'components/technical/inputs/date/dateRange.utils';
import { createColumnToolDef } from '../../../technical/grids/agGrid.utils.tsx';

type BottomUpAttributionReportProps = {
  subAccountAssetFilters: SubAccountAssetFilters;
  dateRange: [Dayjs, Dayjs] | null;
  classification: string;
  returnsType: IReturnsType;
  benchmark: null | { id: string };
};

const MAX_DATA_LEVELS = 6;
const reportGridHeight = '85vh'; // give almost all space to the grid

const LevelNameOrAssetGroupRenderer: FunctionComponent<{ data: BottomUpRowData }> = ({ data }) => {
  if (data.name) {
    return <span style={{ textTransform: 'capitalize' }}>{data.name}</span>;
  }

  if (data.asset) {
    return <AssetLabel asset={data.asset} wrap={false} size={IconVariant.MEDIUM} />;
  }

  logErrorOnce('GroupCellRenderer: no data found to render', data);
  return null;
};

const BottomUpAttributionReport: FunctionComponent<BottomUpAttributionReportProps> = ({
  subAccountAssetFilters,
  dateRange,
  classification,
  returnsType,
  benchmark,
}) => {
  const { loaded, data, Fallback } = useDefaultErrorHandling(
    useBottomUpPerformanceAttributionQuery({
      variables: {
        input: {
          attributionType: IMultiLevelAttributionType.BottomUp,
          clusterType: classification,
          dateRange: convertDateRangeToSinceToDate(dateRange),
          returnsType,
          subAccountAssetFilter: subAccountAssetFilters,
          benchmarkId: benchmark?.id,
        },
      },
    })
  );

  if (!loaded) {
    return <Fallback />;
  }

  const reportData = data.portfolio.multilevelPerformanceAttributions;

  if (reportData.levelNames.length > MAX_DATA_LEVELS) {
    return (
      <WarningMessage showCard>Data has too many levels, currently supported {MAX_DATA_LEVELS} levels.</WarningMessage>
    );
  }

  const rowData = flatTreeDataAddPathHierarchy(reportData.data);

  return (
    <GAgGrid<BottomUpRowData>
      rowData={rowData}
      getDataPath={(data) => data.path}
      treeData
      height={reportGridHeight}
      autoSizeStrategy={{ type: 'fitCellContents' }}
      onGridReady={(grid) => grid.api.expandAll()}
      pivotPanelShow="never"
      autoGroupColumnDef={{
        headerName: '',
        minWidth: 280,
        cellRendererParams: {
          suppressCount: true,
          innerRenderer: LevelNameOrAssetGroupRenderer,
        },
        pinned: 'left',
      }}
      columnDefs={reportData.columns.map((column, index) => ({
        headerName: column,
        // show percentage without % sign as it's already in the header
        valueGetter: (params) => (!isNil(params.data?.values[index]) ? 100 * params.data.values[index]! : null),
        valueFormatter: (params) => (!isNil(params.value) ? formatNumber(params.value) : ''),
        suppressMenu: true,
        type: ['numericColumn', 'extendedNumericColumn'],
      }))}
      sideBar={{
        toolPanels: [
          createColumnToolDef({
            suppressRowGroups: true,
            suppressValues: true,
            suppressPivotMode: true,
          }),
        ],
      }}
    ></GAgGrid>
  );
};

export default BottomUpAttributionReport;
