import { Autocomplete as JoyAutocomplete, CircularProgress, FormControl, Box } from '@mui/joy';
import type { AutocompleteRenderOptionState } from '@mui/joy/Autocomplete';
import isNil from 'lodash/fp/isNil';
import type React from 'react';
import { type ReactElement, type ReactNode, useEffect, useMemo, useState } from 'react';
import { calculateContainerClasses, createJoyAutocompleteProps, type GOption } from './GAutocomplete';
import type { GSingleAutocompleteProps } from './GSingleAutocomplete.props';
import {
  VirtualizedListContext,
  type VirtualizedListContextType,
} from './VirtualizedListBox/VirtualizedListContext.tsx';
import { virtualizedRenderOptions } from './VirtualizedListBox/VirtualizedRenderOptions.tsx';
import InputError from '../InputError';
import InputLabel from '../InputLabel';
import { renderOption } from '../RenderOption/RenderOption.tsx';
import { useDebounce } from '../../../UseDebounce.ts';
import { useOptionsLoader } from '../../../../UseOptionsLoader.tsx';
import { useOpen } from '../../UseOpen.tsx';
import { shouldRenderInputLabel } from '../LabelService.ts';

function GSingleAutocomplete<TValue>(props: GSingleAutocompleteProps<TValue>): ReactElement {
  const { value, onChange, getOptions, getOptionLabel, renderOption: renderOptionProp, disabled } = props;
  const { open, onOpen, onClose } = useOpen();
  const [input, setInput] = useState(isNil(value) ? '' : getOptionLabel(value));
  const debouncedInput = useDebounce(input, 100);
  const { load, loading, options } = useOptionsLoader(getOptions);
  // avoid flashing of loading indicator
  const deboundedLoading = useDebounce(loading, 50);
  useEffect(() => {
    if (!open) {
      return;
    }

    load(debouncedInput);
  }, [debouncedInput, load, open]);

  const finalValue = useMemo(
    (): null | GOption<TValue> =>
      isNil(value)
        ? null
        : {
            type: 'regular',
            value,
          },
    [value]
  );

  const VirtualizedBoxContext = VirtualizedListContext as React.Context<VirtualizedListContextType<TValue>>;

  return (
    <Box sx={calculateContainerClasses(props)}>
      <FormControl error={!!props.error} color={props.color} disabled={props.disabled}>
        {shouldRenderInputLabel(props) ? <InputLabel {...props} /> : <></>}
        <VirtualizedBoxContext.Provider
          value={{
            optionHeight: props.optionHeight,
            getOptionKey: props.getOptionKey,
            hasGroups: !!props.groupBy,
            renderOption: (
              props: React.HTMLAttributes<HTMLLIElement>,
              option: TValue,
              state: AutocompleteRenderOptionState
            ): ReactNode => {
              return renderOption(renderOptionProp(props, option, state));
            },
            menuWidth: props.menuWidth ?? props.width,
          }}
        >
          <JoyAutocomplete<GOption<TValue>, false>
            {...createJoyAutocompleteProps(props)}
            onOpen={onOpen}
            onClose={onClose}
            options={options}
            // @ts-expect-error: seems like an error in joy types - no ref for multiple=true/false, but actually ref is required
            ref={props.ref}
            value={finalValue}
            onChange={(_event, value: GOption<TValue> | null): void => {
              if (isNil(value)) {
                onChange?.(null);
                return;
              }

              onChange?.(value.value ?? null);
            }}
            renderOption={virtualizedRenderOptions}
            onInputChange={(_event, value): void => {
              setInput(value);
            }}
            loading={deboundedLoading}
            readOnly={disabled}
            getOptionDisabled={(option): boolean => option.type !== 'regular'}
            endDecorator={
              deboundedLoading ? (
                <CircularProgress size="sm" sx={{ bgcolor: 'background.surface', '--CircularProgress-size': '20px' }} />
              ) : null
            }
          />
        </VirtualizedBoxContext.Provider>
        <InputError error={props.error} />
      </FormControl>
    </Box>
  );
}

export default GSingleAutocomplete;
