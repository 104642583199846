// GCard.tsx
import { Box, Card, type CardProps, Grid, Stack, type Theme, Typography } from '@mui/joy';
import type { FocusEvent, ReactElement, ReactNode, MouseEvent, KeyboardEvent, ForwardedRef } from 'react';
import { AddCircle } from 'components/technical/icons';
import type { Breakpoint, SxProps } from '@mui/system';
import mapKeys from 'lodash/fp/mapKeys';

export type GridWidth = Partial<Record<Breakpoint, number>>;

export interface GCardProps {
  gridWidth: GridWidth;
  height: string;
  onClick?: (e: FocusEvent | MouseEvent | KeyboardEvent) => void;
  selectable?: boolean;
  cardWrapperSx?: SxProps<Theme>;
  cardSx?: SxProps<Theme>;
  addNewText?: string;
  children?: ReactNode;
  size?: 'sm' | 'md' | 'lg';
  className?: string;
  dataset?: Record<string, string>;
  variant?: CardProps['variant'];
  title?: ReactNode;
  content?: ReactNode;
  ref?: ForwardedRef<HTMLDivElement>;
}

const BaseGCard = ({
  children,
  onClick,
  addNewText,
  height,
  gridWidth,
  cardWrapperSx,
  cardSx,
  size = 'lg',
  className,
  dataset,
  selectable,
  variant,
  title,
  content,
  ref,
}: GCardProps): ReactElement => {
  const dataProps: Record<string, string> = mapKeys((key) => `data-${key}`, dataset ?? {});

  const handleCardClick = (e: MouseEvent<HTMLDivElement> | KeyboardEvent<HTMLDivElement>) => {
    if (onClick) {
      onClick(e);
    }
  };

  const isClickable = Boolean(onClick) || selectable;

  const handleKeyPress = (e: KeyboardEvent<HTMLDivElement>) => {
    if (isClickable && (e.key === 'Enter' || e.key === ' ')) {
      e.preventDefault();
      handleCardClick(e);
    }
  };

  return (
    <Grid xl={gridWidth.xl} lg={gridWidth.lg} md={gridWidth.md} sm={gridWidth.sm} width="100%">
      <Box sx={{ minHeight: height, height: '100%', ...cardWrapperSx }} ref={ref} {...dataProps} className={className}>
        <Card
          onClick={isClickable ? handleCardClick : undefined}
          sx={[
            {
              height: '100%',
              cursor: isClickable ? 'pointer' : 'auto',
              padding: '.6rem',
            },
            ...(Array.isArray(cardSx) ? cardSx : [cardSx]).filter((sx) => !!sx),
          ]}
          size={size}
          variant={variant}
          role={isClickable ? 'button' : undefined}
          tabIndex={isClickable ? 0 : undefined}
          onKeyUp={isClickable ? handleKeyPress : undefined}
        >
          {children && children}
          {title && <Box mb={2}>{title}</Box>}
          {content && (
            <Box flexGrow={1} mb={2}>
              {content}
            </Box>
          )}
          {!children && addNewText && (
            <Stack height="100%" direction="column" alignItems="center" justifyContent="center">
              <AddCircle size="lg" className="add-circle" />
              <Typography level="title-sm" sx={{ color: 'secondary.200', marginTop: '.5rem' }}>
                {addNewText}
              </Typography>
            </Stack>
          )}
        </Card>
      </Box>
    </Grid>
  );
};

export default BaseGCard;
