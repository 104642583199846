import { createColumnHelper } from '@tanstack/react-table';
import type { CellContext } from '@tanstack/table-core';
import groupBy from 'lodash/fp/groupBy';
import sortBy from 'lodash/fp/sortBy';
import { type BigNumber, bignumber } from 'mathjs';
import type { FunctionComponent, ReactElement, ReactNode } from 'react';
import { formatPercentage } from 'components/formatter.utils.ts';
import { useSearchableAssetsIds } from 'components/technical/Appbar/AppbarSearch/useSearchableAssets.ts';
import GTable from 'components/technical/GTable/GTable';

import type { GColumnDef } from 'components/technical/GTable/GTable.props.ts';
import PreciseTooltip from 'components/technical/PreciseTooltip.tsx';
import { useScreenBreakpointDown } from 'components/technical/screenSizeUtils.ts';
import bigNumMath from '../../../bigNumMath.ts';
import type { IPortfolioExposureRiskQuery } from '../../../generated/graphql.tsx';
import AssetLabel from '../../market/asset/AssetLabel.tsx';
import type {
  ExchangeAssetLabelInput,
  PrivatePublicUnvestedAssetLabelInput,
} from '../../market/asset/AssetLabelService.ts';
import { VenueIconList } from '../../venue/VenueIconList.tsx';
import { calculateSummary, calculateUnrealizedGain } from '../account/SubAccountPositionsService.ts';
import { useTheme } from '@mui/joy';

type SubAccountPosition = IPortfolioExposureRiskQuery['portfolio']['positions']['positions'][number];
export type PositionDetails = Omit<NonNullable<SubAccountPosition['spot']>, '__typename'> & {
  asset: PrivatePublicUnvestedAssetLabelInput | ExchangeAssetLabelInput;
  subAccount: SubAccountPosition['subAccount'];
};

export type AssetPositionDetails = { positions: PositionDetails[] } & {
  summary: ReturnType<typeof calculateSummary>;
  asset: PrivatePublicUnvestedAssetLabelInput | ExchangeAssetLabelInput;
};

type PortfolioOpenSpotPositionListProps = {
  positions: PositionDetails[];
  costBasisPerAsset: Map<string, BigNumber>;
  portfolioBalance: BigNumber;
};

const PortfolioOpenSpotPositionList: FunctionComponent<PortfolioOpenSpotPositionListProps> = ({
  positions,
  costBasisPerAsset,
  portfolioBalance,
}): ReactElement => {
  const theme = useTheme();
  const navigableAssetIds = useSearchableAssetsIds();
  const isXsScreen = useScreenBreakpointDown('sm');

  const groupedByAssetId = groupBy((pos) => pos.asset.id, positions);

  const sortedAssetPositions = sortBy((positions) => {
    const subAccountPositions = bigNumMath.sum(
      positions.map((pos) => bignumber(pos.amount ?? 0).mul(bignumber(pos.price ?? 0)))
    );
    return subAccountPositions.abs().toNumber();
  }, Object.values(groupedByAssetId)).reverse();

  const sortedAssetPositionDetails = sortedAssetPositions.map((positions) => ({
    positions: positions,
    summary: calculateSummary(positions, portfolioBalance),
    asset: positions[0].asset,
  }));

  const columnHelper = createColumnHelper<AssetPositionDetails>();

  const columns: GColumnDef<AssetPositionDetails>[] = [
    {
      header: 'Asset',
      cell: (props: CellContext<AssetPositionDetails, unknown>) => (
        <AssetLabel asset={props.row.original.asset} link={navigableAssetIds.has(props.row.original.asset.id)} />
      ),
      accessorFn: (assetPositions) => assetPositions.asset.symbol,
    },
    ...(!isXsScreen
      ? [
          columnHelper.display({
            header: 'Sub-account',
            cell: (props: CellContext<AssetPositionDetails, unknown>) => (
              <VenueIconList
                accounts={props.row.original.positions.map((pos) => ({
                  name: pos.subAccount.name,
                  venue: pos.subAccount.account.venue,
                }))}
              />
            ),
          }),
        ]
      : []),
    {
      header: 'Quantity',
      cell: (props: CellContext<AssetPositionDetails, number>): ReactElement => (
        <PreciseTooltip format="number" value={props.getValue()} />
      ),
      accessorFn: (assetPositions) => assetPositions.summary.amount.toNumber(),
    },
    {
      header: 'Value',
      cell: (props: CellContext<AssetPositionDetails, number>): ReactElement => (
        <PreciseTooltip format="cash" value={props.getValue()} />
      ),
      accessorFn: (assetPositions) =>
        bignumber(assetPositions.summary.price ?? 0)
          .mul(bignumber(assetPositions.summary.amount ?? 0))
          .toNumber(),
    },
    {
      header: 'Balance %',
      cell: (props: CellContext<AssetPositionDetails, number | undefined>) => formatPercentage(props.getValue()),
      accessorFn: (assetPositions) => assetPositions.summary.balanceContribution?.toNumber(),
      meta: {
        headerTooltip: 'Contribution to balance',
      },
    },
    {
      header: 'Profit & loss',
      cell: (props: CellContext<AssetPositionDetails, unknown>): ReactNode => {
        const unitCostBasis = costBasisPerAsset.get(props.row.original.asset.id);
        const summary = props.row.original.summary;
        if (!summary.price || !unitCostBasis) {
          return '-';
        }

        const unrealizedGain = calculateUnrealizedGain(summary.amount, summary.price, unitCostBasis);
        return <PreciseTooltip format="cash" value={unrealizedGain.toNumber()} />;
      },
    },
    {
      header: 'Cost basis',
      cell: (props: CellContext<AssetPositionDetails, unknown>): ReactNode => {
        const unitCostBasis = costBasisPerAsset.get(props.row.original.asset.id);
        if (!unitCostBasis) {
          return '-';
        }

        const summary = props.row.original.summary;
        const costBasis = unitCostBasis.mul(summary.amount);
        return <PreciseTooltip format="cash" value={costBasis.toNumber()} />;
      },
    },
  ];

  return (
    <GTable
      columns={columns}
      data={sortedAssetPositionDetails}
      disablePagination
      stickyHeader
      fullHeight
      hideBorders
      headerBackground={theme.palette.background.surface}
    />
  );
};
export default PortfolioOpenSpotPositionList;
