import { Box, Chip, FormControl, Stack, Typography, useTheme } from '@mui/joy';
import type { CSSProperties, ForwardedRef, ReactElement, ReactNode } from 'react';
import ChipDelete from '@mui/joy/ChipDelete';
import InputLabel from '../InputLabel';
import InputError from '../InputError';
import widthSx from '../../../width.styles.ts';
import isNil from 'lodash/fp/isNil';

export type SelectedChipsBoardProps<TValue> = {
  value: TValue[];
  onDelete: (itemToDelete: TValue) => void;
  error?: string;
  disabled?: boolean;
  placeholder?: string;
  label?: string;
  onBlur?: () => void;
  width?: 'fullWidth' | 'smaller' | 'small' | 'normal' | 'xl2' | 'xl3' | 'xl4';
  height: CSSProperties['height'];
  getItemKey: (value: TValue) => string;
  renderItem: (value: TValue) => ReactNode;
  ref?: ForwardedRef<HTMLElement>;
};

/** board of chips with possibility to remove by clicking remove icon */
function SelectedChipsBoard<TValue>({
  placeholder,
  value,
  onDelete,
  height,
  width,
  error,
  disabled,
  label,
  getItemKey,
  renderItem,
  ref,
}: SelectedChipsBoardProps<TValue>): ReactElement {
  const theme = useTheme();
  const allWidthSx = (!isNil(width) && width !== 'fullWidth' && widthSx[width]) || {};
  return (
    <Box sx={allWidthSx} ref={ref}>
      <FormControl error={Boolean(error)} disabled={disabled}>
        {!!label && <InputLabel label={label} />}
        <Stack
          direction="row"
          sx={{
            alignItems: 'center',
            alignContent: 'flex-start',
            flexWrap: 'wrap',
            padding: 0.5,
            gap: 0.5,
            overflow: 'auto',
            height: height,
            borderRadius: theme.radius.md,
            border: `1px solid ${error ? theme.palette.danger.outlinedBorder : theme.palette.divider}`,
          }}
        >
          {value.length === 0 && (
            <Typography
              sx={{
                color: `rgba(${theme.vars.palette.neutral.mainChannel} / 0.8)`,
              }}
              level="body-sm"
            >
              {placeholder}
            </Typography>
          )}

          {value.map((valueItem) => (
            <Chip
              key={getItemKey(valueItem)}
              endDecorator={<ChipDelete onDelete={() => onDelete(valueItem)} />}
              sx={{ cursor: 'default' }}
              // display: 'flex' fixes vertical alignment
              slotProps={{ label: { sx: { display: 'flex' } } }}
            >
              {renderItem(valueItem)}
            </Chip>
          ))}
        </Stack>
        <InputError error={error} />
      </FormControl>
    </Box>
  );
}

export default SelectedChipsBoard;
