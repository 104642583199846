import { type ReactElement, useMemo } from 'react';
import { yearMonthDayColumns } from '../../../../technical/grids/SharedReportColumns.tsx';
import { capitalize, isNil } from 'lodash/fp';
import type { IAggFuncParams, ValueGetterParams } from 'ag-grid-community';
import { type BigNumber, bignumber, isBigNumber } from 'mathjs';
import GAgGrid from '../../../../technical/grids/GAgGrid.tsx';
import { calculateCummulativeReturns } from '../../returnsMath.ts';
import type { PortfolioReturnsSectionProps } from './PortfolioReturnsSectionProps.tsx';

interface RowData {
  date: UtcDate;
  returns: Map<string, BigNumber>;
}

const compoundReturnsAgg = ({ values }: IAggFuncParams<unknown, number | BigNumber>): number | null => {
  if (values.length === 0) {
    return null;
  }

  const hasNullIllegalValues = values.some((val) => {
    if (isNil(val)) {
      return true;
    }

    if (typeof val !== 'number' && !isBigNumber(val)) {
      return true;
    }

    return false;
  });

  if (hasNullIllegalValues) {
    return null;
  }

  const safeNumbers = values as BigNumber[] | number[];
  return calculateCummulativeReturns(safeNumbers).at(-1)!.toNumber();
};

const customAgg = {
  compounded: compoundReturnsAgg,
};

export interface PortfolioReturnsGridProps {
  analysis: PortfolioReturnsSectionProps['analysis'];
}

const PortfolioReturnsGrid = ({ analysis }: PortfolioReturnsGridProps): ReactElement => {
  const idToPortfolio = new Map<string, string>(
    analysis
      .filter(({ returns }) => returns.length > 0) // keep track of only portfolios which have returns
      .map(({ portfolioDefinition }) => [portfolioDefinition.id, portfolioDefinition.name])
  );

  const allPortfolioDailyReturns = useMemo(() => {
    const allPortfolioDailyReturns = new Map<UtcDate, Map<string, BigNumber>>();
    for (const { returns, portfolioDefinition } of analysis) {
      for (const { date, portfolioWeightedReturn } of returns) {
        let dailyReturns = allPortfolioDailyReturns.get(date);
        if (isNil(dailyReturns)) {
          dailyReturns = new Map();
          allPortfolioDailyReturns.set(date, dailyReturns);
        }

        dailyReturns.set(portfolioDefinition.id, bignumber(portfolioWeightedReturn));
      }
    }

    return allPortfolioDailyReturns;
  }, [analysis]);

  return (
    <GAgGrid<RowData>
      height="800px"
      // @ts-expect-error it's not possible to correctly type the function
      aggFuncs={customAgg}
      rowData={Array.from(allPortfolioDailyReturns.entries()).map(([date, returns]) => ({
        date,
        returns,
      }))}
      pivotMode
      columnDefs={[
        yearMonthDayColumns<RowData>({
          field: 'date',
          show: {
            week: true,
          },
        }),
        {
          colId: 'dailyReturns',
          headerName: 'Returns',
          children: Array.from(idToPortfolio.entries()).map(([id, name]) => ({
            colId: `${id}-returns`,
            headerName: capitalize(name),
            chartDataType: 'series' as const,
            allowedAggFuncs: ['compounded'],
            type: ['numericColumn', 'percentageColumn'],
            initialAggFunc: 'compounded',
            valueGetter: (params: ValueGetterParams<RowData>): number | undefined => {
              if (!params.data) {
                return undefined;
              }

              const returns = params.data.returns.get(id);
              if (!returns) {
                return undefined;
              }

              return bignumber(returns).toNumber();
            },
          })),
        },
      ]}
      defaultColDef={{
        resizable: true,
        sortable: true,
        filter: true,
      }}
      groupDefaultExpanded={1}
      enableCharts
      cellSelection
      autoSizeStrategy={{ type: 'fitGridWidth' }}
      sideBar={{
        toolPanels: ['columns', 'filters'],
      }}
    />
  );
};

export default PortfolioReturnsGrid;
