import {
  Box,
  FormControl,
  type FormControlProps,
  IconButton,
  Select as JoySelect,
  type SelectOption as JoySelectOption,
  type SelectProps as JoySelectProps,
} from '@mui/joy';
import Option from '@mui/joy/Option';
import isNil from 'lodash/fp/isNil';
import { type MutableRefObject, useRef, type ReactElement, type ReactNode } from 'react';

import type { SelectOption, SelectProps, SingleSelectProps } from './Select.props';
import InputError from '../InputError';
import InputLabel from '../InputLabel';
import { calculatePlaceholder, shouldRenderInputLabel } from '../LabelService.ts';
import { CloseRounded } from '@mui/icons-material';
import widthSx, { widths } from '../../../width.styles.ts';

const createFormControlProps = <T,>(props: SelectProps<T>): FormControlProps => ({
  error: !!props.error,
  size: props.size,
  color: props.color,
  sx: props.width !== 'minContent' && props.width !== 'fullWidth' ? widthSx[props.width] : {},
  disabled: props.disabled,
});

const createSelectProps = <T,>(
  props: SelectProps<T>
): Pick<JoySelectProps<SelectOption<T>, boolean>, 'name' | 'disabled' | 'color' | 'placeholder'> => ({
  name: props.name,
  disabled: props.disabled,
  color: props.color,
  placeholder: calculatePlaceholder(props),
});

const calculateFinalValue = <T,>(
  value: T,
  options: SelectOption<T>[],
  isValueEqual: (a: T, b: T) => boolean
): null | T => {
  const matchesOption = options.some((opt) => isValueEqual(opt.value, value));
  if (matchesOption) {
    return value;
  }
  if (options.length > 0 && !isNil(value) && value !== '') {
    console.warn(`Provided value outside of possible options ${value}`);
  }

  return null;
};

const getOrAddId = <T,>(
  idToValue: MutableRefObject<Map<number, T>>,
  value: T,
  isValueEqual: (a: T, b: T) => boolean
): number | null => {
  if (isNil(value)) {
    return null;
  }

  for (const [id, val] of idToValue.current.entries()) {
    if (isValueEqual(val, value)) {
      return id;
    }
  }

  const newId = idToValue.current.size + 1;
  idToValue.current.set(newId, value);
  return newId;
};

const Select = <T,>(props: SingleSelectProps<T>): ReactElement => {
  // we generate custom ids for options of the fly to keep constant references
  const idToValue = useRef(new Map<number, T>());
  const { value, options, onChange, placeholder, showClearable, isValueEqual } = props;
  const finalIsValueEqual = isValueEqual ?? ((a, b) => a === b);
  const children = options.map((item) => (
    <Option value={getOrAddId(idToValue, item.value, finalIsValueEqual)} key={item.key}>
      {item.label}
    </Option>
  ));

  const finalValue: T | null = calculateFinalValue(value, options, finalIsValueEqual);
  const allWidthSx = {
    ...widthSx,
    fullWidth: {
      width: '100%',
    },
    minContent: {
      width: 'min-content',
    },
  };

  return (
    <Box
      sx={{
        position: 'relative',
        height: 'fit-content',
        ...allWidthSx[props.width],
      }}
    >
      <FormControl {...createFormControlProps(props)}>
        {shouldRenderInputLabel(props) ? <InputLabel {...props} /> : <></>}
        <JoySelect<number>
          {...createSelectProps(props)}
          slotProps={{
            listbox: {
              sx: props.menuWidth
                ? {
                    minWidth: widths[props.menuWidth],
                  }
                : {},
            },
          }}
          onChange={(_e, value): void => {
            onChange?.(idToValue.current.get(value!)!);
          }}
          value={getOrAddId(idToValue, finalValue, finalIsValueEqual)}
          ref={props.ref}
          renderValue={(option: JoySelectOption<number> | undefined | null): ReactNode => {
            if (!option) {
              return placeholder;
            }

            const child = options.find((item) =>
              finalIsValueEqual(idToValue.current.get(option.value) ?? null, item.value)
            );

            if (!child) {
              throw new Error(`Couldn't find child for value: ${option}`);
            }

            return child.label;
          }}
          {...(!!value &&
            showClearable &&
            onChange && {
              endDecorator: (
                <IconButton
                  size={'sm'}
                  sx={{
                    minHeight: '1.5rem',
                  }}
                  variant="plain"
                  color="neutral"
                  onMouseDown={(event) => {
                    // don't open the popup when clicking on this button
                    event.stopPropagation();
                  }}
                  onClick={() => {
                    onChange?.(null);
                  }}
                >
                  <CloseRounded />
                </IconButton>
              ),
            })}
        >
          {children}
        </JoySelect>
        <InputError error={props.error} />
      </FormControl>
    </Box>
  );
};

export default Select;
