import type { ReactElement } from 'react';
import { Stack, Typography } from '@mui/joy';
import { defaultRowSpacing } from '../../StackSpacing.ts';
import { customColumnTypes } from '../../technical/grids/customColumnTypes.ts';
import capitalize from 'lodash/fp/capitalize';
import type { Response } from './UnifiedStressTest.types.ts';
import type { CellStyle } from 'ag-grid-community';
import { getRowDataForRowGroupColumn } from '../../technical/grids/agGrid.utils.tsx';
import { UserSettings } from '../../management/UserSettings.types.ts';
import GAgGridPresets from '../../technical/grids/GAgGridPresets.tsx';
import { unifiedStressTestShocksDefaultPresets } from './unifiedStressTestPresets.ts';

const calculateColor = (value: number, refValue: number, maxRange: number): string => {
  const targetColor =
    value > refValue ? 'var(--joy-palette-success-plainColor)' : 'var(--joy-palette-danger-plainColor)';
  const targetPercent = Math.abs(value - refValue) / maxRange;
  return `color-mix(in oklab, var(--ag-data-color) ${100 * targetPercent}%, ${targetColor})`;
};

const calculateMaxRanges = (
  response: Response,
  field: 'fairValue' | 'marketValue'
): Map<string, { maxRange: number; refValue: number }> => {
  const results = new Map<string, { maxRange: number; refValue: number }>();
  for (const portfolioResp of response) {
    const refValue = portfolioResp.referenceEvaluation[field] ?? 0;
    const values = portfolioResp.simulatedResults.map((scenario) => scenario.portfolioEvaluationV2[field] ?? refValue);
    const maxValue = Math.max(...values);
    const minValue = Math.max(...values);
    const valueRange = Math.max(Math.abs(maxValue - refValue), Math.abs(refValue - minValue));

    results.set(portfolioResp.name, {
      maxRange: valueRange,
      refValue: refValue,
    });
  }

  return results;
};

const UnifiedStressTestResultShocks = ({ response }: { response: Response }): ReactElement => {
  const scenarios = response.flatMap((resp) =>
    resp.simulatedResults.map((sim) => ({
      name: resp.name,
      ...sim,
    }))
  );

  const fairValueRange = calculateMaxRanges(response, 'fairValue');
  const marketValueRange = calculateMaxRanges(response, 'marketValue');
  return (
    <Stack gap={defaultRowSpacing}>
      <Typography level="h3">Shocks</Typography>
      <GAgGridPresets
        presetSettingsKey={UserSettings.StressTestResultsShocks}
        defaultPresets={unifiedStressTestShocksDefaultPresets}
        rowData={scenarios}
        enableCharts
        cellSelection
        columnTypes={customColumnTypes}
        height="max(85vh, 800px)"
        sideBar={{
          toolPanels: ['columns', 'filters'],
          defaultToolPanel: 'columns',
        }}
        defaultColDef={{
          resizable: true,
          sortable: true,
        }}
        columnDefs={[
          {
            headerName: 'Portfolio',
            colId: 'portfolio',
            type: 'textColumn',
            field: 'name',
            filter: true,
          },
          {
            headerName: 'Shocks',
            colId: 'shocks',
            marryChildren: true,
            children: [
              {
                headerName: 'Volatility',
                type: 'percentageColumn',
                field: 'scenario.benchmark.volatility.shock',
                enablePivot: true,
                enableRowGroup: true,
              },
              {
                headerName: 'Price',
                type: 'percentageColumn',
                field: 'scenario.benchmark.price.shock',
                enablePivot: true,
                enableRowGroup: true,
              },
              {
                headerName: 'Interest free rate',
                type: 'percentageColumn',
                field: 'scenario.riskFree.shock',
                enablePivot: true,
                enableRowGroup: true,
              },
            ],
          },
          {
            headerName: 'P&L',
            colId: 'pnl',
            marryChildren: true,
            children: [
              {
                headerName: 'P&L (net present value)',
                type: 'cashColumn',
                field: 'portfolioEvaluationV2.fairValue',
                cellStyle: (params): CellStyle => {
                  const data = getRowDataForRowGroupColumn(params);
                  if (!data) {
                    return {};
                  }
                  const ranges = fairValueRange.get(data.name)!;
                  return {
                    color: calculateColor(params.value, ranges.refValue, ranges.maxRange),
                  };
                },
              },
              {
                headerName: 'P&L (market value)',
                type: 'cashColumn',
                field: 'portfolioEvaluationV2.marketValue',
                cellStyle: (params): CellStyle => {
                  const data = getRowDataForRowGroupColumn(params);
                  if (!data) {
                    return {};
                  }
                  const ranges = marketValueRange.get(data.name)!;
                  return {
                    color: calculateColor(params.value, ranges.refValue, ranges.maxRange),
                  };
                },
              },
            ],
          },
          {
            headerName: 'Greeks',
            colId: 'greeks',
            marryChildren: true,
            children: (['delta', 'gamma', 'rho', 'vega', 'theta'] as const).map((field) => ({
              headerName: capitalize(field),
              type: 'extendedNumericColumn',
              field: `portfolioEvaluationV2.greeks.${field}`,
            })),
          },
        ]}
      />
    </Stack>
  );
};

export default UnifiedStressTestResultShocks;
