import type { ColDef, ColGroupDef, ValueGetterParams } from 'ag-grid-enterprise';
import { IPeriodType, type ISubFundPnlQuery } from '../../../../generated/graphql.tsx';
import { createColumnToolDef, getRowDataForRowGroupColumn } from '../../../technical/grids/agGrid.utils.tsx';
import { getAssetName } from '../../../market/asset/AssetService.tsx';
import type { CellClassParams, ICellRendererParams } from 'ag-grid-community';
import type { ReactElement } from 'react';
import AssetLabel from '../../../market/asset/AssetLabel.tsx';
import { IconVariant } from '../../../market/asset/cryptocurrencies/CryptocurrenciesData.tsx';
import isNil from 'lodash/fp/isNil';
import GAgGridPresets from '../../../technical/grids/GAgGridPresets.tsx';
import { UserSettings } from '../../../management/UserSettings.types.ts';
import { defaultPresets } from './subFundPnlGridPresets.ts';
import { upperFirst } from 'lodash/fp';

type Query = ISubFundPnlQuery['portfolio']['subFundsTimeWeightedReturns']['subFunds'];
type PeriodRow = Query[number]['usdReturns']['summaries'][number];
type RowData = {
  subFund: Query[number]['subFund'];
  usdReturns: Partial<Record<IPeriodType, PeriodRow>>;
  referenceAssetReturns: Partial<Record<IPeriodType, PeriodRow>>;
};

enum SubFundAsset {
  Usd = 'USD',
  Reference = 'REFERENCE',
}

const periodName: Record<IPeriodType, string> = {
  [IPeriodType.All]: 'Whole period',
  [IPeriodType.Mtd]: 'MTD',
  [IPeriodType.Qtd]: 'QTD',
  [IPeriodType.Ytd]: 'YTD',
  [IPeriodType.Wtd]: 'WTD',
  [IPeriodType.D_7]: 'Last 7 days',
  [IPeriodType.D_30]: 'Last 30 days',
  [IPeriodType.D_90]: 'Last 90 days',
};

const cashColumnType = ['numericColumn', 'cashColumn'];
const percentageColumnType = ['numericColumn', 'percentageColumn'];
const numericColumnType = ['numericColumn', 'extendedNumericColumn'];
const dayTypes = ['all', 'negative', 'positive'] as const;

const createAssetsColumns = ({
  periodType,
}: {
  periodType: IPeriodType;
}): (ColDef<RowData> | ColGroupDef<RowData>)[] => {
  const getPeriodReturnStats = (
    asset: SubFundAsset,
    params: ICellRendererParams<RowData> | ValueGetterParams<RowData>
  ): PeriodRow | undefined => {
    if (!params.data) {
      return undefined;
    }

    const assetField = asset === SubFundAsset.Usd ? 'usdReturns' : 'referenceAssetReturns';
    return params.data[assetField][periodType as IPeriodType];
  };

  const returnColumns: ColDef<RowData>[] = [];
  const columns: ColGroupDef<RowData>[] = [
    {
      headerName: 'Return',
      groupId: 'return',
      marryChildren: true,
      children: returnColumns,
    },
  ];

  const positiveNegativeCellStyle = (params: CellClassParams<RowData, number>) => {
    if (isNil(params.value)) {
      return null;
    }

    return {
      color: params.value < 0 ? 'var(--joy-palette-danger-plainColor)' : 'var(--joy-palette-success-plainColor)',
    };
  };

  const unitTypes = ['absolute', 'percentage'] as const;
  for (const unitType of unitTypes) {
    for (const asset of [SubFundAsset.Usd, SubFundAsset.Reference]) {
      returnColumns.push({
        colId: `${asset}_cumm_ret_${unitType}`,
        headerName: ['P&L', asset === SubFundAsset.Usd ? '($)' : '', unitType === 'percentage' ? '[%]' : '']
          .filter((part) => !!part)
          .join(' '),
        cellStyle: positiveNegativeCellStyle,
        type:
          unitType === 'percentage'
            ? percentageColumnType
            : asset === SubFundAsset.Usd
              ? cashColumnType
              : numericColumnType,
        valueGetter: (params): number | undefined => {
          return getPeriodReturnStats(asset, params)?.cumulativeReturns[unitType];
        },
      });
    }
  }

  const retMetrics = ['dayCount', 'medianReturn', 'meanReturn', 'bestDay', 'worstDay'] as const;
  for (const retMetric of retMetrics) {
    const groupColumns: ColDef<RowData>[] = [];
    columns.push({
      headerName: upperFirst(retMetric.replaceAll(/([A-Z])/g, ' $1').toLowerCase()).replaceAll(/s$/g, ''),
      groupId: retMetric,
      marryChildren: true,
      children: groupColumns,
    });

    for (const dayType of dayTypes) {
      for (const unitType of ['absolute', 'percentage'] as const) {
        if (retMetric === 'dayCount' && unitType === 'percentage' && dayType === 'all') {
          continue;
        }

        for (const asset of [SubFundAsset.Usd, SubFundAsset.Reference]) {
          groupColumns.push({
            colId: `${asset}_${periodType}_${dayType}_${retMetric}_${unitType}`,
            headerName: upperFirst(
              [dayType, asset === SubFundAsset.Usd ? '($)' : '', unitType === 'percentage' ? '[%]' : '']
                .filter((part) => !!part)
                .join(' ')
            ),
            cellStyle: retMetric === 'dayCount' ? () => null : positiveNegativeCellStyle,
            type:
              unitType === 'percentage'
                ? percentageColumnType
                : retMetric === 'dayCount'
                  ? numericColumnType
                  : asset === SubFundAsset.Usd
                    ? cashColumnType
                    : numericColumnType,
            enableValue: unitType === 'absolute',
            valueGetter: (params: ValueGetterParams<RowData>): number | undefined | null => {
              const returnSummary = getPeriodReturnStats(asset, params);
              if (isNil(returnSummary)) {
                return undefined;
              }

              const dayStats = returnSummary.statistics[dayType];
              if (isNil(dayStats)) {
                return undefined;
              }

              return dayStats[retMetric][unitType];
            },
          });
        }
      }
    }
  }

  return columns;
};

const createPeriodBasedColumns = (): (ColDef<RowData> | ColGroupDef<RowData>)[] => {
  return Object.entries(periodName).flatMap(([periodType, name]): ColDef<RowData>[] => {
    const columns = createAssetsColumns({
      periodType: periodType as IPeriodType,
    });

    const resultColumns: ColGroupDef<RowData>[] = [];
    resultColumns.push({
      headerName: name,
      groupId: periodType,
      marryChildren: true,
      children: [...columns],
    });

    return resultColumns;
  });
};

const columnDefs: (ColDef<RowData> | ColGroupDef<RowData>)[] = [
  {
    headerName: 'Sub-fund',
    type: 'textColumn',
    valueGetter: (params): string | undefined => {
      if (!params.data) {
        return undefined;
      }

      return params.data.subFund.name;
    },
  },
  {
    headerName: 'Valuation currency',
    type: 'textColumn',
    colId: 'valuationAsset',
    valueGetter: (params): string | undefined => {
      if (!params.data) {
        return undefined;
      }

      return getAssetName(params.data.subFund.referenceAsset);
    },
    cellRenderer: (params: ICellRendererParams<RowData>): ReactElement | undefined => {
      const rowData = getRowDataForRowGroupColumn(params);
      if (!rowData) {
        return undefined;
      }
      const asset = rowData.subFund.referenceAsset;
      return asset ? <AssetLabel asset={asset} wrap={false} size={IconVariant.MEDIUM} plain /> : undefined;
    },
  },
  ...createPeriodBasedColumns(),
];

const defaultColDef = {
  sortable: true,
  resizable: true,
  filter: true,
};

const createPeriodToSummary = (
  assetValues: Query[number]['referenceAssetReturns']['summaries']
): Partial<Record<IPeriodType, PeriodRow>> => {
  return Object.fromEntries(assetValues.map((summ) => [summ.periodType, summ]));
};

const SubFundPnlGrid = ({
  data,
}: {
  data: Query;
}) => {
  const rows = data.map((subFund) => {
    return {
      subFund: subFund.subFund,
      usdReturns: createPeriodToSummary(subFund.usdReturns.summaries),
      referenceAssetReturns: createPeriodToSummary(subFund.referenceAssetReturns.summaries),
    };
  });
  return (
    <GAgGridPresets<RowData>
      presetSettingsKey={UserSettings.SubFundPnlPresets}
      defaultPresets={defaultPresets}
      rowData={rows}
      minHeight={'800px'}
      groupDefaultExpanded={-1}
      sideBar={{
        toolPanels: [
          createColumnToolDef({
            suppressPivotMode: true,
          }),
          'filters',
        ],
      }}
      enableCharts
      cellSelection
      defaultColDef={defaultColDef}
      columnDefs={columnDefs}
    />
  );
};

export default SubFundPnlGrid;
