import isEqual from 'lodash/fp/isEqual';
import isNil from 'lodash/fp/isNil';
import isEmpty from 'lodash/fp/isEmpty';
import * as yup from 'yup';
import type { Schema } from 'yup';
import type { FormInputType } from 'components/technical/form/Form.types.ts';
import type { SelectOption } from 'components/technical/inputs/Select/Select.props';

import {
  IConstraintFormulation,
  IObjectiveType,
  IReturnMeasureName,
  IReturnMeasureNameUi,
} from '../../../../../generated/graphql.tsx';
import { yupWhen } from '../../../../../validation.ts';
import type { AllocationConstraintsInput } from '../allocationConstraints/AllocationConstraintsStep.validation.ts';
import type { ObjectivesStepInput } from '../objective/ObjectivesStep.validation.ts';
import type { InitialPortfolioStepInput } from '../initialPortfolio/InitialPortfolio.validation.ts';
import type { AssetUniverseStepInput } from '../asset/assetUniverse/AssetUniverseStep.validation.ts';
import type { PortfolioUniverseStepInput } from '../portfolio/portfolioUniverse/PortfolioUniverseStep.validation.ts';

export const returnMeasureLabels: Record<IReturnMeasureNameUi, string> = {
  [IReturnMeasureNameUi.ForecastedReturns]: "Genie's return forecasts",
  [IReturnMeasureNameUi.HistoricMedian]: 'Historical 7-day returns median',
  [IReturnMeasureNameUi.UserSupplied]: 'Custom values',
};

export const uiReturnMeasureToBackendValue: Record<IReturnMeasureNameUi, IReturnMeasureName> = {
  [IReturnMeasureNameUi.ForecastedReturns]: IReturnMeasureName.UserSupplied, // value autopopulated from the backend forecast
  [IReturnMeasureNameUi.HistoricMedian]: IReturnMeasureName.HistoricMedian,
  [IReturnMeasureNameUi.UserSupplied]: IReturnMeasureName.UserSupplied,
};

export const returnMeasureValues: SelectOption<IReturnMeasureNameUi>[] = [
  IReturnMeasureNameUi.HistoricMedian,
  IReturnMeasureNameUi.UserSupplied,
  IReturnMeasureNameUi.ForecastedReturns,
].map((val) => ({
  value: val,
  label: returnMeasureLabels[val],
  key: returnMeasureLabels[val],
}));

export enum RiskDistributionOption {
  UserSupplied = 'USER_SUPPLIED',
  MultifactorScore = 'MultifactorScore',
  Forecast = 'FORECAST',
}

const riskDistributionLabels: Record<RiskDistributionOption, string> = {
  [RiskDistributionOption.Forecast]: "Weights scaled for the asset's expected return",
  [RiskDistributionOption.UserSupplied]: 'Custom values',
  [RiskDistributionOption.MultifactorScore]: 'Scaled based on multifactor',
};

export const riskDistributionValues: SelectOption<RiskDistributionOption>[] = [
  RiskDistributionOption.Forecast,
  RiskDistributionOption.MultifactorScore,
  RiskDistributionOption.UserSupplied,
].map((val) => ({
  value: val,
  label: riskDistributionLabels[val],
  key: riskDistributionLabels[val],
}));

export interface ItemOutlookOutput {
  id: string;
  yield?: number | null | undefined;
  leverage?: number | undefined;
  returns?: number | null | undefined;
  riskWeight?: number | null | undefined;
  sources: ('universe' | 'givenPortfolio')[];
}

export type ItemOutlookSource = 'universe' | 'givenPortfolio';

export interface ItemOutlookInput {
  id: string;
  yield: string | null;
  leverage: string | null;
  returns: string | null;
  riskWeight: string | null;
  sources: ('universe' | 'givenPortfolio')[];
}

export interface AssumptionsAndOutlookStepOutput {
  returnsForecast?: IReturnMeasureNameUi;
  riskBudgetAllocation?: RiskDistributionOption;
  multifactor: {
    factor?: {
      id: number;
      maxFactors: number;
    };
    minNumberOfFactors?: number;
    useAbsoluteScores: boolean;
  };
  outlook: ItemOutlookOutput[];
}

export type AssumptionsAndOutlookStepInput = FormInputType<
  Omit<AssumptionsAndOutlookStepOutput, 'outlook' | 'multifactor'>
> & {
  outlook: ItemOutlookInput[];
  multifactor: {
    factor?: {
      id: number;
      maxFactors: number;
    };
    minNumberOfFactors: string | null;
    useAbsoluteScores: boolean;
  };
};

export const shouldShowReturnsForecast = (objectives: { type: IObjectiveType | null }[]): boolean => {
  return objectives.some((obj) => obj.type === IObjectiveType.MaxRiskAdjustedReturns);
};

export const shouldShowRiskBudgetAllocation = (objectives: { type?: IObjectiveType | null }[]): boolean => {
  return objectives.some((obj) => obj.type === IObjectiveType.RiskParity);
};

export const shouldShowMultifactor = (riskBudgetAllocation: RiskDistributionOption | undefined): boolean => {
  if (!riskBudgetAllocation) {
    return false;
  }

  return riskBudgetAllocation === RiskDistributionOption.MultifactorScore;
};

export const shouldShowLeverage = (
  allowShortAndLeverage: ObjectivesStepInput['allowShortAndLeverage'],
  assetConstraintType: AllocationConstraintsInput['constraintType'],
  allocationConstraints: AllocationConstraintsInput['constraints']
): boolean => {
  return (
    allowShortAndLeverage &&
    assetConstraintType === IConstraintFormulation.AbsoluteValue &&
    allocationConstraints.some((item) => item.item?.type === 'asset')
  );
};

export const multifactorSchema = yup
  .object({
    factor: yup
      .object({
        id: yup.number(),
        maxFactors: yup.number(),
      })
      .required()
      .default(null),
    minNumberOfFactors: yupWhen(['factor'], ([factor]) => {
      const baseSchema = yup.number().required().min(0);
      if (isNil(factor)) {
        return baseSchema;
      }

      return baseSchema.max(factor.maxFactors);
    }),
    useAbsoluteScores: yup.boolean().required(),
  })
  .required();

export const schema: Schema<unknown> = yup.object({
  returnsForecast: yupWhen<[ObjectivesStepInput['objectives']], Schema<unknown>>(
    ['objectives'],
    ([objectives]): Schema<unknown> => {
      if (shouldShowReturnsForecast(objectives)) {
        return yup.string().oneOf(Object.values(IReturnMeasureNameUi)).required();
      }

      return yup.mixed().nullable();
    }
  ),

  riskBudgetAllocation: yupWhen<[ObjectivesStepInput['objectives']], Schema<unknown>>(
    ['objectives'],
    ([objectives]): Schema<unknown> => {
      if (shouldShowRiskBudgetAllocation(objectives)) {
        return yup.string().oneOf(Object.values(RiskDistributionOption)).required();
      }

      return yup.mixed().nullable();
    }
  ),
  multifactor: yupWhen<[AssumptionsAndOutlookStepInput['riskBudgetAllocation']], Schema<unknown>>(
    ['riskBudgetAllocation'],
    ([riskBudgetAllocation]): Schema<unknown> => {
      if (shouldShowMultifactor(riskBudgetAllocation)) {
        return multifactorSchema;
      }

      return yup.mixed().nullable();
    }
  ),
  outlook: yupWhen<
    [
      AssumptionsAndOutlookStepInput['returnsForecast'],
      AssumptionsAndOutlookStepInput['riskBudgetAllocation'],
      ObjectivesStepInput['objectives'],
      InitialPortfolioStepInput['givenPortfolio'],
      AssetUniverseStepInput['universe'] | PortfolioUniverseStepInput['universe'],
      ObjectivesStepInput['allowShortAndLeverage'],
      AllocationConstraintsInput['constraintType'],
      AllocationConstraintsInput['constraints'],
    ]
  >(
    [
      'returnsForecast',
      'riskBudgetAllocation',
      'objectives',
      'givenPortfolio',
      'universe',
      'allowShortAndLeverage',
      'assetConstraintType',
      'constraints',
    ],
    ([
      returnsForecast,
      riskBudgetAllocation,
      objectives,
      givenPortfolio,
      universe,
      allowShortAndLeverage,
      assetConstraintType,
      constraints,
    ]): Schema<unknown> => {
      let returnsSchema: Schema<unknown> = yup.mixed().nullable();
      if (shouldShowReturnsForecast(objectives)) {
        if (returnsForecast === IReturnMeasureNameUi.UserSupplied) {
          returnsSchema = yup.number().required();
        } else if (returnsForecast === IReturnMeasureNameUi.ForecastedReturns) {
          returnsSchema = yup.number().nullable();
        }
      }

      let leverageSchema: Schema<unknown> = yup.mixed().nullable();
      if (shouldShowLeverage(allowShortAndLeverage, assetConstraintType, constraints)) {
        leverageSchema = yup.number().min(1).max(10);
      }

      let riskWeightSchema: Schema<unknown> = yup.mixed().nullable().optional();

      if (shouldShowRiskBudgetAllocation(objectives)) {
        if (riskBudgetAllocation === RiskDistributionOption.UserSupplied) {
          riskWeightSchema = yup.number().required().moreThan(0);
        } else {
          riskWeightSchema = yup.number().nullable().moreThan(0);
        }
      }

      return yup
        .array(
          yup.object({
            id: yup.string().required(),
            yield: yup.number().min(0).nullable(),
            leverage: leverageSchema,
            returns: returnsSchema,
            riskWeight: riskWeightSchema,
          })
        )
        .required()
        .test('sameLengthAsUniverse', "Outlook doesn't have data for all rows", (value): boolean => {
          if (isNil(value)) {
            return true;
          }

          const assetIds = new Set([
            ...universe.map((a) => a.id),
            ...Object.entries(givenPortfolio)
              .filter(([_id, value]: [string, string | null]) => !isEmpty(value) && value !== '0')
              .map(([id]) => id),
          ]);

          const valueAssetIds = new Set(value.map((val) => val.id));
          return isEqual(assetIds, valueAssetIds);
        });
    }
  ),
});
