import type { ReactElement } from 'react';
import { Typography, Stack } from '@mui/joy';

interface TitleProps {
  title: string;
  icon?: ReactElement;
  minHeight?: string;
  className?: string;
}

export const Title = ({ minHeight, title, icon, className }: TitleProps) => {
  return (
    <Stack
      direction="row"
      spacing={2}
      width={'100%'}
      justifyContent={'flex-start'}
      minHeight={minHeight}
      alignItems={'center'}
      className={className}
    >
      {icon}
      <Typography level="title-lg" className={className}>
        {title}
      </Typography>
    </Stack>
  );
};
