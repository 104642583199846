import type { ReactElement } from 'react';
import { Typography } from '@mui/joy';

import GCard from 'components/technical/GCard/GCard';
import { SubHeader } from 'components/technical/typography/SubHeader.tsx';
import { LockOutlined, VpnKeyOutlined } from '@mui/icons-material';
import { Title } from 'components/technical/StackTitle';
import { TITLE_MIN_HEIGHT, CONTENT_MIN_HEIGHT } from '../constant';
import { GRID_FULL_WIDTH, SELECTABLE_CARD_STYLE } from 'components/technical/GCard/consts';

export const accountSettingsGroups = (
  isMFA: boolean,
  openUpdatePasswordDialog: () => void,
  openMfaDialog: () => void
): Array<{ key: string; title: ReactElement; items: ReactElement[] }> => [
  {
    key: 'account-security',
    title: <SubHeader title="Account security" withBorder />,
    items: [
      <GCard
        key="login-password"
        gridWidth={GRID_FULL_WIDTH}
        height="100%"
        cardSx={SELECTABLE_CARD_STYLE}
        title={
          <Title
            className="title"
            minHeight={TITLE_MIN_HEIGHT}
            icon={<LockOutlined fontSize="large" />}
            title="Login password"
          />
        }
        onClick={openUpdatePasswordDialog}
        content={
          <Typography level="body-sm" minHeight={CONTENT_MIN_HEIGHT}>
            Click to change your login password to secure your account here.
          </Typography>
        }
      />,
      <GCard
        key="two-factor-authentication"
        gridWidth={GRID_FULL_WIDTH}
        height="100%"
        cardSx={SELECTABLE_CARD_STYLE}
        title={
          <Title
            className="title"
            minHeight={TITLE_MIN_HEIGHT}
            icon={<VpnKeyOutlined fontSize="large" />}
            title="Two factor authentication"
          />
        }
        onClick={openMfaDialog}
        content={
          <Typography level="body-sm" minHeight={CONTENT_MIN_HEIGHT}>
            Click here to
            <strong style={{ padding: '0 2px', color: !isMFA ? 'black' : 'dark-red' }}>
              {!isMFA ? 'enable' : 'disable'}
            </strong>
            2FA.
          </Typography>
        }
      />,
    ],
  },
];
