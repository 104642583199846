import type { ApolloError } from '@apollo/client';
import * as Highcharts from 'highcharts';
import { HighchartsReact } from 'highcharts-react-official';
import isNil from 'lodash/fp/isNil';
import merge from 'lodash/fp/merge';
import mergeAllWith from 'lodash/fp/mergeAllWith';
import React, { type ReactElement, type Ref } from 'react';
import { GraphQLErrorMessage } from 'components/technical/form/GraphQLApiErrorMessage';
import Loader from 'components/technical/Loader/Loader';
import Message from 'components/technical/Message';

import DarkTheme, { colorAxis as colorAxisDarkTheme } from './DarkTheme';
import {
  chartSize,
  type HighChartRef,
  type HighchartsDataPoint,
  type HighchartsDataPointDefault,
  type HighchartSeries,
  markerRadius,
} from './Highchart.utils';
import LightTheme, { colorAxis as colorAxisLightTheme } from './LightTheme';
import { useFinalColorScheme } from '../../../../useFinalColorScheme';
import { defaultHeight } from '../Chart.constants';
import { Box } from '@mui/joy';
import GErrorBoundary from '../../GErrorBoundary.tsx';

type HighChartProps<T, S extends HighchartsDataPoint = HighchartsDataPointDefault> = {
  data: T | undefined;
  exporting?: boolean;
  loading: boolean;
  error?: ApolloError;
  calculateOptions: (data: T) => Highcharts.Options;
  calculateChartData: (data: T) => HighchartSeries<S>[];
  ref?: Ref<HighChartRef>;
};

Highcharts.SVGRenderer.prototype.symbols.cross = function crossSvg(
  x: number,
  y: number,
  w: number,
  h: number
): [string, number, number, string, number, number, string, number, number, string, number, number, string] {
  return ['M', x, y, 'L', x + w, y + h, 'M', x + w, y, 'L', x, y + h, 'z'];
};

const HighChartsWrapper = <T, S extends HighchartsDataPoint = HighchartsDataPointDefault>({
  data,
  loading,
  error,
  calculateOptions,
  calculateChartData,
  exporting = true,
  ref,
}: HighChartProps<T, S>): ReactElement => {
  const colorScheme = useFinalColorScheme();
  if (loading || !isNil(error) || isNil(data)) {
    return (
      <Box sx={{ minHeight: chartSize, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        {loading ? <Loader /> : error ? <GraphQLErrorMessage error={error} /> : <Message>No data</Message>}
      </Box>
    );
  }

  const calculatedOptions = calculateOptions(data);
  const finalData: HighchartSeries<S>[] = data
    ? calculateChartData(data).map((trace) => {
        return merge(
          {
            type: trace.type ?? calculatedOptions.chart?.type,
            marker: {
              radius: 0.1,
              states: {
                select: {
                  radius: markerRadius,
                  lineColor: 'white',
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  fillColor: (trace as unknown).color,
                },
                hover: {
                  radius: markerRadius,
                },
              },
            },
          },
          trace
        );
      })
    : [];

  const hasHeatmapSerie = finalData.some((serie) => serie.type === 'heatmap');

  const layout: Highcharts.Options = {
    credits: {
      enabled: false,
    },
    ...(hasHeatmapSerie && {
      colorAxis: colorScheme === 'dark' ? colorAxisDarkTheme : colorAxisLightTheme,
    }),
    exporting: {
      enabled: exporting,
      buttons: {
        contextButton: {
          menuItems: ['downloadPNG', 'downloadJPEG', 'downloadCSV', 'downloadXLS'],
        },
      },
    },
    title: {
      text: undefined,
    },
    tooltip: {
      shared: true,
      style: {
        whiteSpace: 'nowrap',
      },
    },
    chart: {
      height: defaultHeight,
      spacingLeft: 0,
      spacingRight: 0,

      zooming: {
        type: 'xy',
      },
    },
    // biome-ignore lint/suspicious/noExplicitAny: higcharts doesnt allow series with type:undefined, which is valid for histograms
    series: finalData as any,
    plotOptions: {
      series: {
        // disables turbo mode, we might need to enable if some charts will be slow
        // for that we would need to change data shape from object {x,y,textValue} to tuples [x,y]
        // for more info see https://api.highcharts.com/highcharts/plotOptions.series.turboThreshold
        turboThreshold: 0,
        boostThreshold: 0,
      },
    },
  };

  const options = mergeAllWith(
    (value: unknown, srcValue: unknown, key: string) => {
      if (!['yAxis', 'xAxis'].includes(key)) {
        return undefined;
      }

      if (!Array.isArray(srcValue)) {
        return undefined;
      }

      return srcValue.map((srcVal) => merge(value, srcVal));
    },
    [colorScheme === 'dark' ? DarkTheme : LightTheme, layout, calculatedOptions]
  );

  return (
    <HighchartsReact
      ref={ref}
      // recreate chart when scheme changes to populate highcharts with a completely new theme. without it, some properties are not overridden
      key={`${colorScheme}`}
      highcharts={Highcharts}
      options={options}
    />
  );
};

const HighChartsWrapperMemoized = React.memo(HighChartsWrapper) as typeof HighChartsWrapper;

const ErrorHandledHighChartsWrapper = <T, S extends HighchartsDataPoint = HighchartsDataPointDefault>(
  props: HighChartProps<T, S>
): ReactElement => {
  return (
    <GErrorBoundary>
      <HighChartsWrapperMemoized {...props} ref={props.ref} />
    </GErrorBoundary>
  );
};

export default ErrorHandledHighChartsWrapper;
