import { Modal, ModalDialog, Stack } from '@mui/joy';
import { type FunctionComponent, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import {
  createAccountAutocompleteOptions,
  type CreateSubAccountIdAutocompleteOptionsInputAccount,
} from 'components/portfolio/account/AccountService.tsx';
import GFormProvider from 'components/technical/form/GFormProvider';
import { GraphQLApiFormErrorMessage } from 'components/technical/form/GraphQLApiErrorMessage';
import gYupResolver from 'components/technical/form/gYupResolver';
import SubmitButton from 'components/technical/form/SubmitButton';
import { type GraphQlErrorHandler, useGraphQLApiError } from 'components/technical/form/UseGraphQLApiError';
import GDialogHeader from 'components/technical/GDialog/GDialogHeader.tsx';
import StaticSingleAutocomplete from 'components/technical/inputs/Autocomplete/StaticSingleAutocomplete';
import GButton from 'components/technical/inputs/GButton/GButton';
import { defaultRowSpacing } from 'components/StackSpacing';
import type { SubFund, SubFundCorrelationAssets } from './SubFundsDashboard';
import { Edit } from '@mui/icons-material';
import { sortBy } from 'lodash/fp';
import { createAssetSelectOptions } from 'components/market/asset/AssetService';
import FormSelectedChipsBoard from 'components/technical/form/FormSelectedChipsBoard';
import { IconVariant } from 'components/market/asset/cryptocurrencies/CryptocurrenciesData';
import { AccountLabel } from '../account/AccountLabel';
import { FormInput } from 'components/technical/inputs';

type SubFundFormState = {
  name: string;
  accounts: SubFund['accounts'];
  referenceAsset: string;
};

type CreateUpdateSubFundDialogProps = {
  onClose: () => void;
  accounts: CreateSubAccountIdAutocompleteOptionsInputAccount[];
  existingSubFunds: Set<string>;
  handleFormSubmit: (data: SubFundFormState, graphQlErrorHandler: GraphQlErrorHandler) => Promise<void>;
  initialState: SubFund;
  correlationAssets: SubFundCorrelationAssets;
};

const ChangeSubFundAccountsDialog: FunctionComponent<CreateUpdateSubFundDialogProps> = (props) => {
  console.log('props', props);

  const checkUniqueSubFund = (value: string) => {
    // If the new name is the same as the current name ignore the check
    return props.initialState.name === value || !props.existingSubFunds.has(value);
  };

  const formSchema = yup.object({
    name: yup
      .string()
      .required()
      .test('unique', 'Sub-fund already exists', (value) => checkUniqueSubFund(value)),
    accounts: yup.array().of(yup.mixed().required()).required().min(1, 'At least one account is required'),
    referenceAsset: yup.string().optional(),
  });

  const initialReferenceAsset = props.correlationAssets.find(
    (asset) => asset.id === props.initialState.referenceAsset?.id
  );
  const initialAccounts =
    props.initialState.accounts.length > 0 ? sortBy((a) => a.name, props.initialState.accounts) : [];
  const methods = useForm<SubFundFormState>({
    resolver: gYupResolver(formSchema),
    reValidateMode: 'onChange',
    defaultValues: {
      name: props.initialState.name,
      accounts: initialAccounts,
      referenceAsset: initialReferenceAsset?.id,
    },
  });

  const [selectedAsset, setSelectedAsset] = useState(initialReferenceAsset || null);
  const selectedAccounts = methods.watch('accounts');
  const availableAccounts = props.accounts.filter((account) => !selectedAccounts.map((a) => a.id).includes(account.id));

  const availableToAddAccountOptions = createAccountAutocompleteOptions(availableAccounts);

  const assetOptions = useMemo(() => createAssetSelectOptions(props.correlationAssets), [props.correlationAssets]);

  const { onErrorAndThrow } = useGraphQLApiError(methods);
  const [autocompleteClearAccounts, setAutocompleteClearAccounts] = useState(0);
  const [autocompleteClearAsset, setAutocompleteClearAsset] = useState(10);

  return (
    <Modal open onClose={props.onClose}>
      <ModalDialog layout="center" size="lg">
        <GFormProvider {...methods}>
          <form onSubmit={methods.handleSubmit((data) => props.handleFormSubmit(data, onErrorAndThrow))}>
            <GDialogHeader>{methods.getValues('name')}</GDialogHeader>
            <Stack spacing={3} alignItems="start">
              <Stack direction="row" gap={10} maxWidth={'100%'}>
                <Stack spacing={3.5}>
                  <FormInput
                    type="input"
                    showLabelAboveInput
                    placeholder="Name"
                    label="Name"
                    name="name"
                    autoComplete="off"
                    width="xl2"
                  />
                  <StaticSingleAutocomplete
                    value={selectedAsset}
                    key={autocompleteClearAsset} // to clear input after account selected, value=null works only on blur too late
                    label="Reference Asset"
                    placeholder="Search"
                    width="fullWidth"
                    showLabelAboveInput
                    {...assetOptions}
                    onChange={(value) => {
                      if (value) {
                        setSelectedAsset(value);
                        methods.setValue('referenceAsset', value.id, {
                          shouldValidate: true,
                        });
                        setAutocompleteClearAsset((prevAutocompleteClearKey) => prevAutocompleteClearKey + 1);
                      }
                    }}
                  />
                </Stack>
                <Stack direction="column" gap={3.5} maxWidth={'100%'}>
                  <StaticSingleAutocomplete
                    value={null}
                    key={autocompleteClearAccounts}
                    label="Accounts"
                    placeholder="Search"
                    width="fullWidth"
                    menuWidth="xl3"
                    showChipTooltips
                    showLabelAboveInput
                    {...availableToAddAccountOptions}
                    onChange={(accountToAdd) => {
                      if (accountToAdd) {
                        methods.setValue('accounts', methods.getValues('accounts').concat(accountToAdd), {
                          shouldValidate: true,
                        });
                      }
                      setAutocompleteClearAccounts((prevAutocompleteClearKey) => prevAutocompleteClearKey + 1);
                    }}
                  />
                  <FormSelectedChipsBoard<SubFundFormState>
                    name="accounts"
                    label={`Selected Accounts for ${props.initialState.name}`}
                    placeholder="Accounts"
                    height={300}
                    width="xl4"
                    getItemKey={(account) => account.id}
                    renderItem={(account) => <AccountLabel account={account} size={IconVariant.MEDIUM} wrap={false} />}
                  />
                </Stack>
              </Stack>
              <Stack width="100%">
                <GraphQLApiFormErrorMessage />
                <Stack direction="row" justifyContent="center" spacing={defaultRowSpacing}>
                  <GButton width="normal" variant="outlined" onClick={props.onClose}>
                    Cancel
                  </GButton>
                  <SubmitButton width="normal" startDecorator={<Edit />}>
                    Save
                  </SubmitButton>
                </Stack>
              </Stack>
            </Stack>
          </form>
        </GFormProvider>
      </ModalDialog>
    </Modal>
  );
};

export default ChangeSubFundAccountsDialog;
