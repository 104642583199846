import { Box, Stack, Typography } from '@mui/joy';
import type { ReactElement } from 'react';
import DebugTooltip from 'components/technical/DebugTooltip.tsx';

import type { IAccount } from '../../../generated/graphql';
import { IconVariant } from '../../market/asset/cryptocurrencies/CryptocurrenciesData';
import { VenueIcon } from '../../venue/VenueLabel.tsx';

export const HEIGHT_PX = 32;

export type AccountLabelInputAccount = Pick<IAccount, 'id' | 'venue' | 'name'>;

export const AccountLabel = ({
  account,
  variant = 'long',
  size = IconVariant.MEDIUM,
  wrap = true,
  plain = false,
  hideDebug = false,
}: {
  account: AccountLabelInputAccount;
  variant?: 'long' | 'short';
  size?: IconVariant;
  wrap?: boolean;
  plain?: boolean;
  hideDebug?: boolean;
}): ReactElement => {
  const venueIcon = <VenueIcon accountName={account.name} venue={account.venue.label} size={size} />;

  if (variant === 'short') {
    return (
      <DebugTooltip text={account.id} hideDebug={hideDebug}>
        {venueIcon}
      </DebugTooltip>
    );
  }

  const sx = {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  };

  const textContent = plain ? (
    <Box component={'span'} sx={sx}>
      {account.name}
    </Box>
  ) : (
    <Typography level={'title-sm'} sx={sx}>
      {account.name}
    </Typography>
  );

  return (
    <DebugTooltip text={account.id} hideDebug={hideDebug}>
      <Stack direction="row" spacing={0.5} flexWrap={wrap ? 'wrap' : 'nowrap'} alignItems="center">
        {venueIcon}
        {textContent}
      </Stack>
    </DebugTooltip>
  );
};
