import { IconCheck, IconX, IconAlertTriangle, IconClockHour1Filled } from '@tabler/icons-react';
import { Typography } from '@mui/joy';
import type { FunctionComponent } from 'react';
import { ISyncRequestResultCode } from '../../../generated/graphql';
import StatusChip from 'components/technical/StatusChip';

const AccountStatusChip: FunctionComponent<{
  resultCode?: ISyncRequestResultCode | null;
  lastSync: string;
}> = ({ resultCode, lastSync }) => {
  const lastSyncInfo = (
    <Typography level="body-sm" color="secondary">
      Last sync: {lastSync}
    </Typography>
  );

  const size = 'sm';
  switch (resultCode) {
    case ISyncRequestResultCode.Ok:
    case ISyncRequestResultCode.CompletedPartially:
      return (
        <StatusChip color="success" icon={IconCheck} tooltip={lastSyncInfo} size={size}>
          Synced
        </StatusChip>
      );
    case ISyncRequestResultCode.PermissionDenied:
    case ISyncRequestResultCode.AccessDenied:
      return (
        <StatusChip
          color="danger"
          tooltip={
            <>
              {lastSyncInfo}
              <Typography level="body-sm" color="secondary" mt={1}>
                Check if your API keys are up to date or get in touch with the Genie team for support
              </Typography>
            </>
          }
          size={size}
          icon={IconX}
        >
          Failed
        </StatusChip>
      );
    case ISyncRequestResultCode.NetworkError:
    case ISyncRequestResultCode.ExchangeError:
    case ISyncRequestResultCode.UnknownError:
      return (
        <StatusChip
          color="warning"
          tooltip={
            <>
              {lastSyncInfo}
              <Typography level="body-sm" color="secondary" mt={1}>
                Try syncing again later
              </Typography>
            </>
          }
          size={size}
          icon={IconAlertTriangle}
        >
          Temporarily unavailable
        </StatusChip>
      );
    default:
      return (
        <StatusChip
          color="neutral"
          tooltip="The sub account will be updated soon"
          icon={IconClockHour1Filled}
          size={size}
        >
          In progress
        </StatusChip>
      );
  }
};

export default AccountStatusChip;
