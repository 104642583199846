import { UploadFile } from '@mui/icons-material';
import { FormControl, type ButtonProps } from '@mui/joy';
import { type ChangeEvent, type ForwardedRef, type ReactElement, useRef } from 'react';
import GButton from './GButton/GButton';
import type { GButtonProps } from './GButton/GButton.props';
import InputError from './InputError.tsx';
import { useForkRef } from '@mui/material';

export type FileUploadInputProps = {
  onChange: (file: File) => void;
  width?: GButtonProps['width'];
  error?: string;
  name?: string;
  ref?: ForwardedRef<HTMLInputElement>;
} & Omit<ButtonProps, 'onChange'>;

/**
 * Use the component if you want to load file in ui and send a request to the backend later
 */
const FileUploadInput = ({ onChange, children, ref, ...props }: FileUploadInputProps): ReactElement => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const handleRef = useForkRef(fileInputRef, ref);

  const handleButtonClick = (): void => {
    fileInputRef.current?.click();
  };

  const handleFileInput = (event: ChangeEvent<HTMLInputElement>): void => {
    if (event.target.files?.[0]) {
      onChange(event.target.files[0]);
      event.target.value = '';
    }
  };

  return (
    <FormControl error={!!props.error} disabled={props.disabled} size={props.size} color={props.color}>
      <input type="file" ref={handleRef} onChange={handleFileInput} style={{ display: 'none' }} name={props.name} />
      <GButton variant="outlined" onClick={handleButtonClick} startDecorator={<UploadFile />} {...props}>
        {children}
      </GButton>
      <InputError error={props.error} />
    </FormControl>
  );
};

export default FileUploadInput;
