import { useSubFundPnlSuspenseQuery } from '../../../../generated/graphql.tsx';
import { isNil, sortBy, uniqBy } from 'lodash/fp';
import { formatISODate } from '../../../formatter.utils.ts';
import dayjs, { type Dayjs } from 'dayjs';
import { Grid, Stack } from '@mui/joy';
import { defaultRowSpacing } from '../../../StackSpacing.ts';
import SubFundPerformanceLineChart from './SubFundPerformanceLineChart.tsx';
import SubFundPnlGrid from './SubFundPnlGrid.tsx';
import type { ITimeWeightedReturnType } from '../../../../scalars.ts';
import Message from '../../../technical/Message.tsx';
import { parseUtcDate } from '../../../date.utils.ts';
import SubFundBalanceChart from './SubFundBalanceChart.tsx';
import HeaderBar from '../../../technical/HeaderBar/HeaderBar.tsx';

const gridItemProps = {
  sm: 12,
  md: 6,
} as const;

// when given asset is provided, we show data only for it as a reference asset, otherwise we display data for usd
const AssetSection = ({
  asset,
  subFundTwr,
  subFundIdToColorIndex,
}: {
  asset?: { id: string; symbol: string };
  subFundTwr: {
    subFund: {
      id: number;
      name: string;
      referenceAsset: {
        id: string;
      };
    };
    referenceAssetReturns: { twr: ITimeWeightedReturnType[] };
    usdReturns: { twr: ITimeWeightedReturnType[] };
  }[];
  subFundIdToColorIndex: Map<number, number>;
}) => {
  const twrRows = subFundTwr
    .map((row) => {
      if (isNil(asset)) {
        return {
          subFund: row.subFund,
          twr: row.usdReturns.twr,
        };
      }

      if (row.subFund.referenceAsset.id !== asset.id) {
        return null;
      }

      return {
        subFund: row.subFund,
        twr: row.referenceAssetReturns.twr,
      };
    })
    .filter((rows) => !isNil(rows));

  const performanceTwrRows = subFundTwr
    .map((row) => {
      if (isNil(asset)) {
        // show always reference asset performance
        return {
          subFund: row.subFund,
          twr: row.referenceAssetReturns.twr,
        };
      }

      if (row.subFund.referenceAsset.id !== asset.id) {
        return null;
      }

      return {
        subFund: row.subFund,
        twr: row.referenceAssetReturns.twr,
      };
    })
    .filter((rows) => !isNil(rows));

  const twrLastDate = twrRows
    .map((row) => {
      return row.twr.at(-1)?.date;
    })
    .filter((date) => !isNil(date))
    .map((date) => parseUtcDate(date));

  if (twrLastDate.length === 0) {
    return <Message>No twr data</Message>;
  }

  const maxTwrDate = dayjs.max(twrLastDate);

  const assetSymbol = asset?.symbol ?? 'USD';
  return (
    <Grid container spacing={2}>
      <Grid {...gridItemProps}>
        <SubFundPerformanceLineChart title={'Performance [%]'} data={performanceTwrRows} twrField={'weight'} />
      </Grid>
      <Grid {...gridItemProps}>
        <SubFundPerformanceLineChart title={`P&L [${assetSymbol}]`} data={twrRows} twrField={'value'} />
      </Grid>
      <Grid {...gridItemProps}>
        <SubFundPerformanceLineChart title={`Balance [${assetSymbol}]`} data={twrRows} twrField={'balance'} />
      </Grid>
      <Grid {...gridItemProps}>
        <SubFundBalanceChart
          title={`Balance by sub-fund [${assetSymbol}]`}
          subFundIdToColorIndex={subFundIdToColorIndex}
          data={twrRows
            .map((row) => ({
              ...row,
              twr: row.twr.find((twr) => parseUtcDate(twr.date).isSame(maxTwrDate)),
            }))
            .filter(
              (row): row is Omit<(typeof twrRows)[number], 'twr'> & { twr: ITimeWeightedReturnType } => !isNil(row.twr)
            )}
        />
      </Grid>
    </Grid>
  );
};

const SubFundPnlResult = ({ dimension, startDate }: { dimension: string | null; startDate: Dayjs | null }) => {
  const { data } = useSubFundPnlSuspenseQuery({
    variables: {
      dimensions: isNil(dimension) ? [] : [dimension],
      since: isNil(startDate) ? null : formatISODate(startDate),
    },
  });

  const subFundRows = data.portfolio.subFundsTimeWeightedReturns.subFunds;
  const idToReferenceAsset = new Map(
    subFundRows.map((row) => [row.subFund.referenceAsset.id, row.subFund.referenceAsset])
  );
  const sortedReferenceAssets = sortBy((asset) => asset.symbol.toLowerCase(), Array.from(idToReferenceAsset.values()));
  const uniqueSubFunds = uniqBy(
    (subFund) => subFund.id,
    subFundRows.map((row) => row.subFund)
  );
  const allSubFundsSorted = sortBy((subFund) => subFund.name.toLowerCase(), uniqueSubFunds);
  const subFundToColorIndex = new Map(allSubFundsSorted.map((subFund, index) => [subFund.id, index]));
  return (
    <Stack spacing={defaultRowSpacing}>
      <SubFundPnlGrid data={subFundRows} />
      <div>
        <HeaderBar title={'All sub-funds'} />
        <AssetSection subFundTwr={subFundRows} subFundIdToColorIndex={subFundToColorIndex} />
      </div>

      {sortedReferenceAssets.map((asset) => {
        return (
          <div key={asset.id}>
            <HeaderBar title={`${asset.symbol} P&L`} />
            <AssetSection asset={asset} subFundTwr={subFundRows} subFundIdToColorIndex={subFundToColorIndex} />
          </div>
        );
      })}
    </Stack>
  );
};

export default SubFundPnlResult;
