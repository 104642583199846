export const defaultPresets = [
  {
    name: 'default',
    isDefault: true,
    settings: {
      filters: {},
      pivotMode: false,
      columns: [
        {
          colId: 'ag-Grid-AutoColumn',
          width: 200,
          expanded: true,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: '0',
          expanded: true,
          width: 150,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'valuationAsset',
          width: 212,
          hide: true,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: true,
          rowGroupIndex: 0,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_cumm_ret_absolute',
          width: 138,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_cumm_ret_absolute',
          width: 122,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_cumm_ret_percentage',
          width: 162,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_cumm_ret_percentage',
          width: 142,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_ALL_all_dayCount_absolute',
          width: 130,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_ALL_all_dayCount_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_ALL_negative_dayCount_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_ALL_negative_dayCount_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_ALL_negative_dayCount_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_ALL_negative_dayCount_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_ALL_positive_dayCount_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_ALL_positive_dayCount_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_ALL_positive_dayCount_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_ALL_positive_dayCount_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_ALL_all_medianReturn_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_ALL_all_medianReturn_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_ALL_all_medianReturn_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_ALL_all_medianReturn_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_ALL_negative_medianReturn_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_ALL_negative_medianReturn_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_ALL_negative_medianReturn_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_ALL_negative_medianReturn_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_ALL_positive_medianReturn_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_ALL_positive_medianReturn_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_ALL_positive_medianReturn_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_ALL_positive_medianReturn_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_ALL_all_meanReturn_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_ALL_all_meanReturn_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_ALL_all_meanReturn_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_ALL_all_meanReturn_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_ALL_negative_meanReturn_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_ALL_negative_meanReturn_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_ALL_negative_meanReturn_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_ALL_negative_meanReturn_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_ALL_positive_meanReturn_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_ALL_positive_meanReturn_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_ALL_positive_meanReturn_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_ALL_positive_meanReturn_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_ALL_all_bestDays_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_ALL_all_bestDays_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_ALL_all_bestDays_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_ALL_all_bestDays_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_ALL_negative_bestDays_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_ALL_negative_bestDays_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_ALL_negative_bestDays_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_ALL_negative_bestDays_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_ALL_positive_bestDays_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_ALL_positive_bestDays_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_ALL_positive_bestDays_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_ALL_positive_bestDays_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_ALL_all_worstDays_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_ALL_all_worstDays_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_ALL_all_worstDays_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_ALL_all_worstDays_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_ALL_negative_worstDays_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_ALL_negative_worstDays_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_ALL_negative_worstDays_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_ALL_negative_worstDays_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_ALL_positive_worstDays_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_ALL_positive_worstDays_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_ALL_positive_worstDays_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_ALL_positive_worstDays_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_cumm_ret_absolute_1',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_cumm_ret_absolute_1',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_cumm_ret_percentage_1',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_cumm_ret_percentage_1',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_MTD_all_dayCount_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_MTD_all_dayCount_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_MTD_negative_dayCount_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_MTD_negative_dayCount_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_MTD_negative_dayCount_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_MTD_negative_dayCount_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_MTD_positive_dayCount_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_MTD_positive_dayCount_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_MTD_positive_dayCount_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_MTD_positive_dayCount_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_MTD_all_medianReturn_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_MTD_all_medianReturn_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_MTD_all_medianReturn_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_MTD_all_medianReturn_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_MTD_negative_medianReturn_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_MTD_negative_medianReturn_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_MTD_negative_medianReturn_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_MTD_negative_medianReturn_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_MTD_positive_medianReturn_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_MTD_positive_medianReturn_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_MTD_positive_medianReturn_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_MTD_positive_medianReturn_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_MTD_all_meanReturn_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_MTD_all_meanReturn_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_MTD_all_meanReturn_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_MTD_all_meanReturn_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_MTD_negative_meanReturn_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_MTD_negative_meanReturn_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_MTD_negative_meanReturn_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_MTD_negative_meanReturn_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_MTD_positive_meanReturn_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_MTD_positive_meanReturn_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_MTD_positive_meanReturn_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_MTD_positive_meanReturn_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_MTD_all_bestDays_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_MTD_all_bestDays_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_MTD_all_bestDays_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_MTD_all_bestDays_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_MTD_negative_bestDays_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_MTD_negative_bestDays_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_MTD_negative_bestDays_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_MTD_negative_bestDays_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_MTD_positive_bestDays_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_MTD_positive_bestDays_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_MTD_positive_bestDays_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_MTD_positive_bestDays_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_MTD_all_worstDays_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_MTD_all_worstDays_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_MTD_all_worstDays_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_MTD_all_worstDays_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_MTD_negative_worstDays_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_MTD_negative_worstDays_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_MTD_negative_worstDays_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_MTD_negative_worstDays_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_MTD_positive_worstDays_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_MTD_positive_worstDays_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_MTD_positive_worstDays_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_MTD_positive_worstDays_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_cumm_ret_absolute_2',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_cumm_ret_absolute_2',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_cumm_ret_percentage_2',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_cumm_ret_percentage_2',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_QTD_all_dayCount_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_QTD_all_dayCount_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_QTD_negative_dayCount_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_QTD_negative_dayCount_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_QTD_negative_dayCount_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_QTD_negative_dayCount_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_QTD_positive_dayCount_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_QTD_positive_dayCount_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_QTD_positive_dayCount_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_QTD_positive_dayCount_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_QTD_all_medianReturn_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_QTD_all_medianReturn_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_QTD_all_medianReturn_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_QTD_all_medianReturn_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_QTD_negative_medianReturn_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_QTD_negative_medianReturn_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_QTD_negative_medianReturn_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_QTD_negative_medianReturn_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_QTD_positive_medianReturn_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_QTD_positive_medianReturn_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_QTD_positive_medianReturn_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_QTD_positive_medianReturn_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_QTD_all_meanReturn_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_QTD_all_meanReturn_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_QTD_all_meanReturn_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_QTD_all_meanReturn_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_QTD_negative_meanReturn_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_QTD_negative_meanReturn_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_QTD_negative_meanReturn_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_QTD_negative_meanReturn_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_QTD_positive_meanReturn_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_QTD_positive_meanReturn_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_QTD_positive_meanReturn_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_QTD_positive_meanReturn_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_QTD_all_bestDays_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_QTD_all_bestDays_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_QTD_all_bestDays_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_QTD_all_bestDays_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_QTD_negative_bestDays_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_QTD_negative_bestDays_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_QTD_negative_bestDays_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_QTD_negative_bestDays_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_QTD_positive_bestDays_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_QTD_positive_bestDays_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_QTD_positive_bestDays_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_QTD_positive_bestDays_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_QTD_all_worstDays_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_QTD_all_worstDays_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_QTD_all_worstDays_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_QTD_all_worstDays_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_QTD_negative_worstDays_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_QTD_negative_worstDays_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_QTD_negative_worstDays_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_QTD_negative_worstDays_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_QTD_positive_worstDays_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_QTD_positive_worstDays_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_QTD_positive_worstDays_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_QTD_positive_worstDays_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_cumm_ret_absolute_3',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_cumm_ret_absolute_3',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_cumm_ret_percentage_3',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_cumm_ret_percentage_3',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_YTD_all_dayCount_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_YTD_all_dayCount_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_YTD_negative_dayCount_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_YTD_negative_dayCount_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_YTD_negative_dayCount_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_YTD_negative_dayCount_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_YTD_positive_dayCount_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_YTD_positive_dayCount_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_YTD_positive_dayCount_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_YTD_positive_dayCount_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_YTD_all_medianReturn_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_YTD_all_medianReturn_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_YTD_all_medianReturn_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_YTD_all_medianReturn_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_YTD_negative_medianReturn_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_YTD_negative_medianReturn_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_YTD_negative_medianReturn_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_YTD_negative_medianReturn_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_YTD_positive_medianReturn_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_YTD_positive_medianReturn_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_YTD_positive_medianReturn_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_YTD_positive_medianReturn_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_YTD_all_meanReturn_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_YTD_all_meanReturn_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_YTD_all_meanReturn_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_YTD_all_meanReturn_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_YTD_negative_meanReturn_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_YTD_negative_meanReturn_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_YTD_negative_meanReturn_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_YTD_negative_meanReturn_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_YTD_positive_meanReturn_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_YTD_positive_meanReturn_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_YTD_positive_meanReturn_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_YTD_positive_meanReturn_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_YTD_all_bestDays_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_YTD_all_bestDays_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_YTD_all_bestDays_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_YTD_all_bestDays_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_YTD_negative_bestDays_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_YTD_negative_bestDays_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_YTD_negative_bestDays_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_YTD_negative_bestDays_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_YTD_positive_bestDays_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_YTD_positive_bestDays_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_YTD_positive_bestDays_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_YTD_positive_bestDays_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_YTD_all_worstDays_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_YTD_all_worstDays_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_YTD_all_worstDays_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_YTD_all_worstDays_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_YTD_negative_worstDays_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_YTD_negative_worstDays_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_YTD_negative_worstDays_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_YTD_negative_worstDays_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_YTD_positive_worstDays_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_YTD_positive_worstDays_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_YTD_positive_worstDays_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_YTD_positive_worstDays_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_cumm_ret_absolute_4',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_cumm_ret_absolute_4',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_cumm_ret_percentage_4',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_cumm_ret_percentage_4',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_WTD_all_dayCount_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_WTD_all_dayCount_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_WTD_negative_dayCount_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_WTD_negative_dayCount_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_WTD_negative_dayCount_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_WTD_negative_dayCount_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_WTD_positive_dayCount_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_WTD_positive_dayCount_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_WTD_positive_dayCount_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_WTD_positive_dayCount_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_WTD_all_medianReturn_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_WTD_all_medianReturn_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_WTD_all_medianReturn_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_WTD_all_medianReturn_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_WTD_negative_medianReturn_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_WTD_negative_medianReturn_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_WTD_negative_medianReturn_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_WTD_negative_medianReturn_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_WTD_positive_medianReturn_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_WTD_positive_medianReturn_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_WTD_positive_medianReturn_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_WTD_positive_medianReturn_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_WTD_all_meanReturn_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_WTD_all_meanReturn_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_WTD_all_meanReturn_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_WTD_all_meanReturn_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_WTD_negative_meanReturn_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_WTD_negative_meanReturn_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_WTD_negative_meanReturn_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_WTD_negative_meanReturn_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_WTD_positive_meanReturn_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_WTD_positive_meanReturn_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_WTD_positive_meanReturn_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_WTD_positive_meanReturn_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_WTD_all_bestDays_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_WTD_all_bestDays_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_WTD_all_bestDays_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_WTD_all_bestDays_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_WTD_negative_bestDays_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_WTD_negative_bestDays_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_WTD_negative_bestDays_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_WTD_negative_bestDays_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_WTD_positive_bestDays_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_WTD_positive_bestDays_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_WTD_positive_bestDays_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_WTD_positive_bestDays_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_WTD_all_worstDays_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_WTD_all_worstDays_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_WTD_all_worstDays_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_WTD_all_worstDays_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_WTD_negative_worstDays_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_WTD_negative_worstDays_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_WTD_negative_worstDays_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_WTD_negative_worstDays_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_WTD_positive_worstDays_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_WTD_positive_worstDays_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_WTD_positive_worstDays_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_WTD_positive_worstDays_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_cumm_ret_absolute_5',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_cumm_ret_absolute_5',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_cumm_ret_percentage_5',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_cumm_ret_percentage_5',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_D_7_all_dayCount_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_D_7_all_dayCount_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_D_7_negative_dayCount_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_D_7_negative_dayCount_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_D_7_negative_dayCount_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_D_7_negative_dayCount_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_D_7_positive_dayCount_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_D_7_positive_dayCount_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_D_7_positive_dayCount_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_D_7_positive_dayCount_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_D_7_all_medianReturn_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_D_7_all_medianReturn_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_D_7_all_medianReturn_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_D_7_all_medianReturn_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_D_7_negative_medianReturn_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_D_7_negative_medianReturn_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_D_7_negative_medianReturn_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_D_7_negative_medianReturn_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_D_7_positive_medianReturn_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_D_7_positive_medianReturn_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_D_7_positive_medianReturn_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_D_7_positive_medianReturn_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_D_7_all_meanReturn_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_D_7_all_meanReturn_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_D_7_all_meanReturn_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_D_7_all_meanReturn_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_D_7_negative_meanReturn_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_D_7_negative_meanReturn_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_D_7_negative_meanReturn_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_D_7_negative_meanReturn_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_D_7_positive_meanReturn_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_D_7_positive_meanReturn_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_D_7_positive_meanReturn_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_D_7_positive_meanReturn_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_D_7_all_bestDays_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_D_7_all_bestDays_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_D_7_all_bestDays_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_D_7_all_bestDays_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_D_7_negative_bestDays_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_D_7_negative_bestDays_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_D_7_negative_bestDays_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_D_7_negative_bestDays_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_D_7_positive_bestDays_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_D_7_positive_bestDays_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_D_7_positive_bestDays_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_D_7_positive_bestDays_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_D_7_all_worstDays_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_D_7_all_worstDays_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_D_7_all_worstDays_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_D_7_all_worstDays_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_D_7_negative_worstDays_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_D_7_negative_worstDays_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_D_7_negative_worstDays_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_D_7_negative_worstDays_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_D_7_positive_worstDays_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_D_7_positive_worstDays_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_D_7_positive_worstDays_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_D_7_positive_worstDays_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_cumm_ret_absolute_6',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_cumm_ret_absolute_6',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_cumm_ret_percentage_6',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_cumm_ret_percentage_6',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_D_30_all_dayCount_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_D_30_all_dayCount_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_D_30_negative_dayCount_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_D_30_negative_dayCount_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_D_30_negative_dayCount_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_D_30_negative_dayCount_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_D_30_positive_dayCount_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_D_30_positive_dayCount_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_D_30_positive_dayCount_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_D_30_positive_dayCount_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_D_30_all_medianReturn_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_D_30_all_medianReturn_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_D_30_all_medianReturn_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_D_30_all_medianReturn_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_D_30_negative_medianReturn_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_D_30_negative_medianReturn_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_D_30_negative_medianReturn_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_D_30_negative_medianReturn_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_D_30_positive_medianReturn_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_D_30_positive_medianReturn_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_D_30_positive_medianReturn_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_D_30_positive_medianReturn_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_D_30_all_meanReturn_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_D_30_all_meanReturn_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_D_30_all_meanReturn_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_D_30_all_meanReturn_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_D_30_negative_meanReturn_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_D_30_negative_meanReturn_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_D_30_negative_meanReturn_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_D_30_negative_meanReturn_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_D_30_positive_meanReturn_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_D_30_positive_meanReturn_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_D_30_positive_meanReturn_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_D_30_positive_meanReturn_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_D_30_all_bestDays_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_D_30_all_bestDays_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_D_30_all_bestDays_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_D_30_all_bestDays_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_D_30_negative_bestDays_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_D_30_negative_bestDays_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_D_30_negative_bestDays_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_D_30_negative_bestDays_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_D_30_positive_bestDays_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_D_30_positive_bestDays_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_D_30_positive_bestDays_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_D_30_positive_bestDays_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_D_30_all_worstDays_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_D_30_all_worstDays_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_D_30_all_worstDays_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_D_30_all_worstDays_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_D_30_negative_worstDays_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_D_30_negative_worstDays_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_D_30_negative_worstDays_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_D_30_negative_worstDays_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_D_30_positive_worstDays_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_D_30_positive_worstDays_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_D_30_positive_worstDays_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_D_30_positive_worstDays_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_cumm_ret_absolute_7',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_cumm_ret_absolute_7',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_cumm_ret_percentage_7',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_cumm_ret_percentage_7',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_D_90_all_dayCount_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_D_90_all_dayCount_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_D_90_negative_dayCount_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_D_90_negative_dayCount_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_D_90_negative_dayCount_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_D_90_negative_dayCount_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_D_90_positive_dayCount_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_D_90_positive_dayCount_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_D_90_positive_dayCount_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_D_90_positive_dayCount_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_D_90_all_medianReturn_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_D_90_all_medianReturn_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_D_90_all_medianReturn_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_D_90_all_medianReturn_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_D_90_negative_medianReturn_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_D_90_negative_medianReturn_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_D_90_negative_medianReturn_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_D_90_negative_medianReturn_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_D_90_positive_medianReturn_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_D_90_positive_medianReturn_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_D_90_positive_medianReturn_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_D_90_positive_medianReturn_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_D_90_all_meanReturn_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_D_90_all_meanReturn_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_D_90_all_meanReturn_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_D_90_all_meanReturn_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_D_90_negative_meanReturn_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_D_90_negative_meanReturn_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_D_90_negative_meanReturn_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_D_90_negative_meanReturn_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_D_90_positive_meanReturn_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_D_90_positive_meanReturn_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_D_90_positive_meanReturn_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_D_90_positive_meanReturn_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_D_90_all_bestDays_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_D_90_all_bestDays_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_D_90_all_bestDays_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_D_90_all_bestDays_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_D_90_negative_bestDays_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_D_90_negative_bestDays_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_D_90_negative_bestDays_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_D_90_negative_bestDays_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_D_90_positive_bestDays_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_D_90_positive_bestDays_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_D_90_positive_bestDays_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_D_90_positive_bestDays_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_D_90_all_worstDays_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_D_90_all_worstDays_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_D_90_all_worstDays_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_D_90_all_worstDays_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_D_90_negative_worstDays_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_D_90_negative_worstDays_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_D_90_negative_worstDays_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_D_90_negative_worstDays_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_D_90_positive_worstDays_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_D_90_positive_worstDays_absolute',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'USD_D_90_positive_worstDays_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'REFERENCE_D_90_positive_worstDays_percentage',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
      ],
    },
  },
];
