import { Box, Breadcrumbs as BreadcrumbsContainer, Stack } from '@mui/joy';
import type { FunctionComponent } from 'react';

import { REPLAY_UNMASK_CLASS_NAME } from 'setup/initializeAll.ts';
import { useBreadcrumbMatch } from '../../../routes/useBreadcrumbMatch';
import GLink from '../GLink/GLink';
import { useAuth } from '../../../UseAuth.tsx';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import IconButton from '@mui/joy/IconButton';

const Breadcrumbs: FunctionComponent = () => {
  const crumbs = useBreadcrumbMatch();
  const { isAdmin } = useAuth();
  return (
    <Box sx={{ display: { xs: 'none', sm: 'block' } }} className={REPLAY_UNMASK_CLASS_NAME}>
      <BreadcrumbsContainer>
        {crumbs.map((crumb, index) =>
          index !== crumbs.length - 1 ? (
            <GLink to={crumb.pathname} key={crumb.breadcrumb}>
              {crumb.breadcrumb}
            </GLink>
          ) : (
            <Stack key={crumb.pathname} direction={'row'} alignItems={'center'} columnGap={0.25}>
              <span>{crumb.breadcrumb}</span>
              {isAdmin && crumb.docs && (
                <IconButton
                  component="a"
                  href={crumb.docs}
                  target={'_blank'}
                  sx={{
                    minHeight: 0,
                    minWidth: 0,
                    textDecoration: 'none',
                  }}
                >
                  <ArticleOutlinedIcon />*
                </IconButton>
              )}
            </Stack>
          )
        )}
      </BreadcrumbsContainer>
    </Box>
  );
};

export default Breadcrumbs;
