import { Chip, Divider, Typography } from '@mui/joy';
import { DateTimeFormat, formatCash, formatDate } from 'components/formatter.utils';
import Loader from 'components/technical/Loader/Loader';
import { Box, Stack } from '@mui/joy';
import dayjs from 'dayjs';
import { useUserTimezone } from 'components/technical/UseUserTimezone.tsx';

export const TotalMarketValueSuggestion = ({
  suggestionData,
  loading,
  error,
  // amount,
}: {
  suggestionData: { value: string; time: string } | undefined;
  loading: boolean | undefined;
  error: string | boolean;
  // amount: string | null;
}) => {
  const timezone = useUserTimezone();

  return (
    <Stack direction={'row'} alignItems={'start'} gap={1}>
      {suggestionData && !loading && (
        <Stack direction={'row'} alignItems={'center'} gap={1}>
          <Divider orientation="vertical" />
          <Box flex={1} alignContent={'center'} gap={0.1}>
            <Typography level="body-sm" component={'div'}>
              {/* {amount && (
                <Chip color="primary" variant="solid" size="sm" disabled>
                  {amount}
                </Chip>
              )} */}
              @
              <Chip color="neutral" variant="solid" size="sm" disabled>
                {formatCash(suggestionData.value)}
              </Chip>
              ({formatDate(dayjs.utc(suggestionData.time), DateTimeFormat.DateTime, timezone)})
            </Typography>
          </Box>
        </Stack>
      )}
      {loading && !error && <Loader variant={'small'} />}
    </Stack>
  );
};
