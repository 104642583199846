import { Avatar, Stack, Typography } from '@mui/joy';
import type { ReactElement } from 'react';

import { createUnsupportedVenueEntry, GENIE_VENUE, venues } from './VenueData.tsx';
import { IconVariant, iconVariantToPx } from '../market/asset/cryptocurrencies/CryptocurrenciesData';
import UnknownIcon from '../technical/UnknownIcon/UnknownIcon.tsx';

export const VenueIcon = ({
  venue,
  size = IconVariant.MEDIUM,
  accountName,
}: {
  venue: Label;
  size?: IconVariant;
  /** for genie exchange we show name of account in the icon */
  accountName?: string;
}) => {
  let entry = venues[venue];
  if (!entry) {
    console.warn('Unsupported venue: ', venue, 'fallback to unsupported name and label');
    entry = createUnsupportedVenueEntry(venue);
  }
  if (venue === GENIE_VENUE) {
    // for genie exchange, we want to show an icon with account name placeholder
    return <UnknownIcon text={accountName ?? '?'} size={size} />;
  }

  const icon = entry.icon;
  const convertedIconSize = iconVariantToPx(size);
  return (
    <Avatar
      component={icon}
      sx={{
        width: convertedIconSize,
        height: convertedIconSize,
        background: entry.iconBackground,
        '& .MuiAvatar-img': {
          objectFit: 'fill',
        },
        padding: '1px',
      }}
      key={venue}
      alt={entry.symbol}
    />
  );
};

const VenueLabel = ({
  venue,
  format = 'long',
  size = IconVariant.MEDIUM,
  accountName,
}: {
  venue: Label;
  format?: 'short' | 'long';
  size?: IconVariant;
  /** for genie exchange we show name of account in the icon */
  accountName?: string;
}): ReactElement => {
  let entry = venues[venue];
  if (!entry) {
    console.warn('Unsupported venue: ', venue, 'fallback to unsupported name and label');
    entry = createUnsupportedVenueEntry(venue);
  }

  if (venue === GENIE_VENUE) {
    // for genie exchange, we want to show an icon with account name placeholder
    return (
      <Stack direction="row" alignItems="center" height="100%">
        <VenueIcon venue={venue} size={size} accountName={accountName} />
      </Stack>
    );
  }

  const entryName = format === 'long' && (
    <Typography component={'span'} sx={{ fontSize: 'inherit', lineHeight: 'inherit' }}>
      {entry.name}
      {entry.adminOnly ? '*' : null}
    </Typography>
  );

  return (
    <Stack direction="row" spacing={1.5} alignItems="center">
      <VenueIcon venue={venue} size={size} accountName={accountName} />
      {entryName}
    </Stack>
  );
};

export default VenueLabel;
