import isNil from 'lodash/fp/isNil';

import type { FormOutputFields, LegFormOutput } from './TransactionForm.validation.ts';
import {
  type IAsset,
  type ICreateTransactionV2InputType,
  type IOrderSide,
  type IOrderType,
  type ITransactionLegInputType,
  ITransactionLegType,
  ITransactionStatus,
  type ITransactionV2,
  IUserTransactionType,
} from '../../../../generated/graphql.tsx';
import type { RecursivePartial } from '../../../type.utils.ts';
import type dayjs from 'dayjs';

const cashFlowAsDefault = new Set([
  IUserTransactionType.Deposit,
  IUserTransactionType.Withdrawal,
  IUserTransactionType.InternalTransfer,
]);

const createTransactionLegs = (
  userType: IUserTransactionType,
  fallbackDate: dayjs.Dayjs,
  legs: LegFormOutput[]
): ITransactionLegInputType[] => {
  return legs.map((leg) => ({
    amount: leg.amount.toString(),
    asset: {
      id: leg.asset.id,
    },
    side: leg.side,
    slot: leg.slot,
    time: (leg.time ?? fallbackDate).toISOString(),
    type: isNil(leg.type)
      ? cashFlowAsDefault.has(userType)
        ? ITransactionLegType.Cashflow
        : ITransactionLegType.Any
      : leg.type,
    unitValue: isNil(leg.marketValue) ? undefined : (leg.marketValue / leg.amount).toString(),
  }));
};

export const isOrderEmpty = (data: {
  side: IOrderSide | null;
  type: IOrderType | null;
  externalId: string;
}): boolean => {
  return !data.side || !data.type || !data.externalId;
};

export const hasOrderAtLeastOneValue = (data: {
  side: IOrderSide | null;
  type: IOrderType | null;
  externalId: string;
}): boolean => {
  return !!data.side || !!data.type || !!data.externalId;
};

export type UpdateableTransaction = Omit<ITransactionV2, 'attributedToAsset' | 'legs' | 'subAccount' | 'order'> & {
  attributedToAsset?: RecursivePartial<IAsset> | null | undefined;
  order?: {
    type: IOrderType;
    side: IOrderSide;
    externalId: string;
  } | null;
  subAccount: {
    id: string;
    name: string;
    account: {
      id: string;
      name: string;
      venue: {
        label: string;
      };
    };
  };
  legs: (Omit<ITransactionV2['legs'][number], 'asset' | 'journalEntry'> & {
    asset: RecursivePartial<IAsset> | null | undefined;
  })[];
};

export const createTransactionOutput = (data: FormOutputFields): ICreateTransactionV2InputType => {
  return {
    userType: data.userType,
    time: data.time.toISOString(),
    attributedToAsset: data.attributedToAsset
      ? {
          id: data.attributedToAsset.id,
        }
      : null,
    externalId: data.externalId,
    order: isOrderEmpty(data.order)
      ? null
      : {
          side: data.order.side as IOrderSide,
          type: data.order.type as IOrderType,
          externalId: data.order.externalId!,
        },
    externalType: data.externalType,
    status: data.status ?? ITransactionStatus.Unknown,
    legs: createTransactionLegs(data.userType, data.time, data.legs),
    tags: data.tags,
    comment: data.comment,
    subAccount: {
      id: data.subAccount.id,
    },
  };
};

export const transactionDialogProps = {
  minWidth: '80vw',
  size: 'lg',
} as const;
