import type { CellContext, SortingState } from '@tanstack/table-core';
import dayjs, { type Dayjs } from 'dayjs';
import isNil from 'lodash/fp/isNil';
import orderBy from 'lodash/fp/orderBy';
import { type BigNumber, bignumber } from 'mathjs';
import { type ReactElement, type ReactNode, useState } from 'react';
import { isValidDayjsDateRange } from 'components/date.utils';
import GTable from 'components/technical/GTable/GTable.tsx';
import { useTablePaginator } from 'components/technical/GTable/UseTablePaginator.tsx';
import DateRangeInput from 'components/technical/inputs/date/DateRangeInput';
import GCheckbox from 'components/technical/inputs/GCheckbox/GCheckbox';

import { useUserTimezone } from 'components/technical/UseUserTimezone.tsx';
import { minSnapshotDiscrepancyUSD } from './Reconciliation.utils.ts';
import {
  type IAccount,
  IAssetType,
  type IPositionSide,
  type ISubAccount,
  useReconciliationReportQuery,
} from '../../../generated/graphql';
import { DateTimeFormat, formatCash, formatDate, formatEnum } from '../../formatter.utils';
import AssetLabel from '../../market/asset/AssetLabel';
import { SubAccountLabel } from '../../portfolio/account/SubAccountLabel.tsx';
import { AccountLabel } from '../../portfolio/account/AccountLabel.tsx';
import { useTheme } from '@mui/joy';
import type { NotVerifiedAsset } from '../../market/asset/AssetLabelService.ts';
import { convertDateRangeToSinceToDate } from '../../technical/inputs/date/dateRange.utils.ts';
import { shellPadding } from '../../technical/PageShell/PageShell.utils.ts';
import { useSubAccountAssetFilters } from '../../technical/SubAccountAssetFilterDrawer/UseSubAccountAssetFilters.tsx';

interface ReportRow {
  startTime: Dayjs | undefined;
  endTime: Dayjs;
  transactionChange: BigNumber;
  snapshotChange: BigNumber;
  amountDifference: BigNumber;
  asset: NotVerifiedAsset & { id: string };
  side: IPositionSide | null | undefined;
  subAccount: Pick<ISubAccount, 'name' | 'id'> & { account: Pick<IAccount, 'id' | 'name' | 'venue'> };
  assetUsdPrice: BigNumber | undefined;
}

const endingDateColumnId = 'endingDateId';
const initialDateColumnId = 'initialDateId';

const ReconciliationReport = (): ReactElement => {
  const [dateRange, setDateRange] = useState<[Dayjs, Dayjs] | null>([dayjs.utc().subtract(30, 'days'), dayjs.utc()]);
  const [hideSmallDifferences, setHideSmallDifferences] = useState<boolean>(true);
  const [sorting, setSorting] = useState<SortingState>([
    {
      id: initialDateColumnId,
      desc: true,
    },
  ]);
  const theme = useTheme();
  const { subAccountAssetFilters } = useSubAccountAssetFilters();

  const isValidDate = isValidDayjsDateRange(dateRange);
  const timezone = useUserTimezone();

  const { loading, error, data } = useReconciliationReportQuery({
    variables: {
      minUsdDiscrepancy: hideSmallDifferences ? bignumber(minSnapshotDiscrepancyUSD).toString() : null,
      dates: convertDateRangeToSinceToDate(dateRange),
      subAccountAssetFilters: subAccountAssetFilters,
    },
    skip: !isValidDate,
  });

  const rows: ReportRow[] = (data?.bookkeeping.reconciliation.report ?? []).map((row) => {
    return {
      startTime: row.startTime ? dayjs.utc(row.startTime) : undefined,
      endTime: dayjs.utc(row.endTime),
      transactionChange: bignumber(row.transactionBalanceChangeAmount),
      snapshotChange: bignumber(row.snapshotBalanceChangeAmount),
      amountDifference: bignumber(row.snapshotBalanceChangeAmount).sub(row.transactionBalanceChangeAmount),
      subAccount: row.subAccount,
      asset: row.asset,
      side: row.side,
      assetUsdPrice: isNil(row.assetClosingPriceUsd) ? undefined : bignumber(row.assetClosingPriceUsd),
    };
  });

  const columns = [
    {
      id: initialDateColumnId,
      header: 'Initial date',
      cell: (context: CellContext<ReportRow, unknown>): ReactNode =>
        formatDate(context.row.original.startTime, DateTimeFormat.DateTime, timezone),
      accessorFn: (row: ReportRow): unknown => row.startTime?.toDate() ?? null,
    },
    {
      id: endingDateColumnId,
      header: 'Ending date',
      cell: (context: CellContext<ReportRow, unknown>): ReactNode =>
        formatDate(context.row.original.endTime, DateTimeFormat.DateTime, timezone),
      accessorFn: (row: ReportRow): unknown => row.endTime.toDate(),
    },
    {
      id: 'account',
      header: 'Account',
      cell: (context: CellContext<ReportRow, unknown>): ReactNode => {
        const { subAccount } = context.row.original;
        return <AccountLabel account={subAccount.account} />;
      },
      accessorFn: (row: ReportRow): unknown => row.subAccount.name,
    },
    {
      id: 'subaccount',
      header: 'Sub-account',
      cell: (context: CellContext<ReportRow, unknown>): ReactNode => {
        const { subAccount } = context.row.original;
        return <SubAccountLabel subAccount={subAccount} />;
      },
      accessorFn: (row: ReportRow): unknown => row.subAccount.name,
    },
    {
      id: 'asset',
      header: 'Asset',
      cell: (context: CellContext<ReportRow, unknown>): ReactNode => {
        const { asset } = context.row.original;
        return <AssetLabel asset={asset} link={false} />;
      },
      accessorFn: (row: ReportRow): unknown => row.asset.symbol,
    },
    {
      id: 'side',
      header: 'Side',
      type: 'textColumn',
      accessorFn: (row: ReportRow): string => {
        return formatEnum(row.side);
      },
    },
    {
      id: 'balanceChange',
      header: 'Ending balance - initial balance (units)',
      cell: (context: CellContext<ReportRow, unknown>): ReactNode => {
        const { snapshotChange } = context.row.original;
        return snapshotChange.toFixed();
      },
      accessorFn: (row: ReportRow): unknown => row.snapshotChange.toNumber(),
    },
    {
      id: 'transactionsChange',
      header: 'Total transactions change (units)',
      cell: (context: CellContext<ReportRow, unknown>): ReactNode => {
        const { transactionChange } = context.row.original;
        return transactionChange.toFixed();
      },
      accessorFn: (row: ReportRow): unknown => row.transactionChange.toNumber(),
    },
    {
      id: 'diffChange',
      header: 'Unreconciled amount (units)',
      cell: (context: CellContext<ReportRow, unknown>): ReactNode => {
        const { amountDifference } = context.row.original;
        return amountDifference.toFixed();
      },
      accessorFn: (row: ReportRow): unknown => row.amountDifference.toNumber(),
    },
    {
      id: 'unreconciledUsd',
      header: 'Unreconciled amount (USD)',
      cell: (context: CellContext<ReportRow, unknown>): ReactNode => {
        const { amountDifference, assetUsdPrice, asset } = context.row.original;
        if (isNil(assetUsdPrice)) {
          return '-';
        }

        if (asset.type === IAssetType.Derivative) {
          return undefined;
        }

        return formatCash(amountDifference.mul(assetUsdPrice));
      },
      accessorFn: (row: ReportRow): undefined | number => {
        if (isNil(row.assetUsdPrice)) {
          return undefined;
        }

        if (row.asset.type === IAssetType.Derivative) {
          return undefined;
        }

        return row.amountDifference.mul(row.assetUsdPrice).toNumber();
      },
    },
  ].map((col) => ({
    ...col,
    enableSorting: !!col.accessorFn,
  }));

  const sortingComparator = sorting
    .map((columnSort) => {
      const column = columns.find((col) => col.id === columnSort.id);
      const accessor = column?.accessorFn;
      if (!accessor) {
        return undefined;
      }

      return { accessor: accessor, desc: columnSort.desc };
    })
    .filter((sort): sort is { accessor: (row: ReportRow) => unknown; desc: boolean } => sort !== undefined);

  const sortedRows = orderBy(
    sortingComparator.map((comp) => comp?.accessor),
    sortingComparator.map((comp) => (comp?.desc ? 'desc' : 'asc')),
    rows
  );

  const { tablePaginator, page } = useTablePaginator({
    filters: {
      hideSmallDifferences,
      dateRange,
    },
    defaultPageSize: 50,
  });

  const limitedRows = sortedRows.slice(page.offset, page.offset + page.limit);

  const width = 'xl2' as const;
  return (
    <GTable
      filters={[
        {
          component: DateRangeInput,
          value: dateRange,
          width,
          error: isValidDate ? undefined : 'Invalid date',
          onChange: setDateRange,
          label: 'Ending date',
          defaultValue: null,
          defaultFilter: true,
        },
        {
          component: GCheckbox,
          checked: hideSmallDifferences,
          width: 'md',
          onChange: setHideSmallDifferences,
          label: 'Hide small differences',
          defaultValue: true,
          defaultFilter: true,
        },
      ]}
      headerBackground={theme.palette.background.body}
      columns={columns}
      data={limitedRows}
      error={error}
      loading={loading}
      sortingState={sorting}
      onSortingChange={setSorting}
      paginator={tablePaginator}
      totalResults={rows.length}
      // fullPage height - page header - padding below the table and make room for scroll bar
      maxHeight={`calc(100vh - var(--header-height) - 0.5rem * ${shellPadding} - 0.5rem)`}
      stickyHeader
    />
  );
};

export default ReconciliationReport;
