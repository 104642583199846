import type { Theme } from '@mui/joy';

export const BUTTON_MENU_POSITION_STYLE = { position: 'absolute', top: 9, right: 9 };

export const GRID_FULL_WIDTH = { xl: 12, lg: 12, md: 12, sm: 12 };

export const SELECTABLE_CARD_STYLE = (theme: Theme) => ({
  minHeight: '100px',
  height: '100%',
  transition: 'all 0.1s ease-in',
  borderOffset: '2px',
  '&:hover': {
    cursor: 'pointer',
    border: `1px solid ${theme.palette.mode === 'light' ? theme.palette.primary[500] : theme.palette.common.white}`,
  },
  '&:hover .title': {
    color: theme.palette.mode === 'light' ? theme.palette.primary[500] : theme.palette.common.white,
  },
});

export const ADD_NEW_CARD_STYLE = (theme: Theme) => ({
  backgroundColor: theme.palette.mode === 'light' ? theme.palette.primary[100] : theme.palette.neutral[800],
  borderColor: theme.palette.mode === 'light' ? theme.palette.primary[200] : theme.palette.primary[500],
});
