import { Box, Grid, LinearProgress, Stack, Tooltip, Typography } from '@mui/joy';
import dayjs, { type Dayjs } from 'dayjs';
import { bignumber } from 'mathjs';
import type { FunctionComponent } from 'react';
import { UserSettings } from 'components/management/UserSettings.types.ts';

import { useSubAccountAssetFilters } from 'components/technical/SubAccountAssetFilterDrawer/UseSubAccountAssetFilters';

import { tooltipEnterDelay } from 'theme/consts.ts';
import { getReturnsColor, getSavedDaysRange, getTileValues } from './PortfolioTilesService';
import {
  type IMetricCalculatorInput,
  type IPortfolioDashboardInputQuery,
  usePortfolioTilesDetailsQuery,
  usePortfolioTilesMetricsQuery,
  useUserSettingsQuery,
} from '../../../generated/graphql';
import { convertDateInUtcToUTCISODate } from '../../date.utils';
import { DateTimeFormat, formatCash, formatDate, formatPercentage } from '../../formatter.utils';
import { logErrorOnce } from '../../log.utils.ts';
import TitleValueTile from '../../technical/Tile/TitleValueTile';
import TitleWithChip from '../../technical/Tile/TitleWithChip';
import { PORTFOLIO_VOLATILITY_METRIC } from 'components/metrics/PortfolioMetricsData.ts';
import { validateMetricParameters } from '../../copilot/risk/assetRiskMetrics/assetRiskMetricsReport.utils.ts';
import drop from 'lodash/fp/drop';
import isNil from 'lodash/fp/isNil';

type TileProgressFooterProps = {
  minValue: string;
  maxValue: string;
  progressValue: number | undefined;
};

const TileProgressFooter: FunctionComponent<TileProgressFooterProps> = ({ minValue, maxValue, progressValue }) => {
  return (
    <Stack>
      <Stack direction="row" justifyContent="space-between">
        <Typography level="body-xs2">{minValue}</Typography>
        <Typography level="body-xs2">{maxValue}</Typography>
      </Stack>
      <LinearProgress size="lg" color="primary" determinate value={progressValue ?? undefined} />
    </Stack>
  );
};

const METRICS_RANGE_DAYS = 60;
const DAILY_RETURN_RANGE_DAYS = 30;

const calculateTooltipText = (date: Dayjs): string => {
  return `Time frame: ${formatDate(date, DateTimeFormat.ShortDate)} - today. Edit in Control center.`;
};

type PortfolioTilesProps = {
  metricParameters: IPortfolioDashboardInputQuery['portfolio']['metricParameters'];
};

const PortfolioTiles: FunctionComponent<PortfolioTilesProps> = ({ metricParameters }) => {
  const currentDate = dayjs.utc();
  const { subAccountAssetFilters } = useSubAccountAssetFilters();

  const userSettingsQueryResult = useUserSettingsQuery({
    variables: {
      field: UserSettings.TilesDateRange,
    },
  });

  // saved range is used only for equity and TWR tiles
  // "Daily return" uses fixed 30 days range, "Volatility" uses fixed 60 days range
  const { savedDateRange, savedTimeFrameInDays } = isNil(userSettingsQueryResult?.data)
    ? { savedTimeFrameInDays: undefined, savedDateRange: undefined }
    : getSavedDaysRange(userSettingsQueryResult.data);
  // we show end of day returns, so when someone wants to see returns since start of day we need to take snapshots one day earlier
  const twrSince = savedTimeFrameInDays ? currentDate.subtract(savedTimeFrameInDays, 'day') : null;
  const portfolioTilesDetails = usePortfolioTilesDetailsQuery({
    variables: {
      twrSince: convertDateInUtcToUTCISODate(twrSince ?? dayjs.utc()),
      twrTo: convertDateInUtcToUTCISODate(dayjs.utc()),
      returnsSince: convertDateInUtcToUTCISODate(currentDate.subtract(DAILY_RETURN_RANGE_DAYS, 'day')),
      subAccountAssetFilters,
    },
    skip: !twrSince,
  });

  const portfolio = portfolioTilesDetails.data?.portfolio;
  const twrFilteredBySavedRange = portfolio?.filteredTimeWeightedReturns.values;

  const equityRows = (twrFilteredBySavedRange ?? []).map((row) => ({
    value: row.return.balance,
  }));
  const equityTile = getTileValues(equityRows);

  const twrFilteredBySavedRangeRows = twrFilteredBySavedRange?.map((twr) => ({
    value: twr.return.weight,
  }));
  const twrTile = getTileValues(twrFilteredBySavedRangeRows?.slice(1));

  const dailyReturnRows = drop(1, portfolio?.recentReturns.values ?? []);
  const dailyReturnLastValue = dailyReturnRows?.at(-1)?.return.return ?? 0;
  const dailyReturnLastValuePercentage = bignumber(dailyReturnRows?.at(-1)?.return.weighted_return ?? 0);

  const volatilityInput: IMetricCalculatorInput = {
    label: PORTFOLIO_VOLATILITY_METRIC,
    parameters: [
      {
        name: 'Window',
        intValues: [30],
      },
      {
        name: 'Window type',
        strValues: ['rolling'],
      },
      {
        name: 'Metric type',
        strValues: ['realized'],
      },
      {
        name: 'Annualized',
        strValues: ['not_annualized'],
      },
    ],
  };
  const volatilityParametersAreValid = validateMetricParameters(metricParameters, [volatilityInput]).isValid;

  const metricQuery = usePortfolioTilesMetricsQuery({
    variables: {
      metrics: volatilityInput,
      metricsSince: convertDateInUtcToUTCISODate(currentDate.subtract(METRICS_RANGE_DAYS, 'day')),
      metricsTo: convertDateInUtcToUTCISODate(dayjs.utc()),
      subAccountAssetFilters,
    },
    skip: !volatilityParametersAreValid,
  });

  const volatilityRows = metricQuery.data?.portfolio.metrics[0]?.series[0]?.values.map((volatility) => ({
    value: volatility.value,
  }));

  const volatilityTile = getTileValues(volatilityRows);
  if (portfolioTilesDetails.error) {
    logErrorOnce('Failed to fetch portfolio details tiles', portfolioTilesDetails.error);
  }

  if (metricQuery.error) {
    logErrorOnce('Failed to fetch portfolio metric tiles', metricQuery.error);
  }

  const equityRangeTooltip = savedDateRange ? calculateTooltipText(savedDateRange.value()![0]) : '';
  const twrRangeTooltip = twrSince ? calculateTooltipText(twrSince) : '';
  const savedRangeLabel = savedDateRange?.label ? `(${savedDateRange.label.toUpperCase()})` : '';

  return (
    <Grid container alignItems="stretch" spacing={1.5}>
      <TitleValueTile
        loading={portfolioTilesDetails.loading}
        title={<TitleWithChip title="Equity value" change={equityTile?.change} />}
        value={formatCash(equityTile?.latestValue)}
        footer={
          <Tooltip title={equityRangeTooltip} enterDelay={tooltipEnterDelay.Slow}>
            <span>
              <TileProgressFooter
                minValue={formatCash(equityTile?.minValue)}
                maxValue={formatCash(equityTile?.maxValue)}
                progressValue={equityTile?.progressValue}
              />
            </span>
          </Tooltip>
        }
      />
      <TitleValueTile
        loading={portfolioTilesDetails.loading}
        title={<Typography lineHeight={2}>TWR {savedRangeLabel}</Typography>}
        value={formatPercentage(twrTile?.latestValue)}
        footer={
          <Tooltip title={twrRangeTooltip} enterDelay={tooltipEnterDelay.Slow}>
            <span>
              <TileProgressFooter
                minValue={formatPercentage(twrTile?.minValue)}
                maxValue={formatPercentage(twrTile?.maxValue)}
                progressValue={twrTile?.progressValue}
              />
            </span>
          </Tooltip>
        }
      />
      <TitleValueTile
        loading={portfolioTilesDetails.loading}
        title={<Typography lineHeight={2}>Daily return</Typography>}
        value={formatCash(dailyReturnLastValue)}
        caption={`(${formatPercentage(dailyReturnLastValuePercentage)})`}
        footer={
          <Stack>
            <Typography level="body-xs2">Last 30 days</Typography>
            <Stack direction="row" gap={0.25}>
              {dailyReturnRows?.map((dailyReturn) => {
                return (
                  <Box
                    borderRadius={2}
                    height={16}
                    width="100%"
                    maxWidth={12}
                    minWidth={2}
                    key={dailyReturn.date.toString()}
                    sx={{
                      bgcolor: (theme) => getReturnsColor(dailyReturn.return.weighted_return, theme),
                    }}
                  />
                );
              })}
            </Stack>
          </Stack>
        }
      />
      <TitleValueTile
        loading={metricQuery.loading}
        title={<TitleWithChip title="Volatility (30D)" change={volatilityTile?.change} />}
        value={formatPercentage(volatilityTile?.latestValue)}
        footer={
          volatilityParametersAreValid ? (
            <TileProgressFooter
              maxValue={formatPercentage(volatilityTile?.maxValue)}
              minValue={formatPercentage(volatilityTile?.minValue)}
              progressValue={volatilityTile?.progressValue}
            />
          ) : (
            <Typography level="body-xs2">Not enough data</Typography>
          )
        }
      />
    </Grid>
  );
};

export default PortfolioTiles;
