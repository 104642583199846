import { Stack } from '@mui/joy';
import GAgGridPreserveState from 'components/technical/grids/GAgGridPreserveState.tsx';
import { type FunctionComponent, useState } from 'react';
import { UserSettings } from 'components/management/UserSettings.types.ts';
import { type AssetLabelInput, isAssetLabelInput } from 'components/market/asset/AssetLabelService.ts';
import { useSubAccountAssetFilters } from 'components/technical/SubAccountAssetFilterDrawer/UseSubAccountAssetFilters.tsx';
import { useDefaultErrorHandling } from 'components/technical/UseDefaultErrorHandling.tsx';
import { useAssetRiskMetricsQuery, useAssetRiskMetricsReportAssetsAndParametersQuery } from 'generated/graphql.tsx';
import {
  assetMetricColumn,
  nameColumn,
  portfolioMetricContributionColumn,
  type PortfolioRiskMetricReportMapKey,
  type PortfolioRiskMetricReportMapValue,
  symbolColumn,
} from '../../../technical/grids/SharedReportColumns.tsx';

import { logErrorOnce } from 'components/log.utils.ts';
import type { ColDef, ColGroupDef } from 'ag-grid-community';
import { isNil } from 'lodash/fp';
import {
  assetMetricColumnConfigurations,
  contributionMetricColumns,
  groupBalanceByAsset,
  type MetricParameter,
  portfolioContributionMetricsBenchmarkAssetsSymbols,
  type PositionRow,
} from './assetRiskMetricsReport.utils.ts';
import PortfolioContributionMetricsLoader from './PortfolioContributionMetricsLoader.tsx';
import { ObjectKeyMap } from 'components/objectKeyMap.ts';
import { createColumnToolDef } from '../../../technical/grids/agGrid.utils.tsx';

const metricWindowOptions = [30, 60, 90] as const;

/**
 * Portfolio's assets risk metrics report
 * shows metrics for all assets in portfolio + portfolio contribution portfolio metrics
 */
const AssetsRiskReportContainer: FunctionComponent = () => {
  const { subAccountAssetFilters } = useSubAccountAssetFilters();
  const portfolioAssetsQueryResult = useDefaultErrorHandling(
    useAssetRiskMetricsReportAssetsAndParametersQuery({
      variables: {
        subAccountAssetFilters,
      },
    })
  );

  if (!portfolioAssetsQueryResult.loaded) {
    return <portfolioAssetsQueryResult.Fallback />;
  }

  const positions = portfolioAssetsQueryResult.data.portfolio.positions.positions.filter((p): p is PositionRow =>
    isAssetLabelInput(p.asset)
  );

  return (
    <AssetsRiskReport
      knownAssets={portfolioAssetsQueryResult.data.assets.feature}
      balanceGroupedByAsset={groupBalanceByAsset(positions)}
      portfolioMetricParametersDescription={portfolioAssetsQueryResult.data.portfolio.metricParameters}
    />
  );
};

type AssetsRiskReportProps = {
  knownAssets: { id: string; symbol: string }[];
  balanceGroupedByAsset: { asset: AssetLabelInput; balance: number }[];
  portfolioMetricParametersDescription: MetricParameter[];
};

const AssetsRiskReport: FunctionComponent<AssetsRiskReportProps> = ({
  knownAssets,
  balanceGroupedByAsset,
  portfolioMetricParametersDescription,
}) => {
  const portfolioAssets = Array.from(balanceGroupedByAsset.map((a) => a.asset.id));

  const assetRiskMetricsQueryResult = useDefaultErrorHandling(
    useAssetRiskMetricsQuery({
      variables: {
        assetIds: portfolioAssets,
      },
    })
  );

  const portfolioContributionMetricsBenchmarkAssets = portfolioContributionMetricsBenchmarkAssetsSymbols
    .map((symbol) => knownAssets.find((asset) => asset.symbol === symbol))
    .filter(
      (
        a
      ): a is {
        id: string;
        symbol: string;
      } => Boolean(a)
    );

  const [portfolioContributionMetricValues, setPortfolioContributionMetricValues] = useState<ObjectKeyMap<
    PortfolioRiskMetricReportMapKey,
    PortfolioRiskMetricReportMapValue
  > | null>(null);

  if (!assetRiskMetricsQueryResult.loaded) {
    return <assetRiskMetricsQueryResult.Fallback />;
  }

  const assetMetrics = assetRiskMetricsQueryResult.data.assets.details;
  const groupedAssetMetrics = new Map(assetMetrics.map((assetMetrics) => [assetMetrics.asset, assetMetrics.metrics]));

  const portfolioContributionMetricsLoaded = !isNil(portfolioContributionMetricValues);

  return (
    <Stack spacing={1.5} height="100%">
      <Stack direction="row">
        <PortfolioContributionMetricsLoader
          knownAssets={knownAssets}
          portfolioMetricParametersDescription={portfolioMetricParametersDescription}
          onContributionMetricsLoaded={setPortfolioContributionMetricValues}
        />
      </Stack>

      <GAgGridPreserveState<AssetsRiskReportProps['balanceGroupedByAsset'][number]>
        userSettingsKey={UserSettings.AssetRiskMetricsReport}
        rowData={balanceGroupedByAsset}
        sideBar={{
          toolPanels: [
            createColumnToolDef({
              suppressRowGroups: true,
              suppressValues: true,
              suppressPivotMode: true,
            }),
            'filters',
          ],
        }}
        enableCharts
        cellSelection
        defaultColDef={{
          resizable: true,
          sortable: true,
          filter: true,
        }}
        onGridReady={(e) => {
          const columnsToolPanel = e.api.getToolPanelInstance('columns');
          if (!columnsToolPanel) {
            logErrorOnce('columnsToolPanel is not found');
            return;
          }
          // initially we don't load portfolio contribution metrics, so it makes sense to make columns panel tidy
          columnsToolPanel.collapseColumnGroups(['contribution-30', 'contribution-60', 'contribution-90']);
        }}
        columnDefs={[
          nameColumn(),
          symbolColumn({ pinned: 'left', lockVisible: true, lockPinned: true, initialHide: false }),
          {
            headerName: 'Balance',
            field: 'balance',
            type: ['numericColumn', 'cashColumn'],
          },
          {
            headerName: 'Fundamentals',
            marryChildren: true,
            children: assetMetricColumnConfigurations.fundamentals.map((colConfig) =>
              assetMetricColumn<AssetsRiskReportProps['balanceGroupedByAsset'][number]>(
                groupedAssetMetrics,
                colConfig.metricLabel,
                colConfig.initialHide
              )
            ),
          },
          ...metricWindowOptions.map((window) => {
            const windowConfigKey = String(window) as keyof typeof assetMetricColumnConfigurations;

            const childrenColumnsByWindow: Array<
              | ColDef<AssetsRiskReportProps['balanceGroupedByAsset'][number]>
              | ColGroupDef<AssetsRiskReportProps['balanceGroupedByAsset'][number]>
            > = assetMetricColumnConfigurations[windowConfigKey].map((colConfig) =>
              assetMetricColumn<AssetsRiskReportProps['balanceGroupedByAsset'][number]>(
                groupedAssetMetrics,
                colConfig.metricLabel,
                colConfig.initialHide
              )
            );

            if (portfolioContributionMetricsLoaded) {
              childrenColumnsByWindow.push({
                headerName: 'Contribution to portfolio risk metric',
                marryChildren: true,
                groupId: `contribution-${window}`,
                children: contributionMetricColumns(portfolioContributionMetricsBenchmarkAssets).flatMap(
                  (colConfig) => [
                    portfolioMetricContributionColumn({
                      portfolioMetricValues: portfolioContributionMetricValues ?? new ObjectKeyMap(),
                      metricLabel: colConfig.label,
                      window,
                      benchmark: colConfig.benchmark,
                      ofTotal: false,
                      hide: !portfolioContributionMetricsLoaded,
                      lockVisible: !portfolioContributionMetricsLoaded,
                    }),
                    portfolioMetricContributionColumn({
                      portfolioMetricValues: portfolioContributionMetricValues ?? new ObjectKeyMap(),
                      metricLabel: colConfig.label,
                      window,
                      benchmark: colConfig.benchmark,
                      ofTotal: true,
                      hide: !portfolioContributionMetricsLoaded,
                      lockVisible: !portfolioContributionMetricsLoaded,
                    }),
                  ]
                ),
              });
            }

            return {
              headerName: `${window} Days`,
              marryChildren: true,
              children: childrenColumnsByWindow,
            };
          }),
        ]}
      />
    </Stack>
  );
};

export default AssetsRiskReportContainer;
