import type { ColDef, ColGroupDef, ValueGetterParams } from 'ag-grid-community';
import { dateReadableValueGetter } from 'components/technical/grids/agGrid.utils.tsx';
import { useDailyPnlSuspenseQuery } from 'generated/graphql';
import {
  accountColumn,
  subAccountColumn,
  subFundGroupColumn,
  venueColumn,
} from 'components/technical/grids/SharedReportColumns';
import { mapSubAccountAndDimensionToSubFund } from 'components/bookkeeping/report/Report.utils';
import { bignumber } from 'mathjs';
import type { Dayjs } from 'dayjs';
import isNil from 'lodash/fp/isNil';
import { convertDateRangeToSinceToDate } from '../../../technical/inputs/date/dateRange.utils.ts';
import { useSubAccountAssetFilters } from '../../../technical/SubAccountAssetFilterDrawer/UseSubAccountAssetFilters.tsx';
import type { ReactElement } from 'react';
import { UserSettings } from 'components/management/UserSettings.types.ts';
import GAgGridPresets from 'components/technical/grids/GAgGridPresets.tsx';
import { dailyPnlDefaultPresets } from './defaultPresets.ts';
import * as returnsUtil from './returnsAgg.utils.ts';
import type { RowData } from './DailyPnl.types.ts';

const defaultColDef = {
  resizable: true,
  sortable: true,
  filter: true,
};

const DEFAULT_AGG_FUNCTIONS = ['sum', 'avg', 'count', 'min', 'max', 'first', 'last'];

const DailyPnlGrid = ({ dateRange }: { dateRange: [Dayjs, Dayjs] | null }): ReactElement => {
  const { subAccountAssetFilters } = useSubAccountAssetFilters();
  const queryResult = useDailyPnlSuspenseQuery({
    variables: {
      subAccountAssetFilters,
      ...convertDateRangeToSinceToDate(dateRange),
    },
  });

  const subFunds = queryResult.data.portfolio.subFunds.list;

  const rows = queryResult.data.portfolio.subAccountsTimeWeightedReturns.subAccounts.flatMap((subAccount) =>
    subAccount.values.map((value) => ({
      ...value,
      subAccount: subAccount.subAccount,
    }))
  );

  const { subAccountAndDimensionToSubFund, subFundDimensions } = mapSubAccountAndDimensionToSubFund(subFunds);

  const returnsColId = 'returns';
  const customAgg = {
    agg: returnsUtil.returnsAgg,
  };

  const columns: (ColDef<RowData> | ColGroupDef<RowData>)[] = [
    {
      headerName: 'Date',
      field: 'date',
      type: 'dateColumn',
      initialSort: 'desc',
      valueGetter: (params: ValueGetterParams<RowData>): string | undefined =>
        params.data ? dateReadableValueGetter(params.data.date) : undefined,
    },
    {
      headerName: 'Account Details',
      colId: 'account-details',
      marryChildren: true,
      children: [accountColumn(), subAccountColumn({ initialHide: true }), venueColumn({ initialHide: true })],
    },
    {
      headerName: 'Balance',
      type: ['numericColumn', 'cashColumn'],
      valueGetter: (params): number | undefined => {
        if (isNil(params.data?.return.balance)) {
          return undefined;
        }
        return bignumber(params.data?.return.balance).toNumber();
      },
      allowedAggFuncs: DEFAULT_AGG_FUNCTIONS,
    },
    {
      headerName: 'P&L',
      type: ['numericColumn', 'cashColumn'],
      valueGetter: (params): number | undefined => {
        if (isNil(params.data?.return.return)) {
          return undefined;
        }
        return bignumber(params.data?.return.return).toNumber();
      },
      allowedAggFuncs: DEFAULT_AGG_FUNCTIONS,
    },
    {
      colId: returnsColId,
      headerName: 'Returns',
      type: ['numericColumn', 'percentageColumn'],
      valueGetter: returnsUtil.valueGetters,
      aggFunc: returnsUtil.returnsAgg,
      allowedAggFuncs: ['agg'],
    },
    {
      headerName: 'Cash flow',
      type: ['numericColumn', 'cashColumn'],
      valueGetter: (params): number | undefined => {
        if (isNil(params.data?.return.cash_flow)) {
          return undefined;
        }
        return bignumber(params.data?.return.cash_flow).toNumber();
      },
      allowedAggFuncs: DEFAULT_AGG_FUNCTIONS,
    },
    {
      headerName: 'Sub-funds',
      colId: 'sub-funds',
      marryChildren: true,
      children: subFundDimensions.map((subFundDimension) =>
        subFundGroupColumn<RowData>(subFundDimension, subAccountAndDimensionToSubFund)
      ),
    },
  ];

  return (
    <GAgGridPresets<RowData>
      minHeight={'800px'}
      presetSettingsKey={UserSettings.DailyPnlPresets}
      aggFuncs={customAgg}
      defaultPresets={dailyPnlDefaultPresets(returnsColId)}
      rowData={rows}
      sideBar={{
        toolPanels: ['columns', 'filters'],
      }}
      enableCharts
      cellSelection
      defaultColDef={defaultColDef}
      autoSizeStrategy={{ type: 'fitCellContents' }}
      columnDefs={columns}
    />
  );
};

export default DailyPnlGrid;
