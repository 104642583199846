import { isNil } from 'lodash/fp';
import { type BigNumber, bignumber } from 'mathjs';
import type { ColDef } from 'ag-grid-community';
import type { AvailableAsset, Position } from '../asset/AssetOptimizerWizard.tsx';

// biome-ignore lint/suspicious/noExplicitAny:
export const defaultCol: ColDef<any> = {
  resizable: true,
  sortable: true,
  floatingFilter: true,
  filter: 'agMultiColumnFilter',
};

/**
 * aggregate positions by asset take balance from spots and notional from derivatives
 * this might change when we add "real" support for derivatives
 */
export const aggregatePositionsForInitialPortfolio = (
  positions: Position[],
  availableAssets: AvailableAsset[]
): Map<string, BigNumber> => {
  const availableAssetIds = new Set(availableAssets.map((asset) => asset.id));
  const groupedAssetValues = new Map<string, BigNumber>();

  for (const pos of positions) {
    let assetId: string | undefined;
    let value: BigNumber | undefined;

    if (pos.spot) {
      assetId = pos.asset.id;
      value = bignumber(pos.spot.balance);
    } else if (pos.derivative && pos.asset.derivativeDetails?.baseAsset) {
      assetId = pos.asset.derivativeDetails.baseAsset.id;
      value = bignumber(pos.derivative.notional ?? '0');
    }

    if (assetId && availableAssetIds.has(assetId) && !isNil(value)) {
      const currentValue = groupedAssetValues.get(assetId) ?? bignumber(0);
      groupedAssetValues.set(assetId, currentValue.add(value));
    }
  }

  return groupedAssetValues;
};
