import { Grid, Stack } from '@mui/joy';
import dayjs from 'dayjs';
import { groupBy, isNil } from 'lodash/fp';
import { bignumber } from 'mathjs';
import { type FunctionComponent, useState } from 'react';
import { UTC } from 'components/date.utils.ts';
import { DateTimeFormat, formatDate } from 'components/formatter.utils';
import HeaderBar from 'components/technical/HeaderBar/HeaderBar';
import { Select } from 'components/technical/inputs';
import type { SelectOption } from 'components/technical/inputs/Select/Select.props';
import Message from 'components/technical/Message';
import type { IOptionsQuery } from 'generated/graphql';

import GreekChart from './GreekChart';
import { greeks } from './Greeks.ts';
import OpenInterestExpiryChart from './OpenInterestExpiryChart';
import OpenInterestStrikePriceChart from './OpenInterestStrikePriceChart';
import VolatilityStrikeChart from './VolatilityStrikeChart';
import { venues } from '../../../../../venue/VenueData.tsx';

const createDateOptions = (availableDates: string[]): SelectOption<string>[] => {
  const options = availableDates.map((date, i) => {
    return {
      label: date,
      value: date,
      key: date + i,
    };
  });
  return options;
};

const OptionsList: FunctionComponent<{
  venue: string;
  pricedOptions: IOptionsQuery['assets']['options']['pricedOptions'];
}> = ({ venue, pricedOptions }) => {
  const expirationToData = groupBy(
    (row) =>
      row.option.derivativeDetails?.expirationDate &&
      // expirationDate is actually a date without time, type should be changed on api side
      formatDate(row.option.derivativeDetails?.expirationDate, DateTimeFormat.ShortDate, 'UTC'),
    pricedOptions?.filter((row) => row.option.derivativeDetails?.expirationDate)
  );

  const availableDates = Object.keys(expirationToData).sort((a, b) => (dayjs(a).isAfter(dayjs(b)) ? 1 : -1));

  const [selectedDate, setSelectedDate] = useState<string>(availableDates[0]);

  if (pricedOptions.length === 0) {
    return <Message>No data</Message>;
  }

  // We assume that quote/settlement asset is always the same for specific exchange and that's why we can use it for option symbol

  const derivativeDetails = pricedOptions.at(0)!.option.derivativeDetails;

  const optionSymbol = `${derivativeDetails?.baseAsset.symbol}/${derivativeDetails?.quoteAsset.symbol}:${derivativeDetails?.settlementAsset.symbol}`;

  const dateTimeForFile = formatDate(dayjs.utc(), DateTimeFormat.FileDateTime, UTC);

  return (
    <Stack gap={1.5}>
      <HeaderBar title="Volatility surfaces" />
      <Grid container spacing={1.5}>
        {availableDates.map((date) => {
          const filename = `${optionSymbol}-${venues[venue].name}-${date}-volatility-surface_${dateTimeForFile}`;

          return <VolatilityStrikeChart key={date} date={date} data={expirationToData[date]} filename={filename} />;
        })}
      </Grid>
      <HeaderBar title="Greeks" />
      {selectedDate && (
        <Select
          value={selectedDate}
          options={createDateOptions(availableDates)}
          onChange={(val): void => setSelectedDate(val)}
          width="normal"
        />
      )}
      <Grid container spacing={1.5}>
        {greeks.map((greek) => {
          const filename = `${optionSymbol}-${venues[venue].name}-${selectedDate}-${greek}_${dateTimeForFile}`;

          return <GreekChart key={greek} greek={greek} data={expirationToData[selectedDate]} filename={filename} />;
        })}
      </Grid>
      <HeaderBar title="Open interest by expiration date" />
      <OpenInterestExpiryChart
        data={expirationToData}
        filename={`${optionSymbol}-${venues[venue].name}-open-interest-by-expiration_${dateTimeForFile}`}
        sortedAvailableDates={availableDates}
      />
      <HeaderBar title="Open interest by strike price" />
      <OpenInterestStrikePriceChart
        data={groupBy(
          (row) =>
            row.option.derivativeDetails?.strikePrice && bignumber(row.option.derivativeDetails.strikePrice).toNumber(),
          pricedOptions.filter((row) => !isNil(row.option.derivativeDetails?.strikePrice))
        )}
        filename={`${optionSymbol}-${venues[venue].name}-open-interest-by-strike-price_${dateTimeForFile}`}
      />
    </Stack>
  );
};

export default OptionsList;
