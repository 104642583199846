import { CookieOutlined } from '@mui/icons-material';
import { Grid, Stack, Typography } from '@mui/joy';
import isNil from 'lodash/fp/isNil';
import type { ReactElement } from 'react';
import { useState } from 'react';

import GCard from 'components/technical/GCard/GCard';
import { accountSettingsGroups } from './AccountSecuritySettings';
import { useAuth } from '../../../UseAuth.tsx';
import { SubHeader } from 'components/technical/typography/SubHeader.tsx';
import UpdatePasswordDialog from './UpdatePasswordDialog.tsx';
import { Title } from 'components/technical/StackTitle';
import EnableMFADialog from './EnableMfaDialog/EnableMfaDialog';
import { DisableMfaDialog } from './DisableMfaDialog.tsx';
import { CONTENT_MIN_HEIGHT } from '../constant';
import { GRID_FULL_WIDTH, SELECTABLE_CARD_STYLE } from 'components/technical/GCard/consts';

export const userSettingsGroups = (): Array<{ key: string; title: ReactElement; items: ReactElement[] }> => [
  {
    key: 'privacy',
    title: <SubHeader title="Privacy" withBorder />,
    items: [
      <GCard
        key="cookie-preferences"
        gridWidth={GRID_FULL_WIDTH}
        height="100%"
        cardSx={SELECTABLE_CARD_STYLE}
        title={<Title className="title" icon={<CookieOutlined fontSize="large" />} title="Cookie preferences" />}
        onClick={() => {
          window.displayPreferenceModal?.();
        }}
        content={
          <Typography level="body-sm" minHeight={CONTENT_MIN_HEIGHT}>
            We use different types of cookies on our website. Click to update your preferences at any time.
          </Typography>
        }
      />,
    ],
  },
];

const UserSettings = (): ReactElement => {
  const { localUser, mfa } = useAuth();

  const isMFA = !!(mfa !== 'NO_MFA');

  const [openDialog, setOpenDialog] = useState(false);
  const [openMfa, setOpenMfa] = useState(false);

  const openMfaDialog = () => {
    setOpenMfa(true);
  };

  const closeMfaDialog = () => {
    setOpenMfa(false);
  };

  const openUpdatePasswordDialog = () => {
    setOpenDialog(true);
  };

  const closeUpdatePasswordDialog = () => {
    setOpenDialog(false);
  };

  const settingsGroups = [
    ...(!isNil(localUser) && localUser ? accountSettingsGroups(isMFA, openUpdatePasswordDialog, openMfaDialog) : []),
    ...userSettingsGroups(),
  ];

  return (
    <Stack direction="column" spacing={10} p={1}>
      <Stack direction="column" spacing={2} sx={{ height: '100%' }}>
        {settingsGroups.map((group) => (
          <Stack key={group.key} direction="column" spacing={0} sx={{ height: '100%' }}>
            {group.title}
            <Grid container xs={12} spacing={2} sx={{ height: '100%' }}>
              {group.items.map((item, index) => (
                <Grid key={index} xs={12} sm={6} md={4}>
                  {item}
                </Grid>
              ))}
            </Grid>
          </Stack>
        ))}
      </Stack>

      {/* Render dialogs */}
      {openMfa && isMFA && <EnableMFADialog onClose={closeMfaDialog} />}
      {openMfa && !isMFA && <DisableMfaDialog onClose={closeMfaDialog} />}
      {openDialog && <UpdatePasswordDialog onClose={closeUpdatePasswordDialog} />}
    </Stack>
  );
};

export default UserSettings;
