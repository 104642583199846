import { Box, Stack } from '@mui/joy';
import type {
  ColDef,
  GetRowIdFunc,
  GetRowIdParams,
  GridApi,
  ICellRendererParams,
  ValueGetterParams,
} from 'ag-grid-community';
import isNil from 'lodash/fp/isNil';
import { type ReactElement, type ReactNode, useEffect, useMemo, useRef } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import ErrorMessage from 'components/technical/ErrorMessage.tsx';
import FormInput from 'components/technical/form/FormInput.tsx';
import FormSelect from 'components/technical/form/FormSelect.tsx';

import { Pencil } from 'components/technical/icons';
import GButton from 'components/technical/inputs/GButton/GButton.tsx';
import {
  type ItemOutlookInput,
  type ItemOutlookSource,
  RiskDistributionOption,
  shouldShowLeverage,
  shouldShowReturnsForecast,
  shouldShowRiskBudgetAllocation,
} from './AssumptionsAndOutlook.validation.tsx';
import GAgGrid from '../../../../technical/grids/GAgGrid.tsx';
import { IReturnMeasureNameUi } from '../../../../../generated/graphql.tsx';
import { defaultRowSpacing } from '../../../../StackSpacing.ts';
import { gridWithInputStyles } from 'components/technical/grids/gridStyles.ts';
import type { PortfolioOptimizerInputFields } from '../portfolio/PortfolioOptimizer.validation.ts';
import type { AssetOptimizerInputFields } from '../asset/AssetOptimizer.validation.ts';
import type { SelectOption } from '../../../../technical/inputs/Select/Select.props.ts';
import FormSwitch from '../../../../technical/form/FormSwitch.tsx';
import { range } from 'lodash/fp';

const defaultCol: ColDef<ItemOutlookInput> = {
  resizable: true,
  sortable: false,
};

interface AgGridContext {
  returnsDefault: Record<string, string>;
  riskBudgetDefault: Record<string, string>;
  isSubmitting: boolean;
}

type FieldSettings = {
  defaultField?: 'returnsDefault' | 'riskBudgetDefault';
  step?: number;
  unit?: string;
};

const settings: Record<Exclude<keyof ItemOutlookInput, 'id' | 'sources'>, FieldSettings> = {
  returns: {
    defaultField: 'returnsDefault',
    unit: '%',
  },
  yield: {
    unit: '%',
  },
  leverage: {},
  riskWeight: {
    defaultField: 'riskBudgetDefault',
    unit: '%',
  },
};

const FieldRenderer = (props: ICellRendererParams<ItemOutlookInput, ReactNode, AgGridContext>): ReactElement | null => {
  const data = props.data;
  if (!data) {
    return null;
  }

  const colDef = props.colDef;
  if (!colDef) {
    return null;
  }

  const id = props.node.id ?? '';
  const numericId = Number.parseInt(id);
  if (Number.isNaN(numericId)) {
    return null;
  }

  const ctx = props.context;

  const field = colDef.field ?? '';
  if (!(field in settings)) {
    console.warn('Unsupported field', colDef);
    return null;
  }

  const castField = field as keyof typeof settings;
  const contextFieldName = settings[castField].defaultField ?? undefined;
  const defaultValue = contextFieldName ? ctx[contextFieldName][data.id] ?? '' : '';

  return (
    <ValueOverriddenCellRenderer
      fieldName={castField}
      rowId={numericId}
      isSubmitting={ctx.isSubmitting}
      defaultValue={defaultValue}
    />
  );
};

const ValueOverriddenCellRenderer = ({
  isSubmitting,
  rowId,
  fieldName,
  defaultValue,
}: {
  isSubmitting: boolean;
  rowId: number;
  fieldName: Exclude<keyof ItemOutlookInput, 'id' | 'sources'>;
  defaultValue: string;
}): ReactElement => {
  const path = `outlook.${rowId}.${fieldName}` as const;
  const value = useWatch<AssetOptimizerInputFields | PortfolioOptimizerInputFields>({
    name: path,
    exact: true,
  });

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <FormInput<AssetOptimizerInputFields | PortfolioOptimizerInputFields>
        type="number"
        name={path}
        placeholder={defaultValue}
        width="fullWidth"
        disabled={isSubmitting}
        endDecorator={
          !isNil(defaultValue) && value !== defaultValue && value !== '' && !isNil(value) ? <Pencil /> : null
        }
        startDecorator={settings[fieldName].unit}
        slotProps={{
          input: {
            step: 1,
          },
        }}
      />
    </Stack>
  );
};

const AssumptionsAndOutlookStep = ({
  returnsForecast,
  riskBudgetForecast,
  columns,
  sourceLabels,
  goToNextStep,
  returnMeasureValues,
  riskDistributionValues,
  multifactorValues,
  showYield,
}: {
  returnsForecast: Record<string, string>;
  riskBudgetForecast: Record<string, string>;
  columns: ColDef<ItemOutlookInput>[];
  sourceLabels: Record<ItemOutlookSource, string>;
  returnMeasureValues: SelectOption<IReturnMeasureNameUi>[];
  riskDistributionValues: SelectOption<RiskDistributionOption>[];
  multifactorValues: SelectOption<{
    id: number;
    maxFactors: number;
  }>[];
  goToNextStep: () => void;
  showYield?: boolean;
}): ReactElement => {
  const { formState, getValues, getFieldState, trigger } = useFormContext<
    AssetOptimizerInputFields | PortfolioOptimizerInputFields
  >();

  const { isSubmitting } = formState;
  const objectives = getValues('objectives');
  const outlook = getValues('outlook');
  const allowShortAndLeverage = getValues('allowShortAndLeverage');
  const cnstraintType = getValues('constraintType');
  const constraints = getValues('constraints');
  const returnsForecastSelector = useWatch<AssetOptimizerInputFields, 'returnsForecast'>({
    name: 'returnsForecast',
  });

  const riskBudgetAllocationSelector = useWatch<AssetOptimizerInputFields, 'riskBudgetAllocation'>({
    name: 'riskBudgetAllocation',
  });

  const showForecast = shouldShowReturnsForecast(objectives);
  const showRiskBudgetAllocation = shouldShowRiskBudgetAllocation(objectives);
  const showLeverage = shouldShowLeverage(allowShortAndLeverage, cnstraintType, constraints);

  const showReturnsForecastPlaceholder =
    showForecast && returnsForecastSelector === IReturnMeasureNameUi.ForecastedReturns;

  const showRiskPlaceholder =
    showRiskBudgetAllocation &&
    [RiskDistributionOption.Forecast, RiskDistributionOption.MultifactorScore].includes(
      riskBudgetAllocationSelector as RiskDistributionOption
    );
  const itemIdToIndex = Object.fromEntries(outlook.map((out, index) => [out.id, index]));

  const gridContext = useRef<AgGridContext>({
    isSubmitting,
    returnsDefault: showReturnsForecastPlaceholder ? returnsForecast : {},
    riskBudgetDefault: showRiskBudgetAllocation ? riskBudgetForecast : {},
  });

  const gridApi = useRef<null | GridApi>(null);

  const { error: outlookError } = getFieldState('outlook');
  const allColumns: ColDef<ItemOutlookInput>[] = useMemo(() => {
    const cols: ColDef<ItemOutlookInput>[] = [
      ...columns,
      {
        colId: 'sources',
        headerName: 'Source',
        valueGetter: (params: ValueGetterParams<ItemOutlookInput, unknown>): string | undefined => {
          if (!params.data) {
            return undefined;
          }

          return params.data.sources.map((s) => sourceLabels[s]).join(', ');
        },
      },
    ];

    if (showForecast) {
      cols.push({
        field: 'returns',
        headerName: 'Expected returns',
        cellRenderer: FieldRenderer,
        cellStyle: gridWithInputStyles.inputCellStyle,
      });
    }

    if (showLeverage) {
      cols.push({
        field: 'leverage',
        headerName: 'Max leverage',
        valueGetter: (params: ValueGetterParams<ItemOutlookInput, string>): string | undefined | null => {
          return params.data?.leverage;
        },
        cellRenderer: FieldRenderer,
        cellStyle: gridWithInputStyles.inputCellStyle,
      });
    }

    if (showYield) {
      cols.push({
        field: 'yield',
        headerName: 'Yield from staking',
        cellRenderer: FieldRenderer,
        cellStyle: gridWithInputStyles.inputCellStyle,
      });
    }

    if (showRiskBudgetAllocation) {
      cols.push({
        field: 'riskWeight',
        headerName: 'Risk weight',
        cellRenderer: FieldRenderer,
        cellStyle: gridWithInputStyles.inputCellStyle,
      });
    }

    return cols;
  }, [showRiskBudgetAllocation, showForecast, showLeverage, columns, sourceLabels, showYield]);

  const getRowId = useMemo<GetRowIdFunc>(() => {
    return (params: GetRowIdParams<ItemOutlookInput>): string => itemIdToIndex[params.data.id]?.toString();
  }, [itemIdToIndex]);

  useEffect(() => {
    const ref = gridApi.current;
    if (!ref) {
      return;
    }

    // context properties need to be updated one by one, otherwise, aggrid renders cells with an old context and the next rendering uses correct values
    gridContext.current.isSubmitting = isSubmitting;
    gridContext.current.riskBudgetDefault = showRiskPlaceholder ? riskBudgetForecast : {};
    gridContext.current.returnsDefault = showReturnsForecastPlaceholder ? returnsForecast : {};

    // aggrid doesn't refresh cells when context changes, so we need to do it manually
    ref.setGridOption('context', gridContext.current);
    // refreshing cells does change detection which ignores context, so we need to force rerendering
    ref.refreshCells({
      force: true,
    });
  }, [showReturnsForecastPlaceholder, showRiskPlaceholder, isSubmitting, returnsForecast, riskBudgetForecast]);

  return (
    <Stack spacing={defaultRowSpacing}>
      {showForecast && (
        <FormSelect<AssetOptimizerInputFields | PortfolioOptimizerInputFields>
          label="Forecasts"
          showLabelAboveInput
          name="returnsForecast"
          width="normal"
          options={returnMeasureValues}
          onChange={() => {
            const paths = range(0, outlook.length).map((i) => `outlook.${i}.returns` as const);
            trigger(paths);
          }}
        />
      )}
      {showRiskBudgetAllocation && (
        <>
          <FormSelect<AssetOptimizerInputFields | PortfolioOptimizerInputFields>
            label="Risk budget allocation"
            showLabelAboveInput
            name="riskBudgetAllocation"
            width="xl2"
            options={riskDistributionValues}
          />
          {riskBudgetAllocationSelector === RiskDistributionOption.MultifactorScore && (
            <Stack direction={'row'} flexWrap={'wrap'} gap={1}>
              <FormSelect<AssetOptimizerInputFields, 'multifactor.factor'>
                label="Multifactor"
                showLabelAboveInput
                name="multifactor.factor"
                width="normal"
                options={multifactorValues}
                isValueEqual={(a, b) => a?.id === b?.id}
                onChange={() => {
                  trigger('multifactor.minNumberOfFactors');
                }}
              />
              <FormInput<AssetOptimizerInputFields>
                label="Min number of factors"
                showLabelAboveInput
                name="multifactor.minNumberOfFactors"
                width="normal"
                type={'number'}
              />
              <FormSwitch<AssetOptimizerInputFields>
                name="multifactor.useAbsoluteScores"
                label="Absolute scores"
                matchInputWithLabelHeight
              />
            </Stack>
          )}
        </>
      )}

      <Box height="400px">
        <GAgGrid<ItemOutlookInput>
          rowData={getValues('outlook')}
          columnDefs={allColumns}
          defaultColDef={defaultCol}
          getRowId={getRowId}
          context={gridContext.current}
          autoSizeStrategy={{ type: 'fitGridWidth' }}
          onGridPreDestroyed={() => {
            gridApi.current = null;
          }}
          onGridReady={(e): void => {
            gridApi.current = e.api;
          }}
          rowHeight={gridWithInputStyles.rowHeight}
        />
      </Box>
      {outlookError && <ErrorMessage>{outlookError.message}</ErrorMessage>}
      <GButton onClick={goToNextStep} sx={{ marginLeft: 'auto' }}>
        Next
      </GButton>
    </Stack>
  );
};

export default AssumptionsAndOutlookStep;
