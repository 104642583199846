import type { ReactElement } from 'react';
import VenueLabel from './VenueLabel.tsx';
import { AccountType, calculateAccountType } from '../portfolio/account/AccountService.tsx';
import { IconVariant } from '../market/asset/cryptocurrencies/CryptocurrenciesData.tsx';
import { MaxItemsAvatarGroup } from '../MaxItemsAvatarGroup.tsx';
import { uniqBy } from 'lodash/fp';
import { Stack } from '@mui/joy';

const getNameVenuePairs = (nameVenuePairs: string[][]): string[][] => {
  const uniqElements = uniqBy((pair) => pair.join('-'), nameVenuePairs);
  return uniqElements.sort();
};

export const VenueIconList = ({
  accounts,
  maxItems = 4,
  size = IconVariant.MEDIUM,
}: {
  accounts: { name: string; venue: { label: string } }[];
  maxItems?: number;
  size?: IconVariant;
}): ReactElement => {
  const nameVenuePairs = accounts.map((acc) => {
    const venueLabel = acc.venue.label;
    if (calculateAccountType(acc) === AccountType.Virtual) {
      return [acc.name, venueLabel];
    }

    return ['', acc.venue.label];
  });

  const items = getNameVenuePairs(nameVenuePairs).map((ext) => (
    <VenueLabel accountName={ext[0]} venue={ext[1]} key={ext.join('-')} format="short" size={size} />
  ));

  return (
    <Stack direction="row" alignItems="center" gap={1}>
      <MaxItemsAvatarGroup maxItems={maxItems} size={size} itemSize={nameVenuePairs.length}>
        {items}
      </MaxItemsAvatarGroup>
    </Stack>
  );
};
