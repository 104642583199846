import type { ForwardedRef, ReactElement } from 'react';
import GInput from '../../technical/inputs/GInput/GInput.tsx';

const WarmUpInputWithRef = ({
  disabled,
  value,
  onChange,
  ref,
}: {
  disabled: boolean;
  value: string | undefined;
  onChange: (value: string) => void;
  ref?: ForwardedRef<HTMLInputElement>;
}): ReactElement => {
  return (
    <GInput
      ref={ref}
      width="normal"
      type="number"
      label="Warm-up period"
      disabled={disabled}
      error={disabled ? '' : Number.isSafeInteger(Number.parseFloat(value ?? '')) ? '' : 'Invalid value'}
      showLabelAboveInput
      value={value}
      onChange={(val) => onChange(val ?? '')}
    />
  );
};

export const WarmUpInput = WarmUpInputWithRef;
