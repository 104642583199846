import Card from '@mui/joy/Card';
import range from 'lodash/fp/range';
import { type ReactElement, useState } from 'react';
import StaticMultiAutocomplete from 'components/technical/inputs/Autocomplete/StaticMultiAutocomplete';
import HeaderBar from '../HeaderBar/HeaderBar';
import { Select } from '../inputs';
import SectionColumn from '../layout/Column/SectionColumn';
import SectionPanel from '../layout/SectionPanel';
import { Box } from '@mui/system';

const optionsRange = range(1, 100);
const createValue = (val: number): string => `value ${val}`;
const options = optionsRange.map((value) => ({
  value: createValue(value),
  label: createValue(value),
  searchText: createValue(value),
  key: createValue(value),
}));

const StateContainer = ({
  children,
  defaultOptions,
}: {
  children: (state: string[], setState: (options: string[]) => void) => ReactElement;
  defaultOptions: string[];
}): ReactElement => {
  const [state, setState] = useState(defaultOptions);
  return children(state, (options: string[]): void => {
    setState(options);
  });
};

const showSelects = (values: number[]): ReactElement => {
  const selectedOptions = options
    .filter((opt) => values.some((val) => createValue(val) === opt.value))
    .map((opt) => opt.value);

  return (
    <>
      <StateContainer defaultOptions={selectedOptions}>
        {(state, setState): ReactElement => (
          <Select
            options={options}
            value={state[0]}
            label="label"
            onChange={(value): void => setState([value])}
            width="xl2"
          />
        )}
      </StateContainer>
      <StateContainer defaultOptions={selectedOptions}>
        {(state, setState): ReactElement => (
          <StaticMultiAutocomplete
            options={options}
            value={state}
            label="label"
            onChange={(values): void => setState(values)}
            width="xl2"
            optionHeight={34}
            limitTags={1}
          />
        )}
      </StateContainer>
    </>
  );
};

export const SelectPlayground = (): ReactElement => (
  <SectionColumn>
    <HeaderBar title="Selects" />
    <Card
      sx={{
        alignItems: 'center',
        display: 'grid',
        gridTemplateColumns: '200px repeat(2, 1fr)',
        gap: '1rem',
      }}
    >
      <span>State</span>
      <span>Select</span>
      <span>Autocomplete</span>

      <span>Empty</span>
      {showSelects([])}

      <span>One value</span>
      {showSelects([1])}

      <span>Two values</span>
      {showSelects([1, 2])}

      <span>Multiple values</span>
      {showSelects(range(3, 8))}

      <span>All values</span>
      {showSelects(optionsRange)}
    </Card>
    <SectionPanel title="Color variants">
      <Box
        sx={{
          alignItems: 'center',
          display: 'grid',
          gridTemplateColumns: '200px 1fr',
          gap: '1rem',
        }}
      >
        <span>Normal</span>
        <Select label="label" value="value 1" name="test" options={options} error="error" width="xl2" />
      </Box>
    </SectionPanel>
  </SectionColumn>
);
