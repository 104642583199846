import type { ReactElement } from 'react';
import { Box, Stack, Typography, useTheme } from '@mui/joy';
import type { GridWidth } from 'components/technical/GCard/GCard.tsx';
import {
  type IPrivateAssetsQuery,
  PrivateAssetsDocument,
  SearchableAssetIdsDocument,
  SearchableAssetsDocument,
  useDeletePrivateAssetMutation,
} from 'generated/graphql';
import { AssetIcon } from '../../AssetLabel.tsx';
import { IconVariant } from '../../cryptocurrencies/CryptocurrenciesData.tsx';
import UpdatePrivateAssetDialog from './UpdatePrivateAssetDialog.tsx';
import EditDialogButton from '../../../../technical/inputs/GButton/EditDialogButton.tsx';
import SeeMoreDropdown from 'components/technical/SeeMoreDropDown/SeeMoreDropdown.tsx';
import { BUTTON_MENU_POSITION_STYLE, SELECTABLE_CARD_STYLE } from 'components/technical/GCard/consts.ts';
import { DeleteMenuItem } from '../../../../technical/inputs/GButton/DeleteDialogButton.tsx';
import GCard from 'components/technical/GCard/GCard.tsx';
import { useNavigate } from 'react-router-dom';

const messageStyle = {
  '&:hover .navigate-to-asset-details': {
    opacity: 1,
  },
};

const PrivateAssetCard = ({
  asset,
  gridWidth,
  height,
}: {
  asset: IPrivateAssetsQuery['assets']['list'][number];
  gridWidth: GridWidth;
  height: string;
}): ReactElement => {
  const navigate = useNavigate();
  const [deletePrivateAsset] = useDeletePrivateAssetMutation({
    ignoreResults: true,
  });

  const theme = useTheme();

  return (
    <GCard
      height={height}
      gridWidth={gridWidth}
      cardSx={{
        ...SELECTABLE_CARD_STYLE(theme),
        ...messageStyle,
      }}
      onClick={() => navigate(`/app/market/assets/${asset.id}`)}
    >
      <Box sx={BUTTON_MENU_POSITION_STYLE}>
        <SeeMoreDropdown>
          <EditDialogButton
            renderDialog={({ onClose }): ReactElement => (
              <UpdatePrivateAssetDialog onClose={onClose} onUpdate={onClose} asset={asset} />
            )}
          />
          <DeleteMenuItem
            deletedMessage={'Asset successfully deleted'}
            confirmationMessage={
              <>
                Are you sure you want to remove asset {asset.name}? This action will remove all trades and balances that
                use it.
              </>
            }
            deleteItem={(): Promise<unknown> =>
              deletePrivateAsset({
                variables: {
                  id: asset.id,
                },
                refetchQueries: [SearchableAssetsDocument, SearchableAssetIdsDocument, PrivateAssetsDocument],
              })
            }
          />
        </SeeMoreDropdown>
      </Box>
      <Stack justifyContent="flex-start" direction="row" alignItems={'center'} columnGap={1}>
        <AssetIcon asset={asset} size={IconVariant.XXXL} />
        <Stack direction="column" alignItems={'start'} justifyContent={'start'}>
          <Typography level="title-lg" className="title">
            {asset.name ?? asset.symbol} <Typography color="neutral">({asset.symbol})</Typography>
          </Typography>
          <Typography
            level="body-xs"
            sx={{
              opacity: 0,
              transition: 'all 0.3s ease-in-out',
              fontStyle: 'italic',
            }}
            className="navigate-to-asset-details"
          >
            Navigate to the asset details
          </Typography>
        </Stack>
      </Stack>
      {/* <Divider /> */}
    </GCard>
  );
};

export default PrivateAssetCard;
