import dayjs, { type Dayjs } from 'dayjs';
import { isValidNumber } from '../../../../../number.utils.ts';

export const sampleFile = `
date,price
2024-01-01,96608.77
2024-01-03,96608.77
2024-01-04,96608.87`.trim();

export const parseFile = (
  file: string
): {
  lineErrors: { line: number; error: string }[];
  generalErrors: string[];
  data: { date: Dayjs; price: string }[];
} => {
  const trimmed = file.trim();
  const lines = trimmed.split(/\r\n|\r|\n/);
  if (lines.length === 0) {
    return {
      generalErrors: ['File is empty'],
      lineErrors: [],
      data: [],
    };
  }

  const generalErrors = [];
  if (lines[0] !== 'date,price'.trim()) {
    generalErrors.push('File contains invalid header');
  }

  const data: {
    date: Dayjs;
    price: string;
  }[] = [];
  const allLineErrors: { line: number; error: string }[] = [];
  for (let i = 1; i < lines.length; i++) {
    const line = lines[i];
    const parts = line.split(',');
    const lineErrors: string[] = [];
    if (parts.length !== 2) {
      lineErrors.push(`Invalid number of columns. Received ${parts.length}, expected 2`);
    } else {
      const dateText = parts[0].trim();
      if (dateText === '') {
        lineErrors.push('Empty date');
      } else {
        const date = dateText.match(/^\d{4}-\d{2}-\d{2}$/);
        if (!date || !dayjs.utc(dateText, 'YYYY-MM-DD', true).isValid()) {
          lineErrors.push('Date has invalid format');
        }
      }

      const priceText = parts[1].trim();
      if (priceText === '') {
        lineErrors.push('Empty price');
      } else {
        if (!isValidNumber(priceText)) {
          lineErrors.push('Price is not a valid number');
        }

        if (Number.parseFloat(priceText) < 0) {
          lineErrors.push('Price is negative');
        }
      }
    }

    if (lineErrors.length > 0) {
      allLineErrors.push({ line: i + 1, error: lineErrors.join('. ') });
    }

    if (lineErrors.length === 0) {
      data.push({
        date: dayjs.utc(parts[0]),
        price: parts[1],
      });
    }
  }

  return {
    generalErrors,
    lineErrors: allLineErrors,
    data,
  };
};
