import { Add } from '@mui/icons-material';
import { Link, Modal, ModalDialog, Stack, Tooltip } from '@mui/joy';
import { type ReactElement, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { useFeedback } from 'components/technical/Feedback/UseFeedback.tsx';
import type { FormInputType } from 'components/technical/form/Form.types';
import FormStaticSingleAutocomplete from 'components/technical/form/FormStaticSingleAutocomplete';
import GFormProvider from 'components/technical/form/GFormProvider';
import { GraphQLApiFormErrorMessage } from 'components/technical/form/GraphQLApiErrorMessage';
import gYupResolver from 'components/technical/form/gYupResolver';
import SubmitButton from 'components/technical/form/SubmitButton';
import { useGraphQLApiError } from 'components/technical/form/UseGraphQLApiError.tsx';
import GDialogHeader from 'components/technical/GDialog/GDialogHeader.tsx';
import { AssetPriceChartDocument, useCreateUpdateAssetPricesMutation } from '../../../../../../generated/graphql';
import type { Asset } from '../../../Asset.types';
import { createAssetSelectOptions } from '../../../AssetService';
import { defaultRowSpacing } from '../../../../../StackSpacing.ts';
import { downloadLocalCsvFile } from '../../../../../file.utils.ts';
import FormFileUploadInput from '../../../../../technical/form/FormFileUploadInput.tsx';
import { parseFile, sampleFile } from './UploadAssetPricesDialog.util.ts';
import { convertDateInUtcToUTCISODate } from '../../../../../date.utils.ts';
import ErrorMessage from '../../../../../technical/ErrorMessage.tsx';

interface FormOutputFields {
  asset: { id: string };
  file: File;
}

type FormInputFields = FormInputType<FormOutputFields>;

const formSchema = yup.object({
  asset: yup.mixed().required(),
  file: yup.mixed().required(),
});

export const UploadAssetPricesDialog = (props: {
  onClose: () => void;
  onAdded: () => void;
  availableAssets: Asset[];
  quoteAsset: { id: string };
}): ReactElement => {
  const [fileValidationErrors, setFileValidationErrors] = useState<string[]>([]);
  const assetOptions = createAssetSelectOptions(props.availableAssets);

  const methods = useForm<FormInputFields>({
    resolver: gYupResolver(formSchema),
    defaultValues: {
      asset: props.availableAssets.at(0),
      file: null,
    },
  });

  const { onErrorAndThrow } = useGraphQLApiError(methods);
  const { showSuccessMessage } = useFeedback();

  const [createAssetPrices] = useCreateUpdateAssetPricesMutation({
    refetchQueries: [AssetPriceChartDocument],
  });

  const handleFormSubmit = async (input: FormInputFields): Promise<void> => {
    const formOutput = input as unknown as FormOutputFields;
    setFileValidationErrors([]);
    const fileText = (await formOutput.file.text()) ?? '';
    const { generalErrors, lineErrors, data } = parseFile(fileText);
    const errorText = [];
    if (generalErrors.length > 0) {
      errorText.push('General file errors: ' + generalErrors.join(', '));
    }

    for (const lineError of lineErrors) {
      errorText.push(`Line: ${lineError.line}. Error: ${lineError.error}`);
    }

    if (errorText.length > 0) {
      setFileValidationErrors(errorText);
      console.error('File validation errors', errorText);
      throw new Error('Invalid form');
    }

    try {
      await createAssetPrices({
        variables: {
          input: {
            prices: data.map((row) => ({
              id: {
                baseAssetId: formOutput.asset.id,
                quoteAssetId: props.quoteAsset.id,
                date: convertDateInUtcToUTCISODate(row.date),
              },
              unitValue: row.price,
            })),
          },
        },
      });

      showSuccessMessage('New asset prices successfully added');

      props.onAdded();
    } catch (e) {
      onErrorAndThrow(e);
    }
  };

  const inputWidth = 'normal';
  return (
    <Modal open={true} onClose={props.onClose}>
      <ModalDialog>
        <GFormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(handleFormSubmit)}>
            <GDialogHeader>Upload prices</GDialogHeader>
            <Stack alignItems="center" spacing={4}>
              <Stack direction={'row'} spacing={2} alignItems={'flex-end'} flexWrap={'wrap'}>
                <FormStaticSingleAutocomplete<FormInputFields>
                  showLabelAboveInput
                  {...assetOptions}
                  name="asset"
                  label="Asset"
                  width={inputWidth}
                />
                <Tooltip
                  enterDelay={400}
                  placement={'right'}
                  title={
                    <Link
                      level="body-sm"
                      sx={{ color: 'inherit' }}
                      onClick={() => {
                        downloadLocalCsvFile(sampleFile, 'prices.csv');
                      }}
                    >
                      Get sample file
                    </Link>
                  }
                >
                  <div>
                    <FormFileUploadInput
                      name={'file'}
                      width={inputWidth}
                      onChange={() => setFileValidationErrors([])}
                    />
                  </div>
                </Tooltip>
              </Stack>

              <GraphQLApiFormErrorMessage />
              {fileValidationErrors && (
                <ErrorMessage>
                  {fileValidationErrors.map((row, i) => (
                    <div key={i}>{row}</div>
                  ))}
                </ErrorMessage>
              )}
              <Stack direction={'row'} spacing={defaultRowSpacing} justifyContent={'flex-end'}>
                <SubmitButton width="small" startDecorator={<Add />}>
                  Upload prices
                </SubmitButton>
              </Stack>
            </Stack>
          </form>
        </GFormProvider>
      </ModalDialog>
    </Modal>
  );
};
