import { Typography } from '@mui/joy';
import type { CellContext, ColumnDef } from '@tanstack/react-table';
import { isNil } from 'lodash/fp';
import { bignumber } from 'mathjs';
import type { FunctionComponent, ReactElement } from 'react';

import GTable from 'components/technical/GTable/GTable.tsx';
import type { IPortfolioMetricsQuery } from '../../../generated/graphql';
import type { PortfolioRiskMetric } from '../../copilot/PortfolioOptimizerRiskMetrics.tsx';
import { formatterForName } from '../../formatter.utils';
import { getFormat, getNameWithTooltip, metricParamsFromParameters } from '../../metrics/MetricsData';
import type { MetricParams } from '../../metrics/MetricsData.types.ts';
import { PORTFOLIO_BETA_METRIC } from '../../metrics/PortfolioMetricsData.ts';

const getPortfolioRiskMetric = (
  metric: IPortfolioMetricsQuery['portfolio']['metrics'][number],
  betaAssetIds: { btc: string; eth: string }
): PortfolioRiskMetric | undefined => {
  const metricRow = metric.series.at(0);
  const metricValue = metricRow?.values.at(-1)?.value;

  if (!metricValue) {
    return undefined;
  }
  const params: MetricParams = metricParamsFromParameters(metricRow.parameters);

  if (metricRow.parameters.some((param) => param.strValue === betaAssetIds.eth)) {
    return {
      name: PORTFOLIO_BETA_METRIC,
      value: bignumber(metricValue).toNumber(),
      params: { ...params, assetSymbol: 'ETH' },
    };
  }

  if (metricRow.parameters.some((param) => param.strValue === betaAssetIds.btc)) {
    return {
      name: PORTFOLIO_BETA_METRIC,
      value: bignumber(metricValue).toNumber(),
      params: { ...params, assetSymbol: 'BTC' },
    };
  }
  return {
    name: metric.label,
    value: bignumber(metricValue).toNumber(),
    params,
  };
};

type Metric = IPortfolioMetricsQuery['portfolio']['metrics'][number];

type PortfolioRealizedMetricsProps = {
  metrics: Metric[];
  betaAssetIds: { btc: string; eth: string };
};

const PortfolioRealizedMetrics: FunctionComponent<PortfolioRealizedMetricsProps> = ({ metrics, betaAssetIds }) => {
  const metricsList: PortfolioRiskMetric[] = metrics
    .map((metric) => getPortfolioRiskMetric(metric, betaAssetIds))
    .filter((met): met is PortfolioRiskMetric => !isNil(met));

  const columns: ColumnDef<PortfolioRiskMetric>[] = [
    {
      id: 'metric',
      cell: (props: CellContext<PortfolioRiskMetric, unknown>): ReactElement => {
        const metricName = getNameWithTooltip(props.row.original.name, props.row.original.params);
        return <Typography>{metricName}</Typography>;
      },
    },
    {
      id: 'value',
      cell: (props: CellContext<PortfolioRiskMetric, unknown>): ReactElement => {
        const metricValue = formatterForName(getFormat(props.row.original.name))(props.row.original.value);
        return <Typography>{metricValue}</Typography>;
      },
    },
  ];
  return <GTable columns={columns} data={metricsList} disablePagination hideBorders cellPadding={0.5} />;
};

export default PortfolioRealizedMetrics;
