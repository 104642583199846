import { Box, Stack } from '@mui/joy';
import type { ColumnState } from 'ag-grid-community';
import type { AgGridReact } from 'ag-grid-react';
import GAgGrid, { type GAgGridProps } from 'components/technical/grids/GAgGrid';
import { cloneDeep, pull } from 'lodash/fp';
import { type ReactElement, type Ref, type RefObject, useRef, useState } from 'react';
import { useLocalStorage } from 'react-use';
import { REPLAY_UNMASK_CLASS_NAME } from 'setup/initializeAll.ts';
import type { UserSettings } from 'components/management/UserSettings.types';
import { defaultRowSpacing } from 'components/StackSpacing';
import { useDefaultErrorHandling } from 'components/technical/UseDefaultErrorHandling';
import { useUserSettingsQuery, useUpdateUserSettingsMutation, UserSettingsDocument } from 'generated/graphql';

import type { ReportPreset, ReportPresetSettings, ReportSettings } from './GAgGridPresets.types';
import ReportPresets from './ReportPresets';
import { useFeedback } from '../Feedback/UseFeedback.tsx';
import Loader from '../Loader/Loader';

type GAgGridPresetsProps<RowData> = GAgGridProps<RowData> & {
  presetSettingsKey: UserSettings;
  defaultPresets: ReportPreset[];
};

const defaultColDef = {
  resizable: true,
  sortable: true,
  filter: true,
};

/** wrapper around GAgGrid, show and manages user saved presets */
function GAgGridPresets<RowData>(props: GAgGridPresetsProps<RowData>, parentRef?: Ref<AgGridReact>): ReactElement {
  const { defaultPresets, presetSettingsKey, ...rest } = props;
  const [updateUserSettings, { loading }] = useUpdateUserSettingsMutation();
  const userSettingsQueryResult = useDefaultErrorHandling(
    useUserSettingsQuery({
      variables: {
        field: presetSettingsKey,
      },
    }),
    { disableNoDataFallback: true }
  );
  const [presetApplied, setPresetApplied] = useState(false);
  const localRef = useRef<AgGridReact>(null);
  const { showSuccessMessage } = useFeedback();
  const currentPresetNameKey = `${presetSettingsKey}-currentPresetName`;
  // use localStorage to save current preset and load on initial render
  // we intentionally don't want to listen to other tabs changes, it would create issues with impersonation (2 tabs with different users)

  const [currentPresetName, setCurrentPresetName] = useLocalStorage(currentPresetNameKey, defaultPresets[0].name);

  const ref = (parentRef as RefObject<AgGridReact<RowData>>) ?? localRef;

  if (parentRef && typeof parentRef !== 'object') {
    throw new Error('GAgGridPresets component expects a RefObject as a ref.');
  }
  if (defaultPresets.length === 0) {
    throw new Error('GAgGridPresets component expects at least one default preset.');
  }

  if (!userSettingsQueryResult.loaded) {
    return <userSettingsQueryResult.Fallback />;
  }

  const userReportSettings: ReportSettings = userSettingsQueryResult.data.management.userSettings ?? {
    presets: [],
    favorites: defaultPresets.map((preset: ReportPreset) => preset.name), // initially default presets are in favorites
  };

  const allPresets = [...(userReportSettings.presets ?? []), ...defaultPresets];

  const currentPreset = allPresets.find((preset) => preset.name === currentPresetName);

  if (currentPresetName && !currentPreset) {
    // happens on preset creation, firstly on rerender we have new currentPresetName, but it appears in allPresets only after refetch
    // eslint-disable-next-line no-console
    console.info(`currentPreset ${currentPresetName} was not found, fallback to the first default preset`);
  }
  const currentPresetOrDefault = currentPreset ?? defaultPresets[0];

  function getGridRef(): AgGridReact<RowData> {
    if (!ref.current) {
      throw new Error('Grid was not initialized, grid ref is null');
    }
    return ref.current;
  }

  function getCurrentReportState(): ReportPresetSettings {
    const gridRef = getGridRef();

    return {
      filters: gridRef.api.getFilterModel(),
      pivotMode: gridRef.api.isPivotMode(),
      columns: gridRef.api.getColumnState(),
    };
  }

  function applyPresetToReport(preset: ReportPreset): void {
    const agGrid = getGridRef();

    agGrid.api.setFilterModel(preset.settings.filters);
    agGrid.api.setGridOption('pivotMode', preset.settings.pivotMode);
    const state = preset.settings.columns.map((column: ColumnState) => ({
      ...column,
      width: undefined, // avoid setting width to not interfere with auto size strategy
    }));
    const columnStateAppliedSuccessfully = agGrid.api.applyColumnState({
      state,
      // ag-grid doesn't allow applying order with group columns marryChildren prop, and without marryChildren grouped columns don't behave
      applyOrder: false,
    });
    if (!columnStateAppliedSuccessfully) {
      console.warn('Applying report preset error: some saved columns was not found in grid column configuration.');
    }

    setTimeout(() => {
      // auto size doesn't work without timeout
      agGrid.api.autoSizeAllColumns();
      // delay showing grid to avoid column width flicker
      setTimeout(() => setPresetApplied(true), 1);
    }, 1);
  }

  async function mutateAndUpdateSettings(mutate: (settings: ReportSettings) => void): Promise<void> {
    const newReportSettings = cloneDeep<ReportSettings>(userReportSettings);
    mutate(newReportSettings);

    await updateUserSettings({
      variables: {
        settings: {
          [presetSettingsKey]: newReportSettings,
        },
      },
      refetchQueries: [UserSettingsDocument],
    });
  }

  return (
    <Stack height="100%" gap={defaultRowSpacing} className={REPLAY_UNMASK_CLASS_NAME}>
      <ReportPresets
        loading={loading}
        userSettings={userReportSettings}
        defaultPresets={defaultPresets}
        currentPreset={currentPresetOrDefault}
        setCurrentPreset={(preset): void => {
          setCurrentPresetName(preset.name);
          applyPresetToReport(preset);
        }}
        onAddPreset={async ({ name, isFavorite }): Promise<void> => {
          await mutateAndUpdateSettings((newSettings) => {
            newSettings.presets.push({
              name,
              settings: getCurrentReportState(),
              isDefault: false,
            });
            if (isFavorite) {
              newSettings.favorites.push(name);
            }
          });
          setCurrentPresetName(name);
        }}
        onSavePreset={(): Promise<void> =>
          mutateAndUpdateSettings((newSettings) => {
            newSettings.presets.find((preset) => preset.name === currentPresetOrDefault.name)!.settings =
              getCurrentReportState();
          })
        }
        onDeletePreset={async (presetToDelete): Promise<void> => {
          mutateAndUpdateSettings((newSettings) => {
            newSettings.presets = newSettings.presets.filter((preset) => preset.name !== presetToDelete.name);
            newSettings.favorites = pull(presetToDelete.name, newSettings.favorites);
            if (currentPresetName === presetToDelete.name) {
              setCurrentPresetName(newSettings.favorites[0] ?? defaultPresets[0].name);
            }
          });
        }}
        toggleFavorite={async (presetName: string): Promise<void> => {
          mutateAndUpdateSettings((newSettings) => {
            if (userReportSettings.favorites.includes(presetName)) {
              newSettings.favorites = pull(presetName, newSettings.favorites);
            } else {
              newSettings.favorites.push(presetName);
            }
          });
        }}
        onResetPreset={(): void => applyPresetToReport(currentPresetOrDefault)}
        copyCurrentStateAsJson={(): void => {
          const stateToPresetJson = JSON.stringify(
            {
              name: '[PUT UNIQUE PRESET NAME HERE]',
              isDefault: true,
              settings: getCurrentReportState(),
            } satisfies ReportPreset,
            null,
            2
          );
          navigator.clipboard.writeText(stateToPresetJson);
          showSuccessMessage(
            'Current report state was copied to clipboard as JSON, replace name field with unique new preset name and it can be used as a new default preset in code'
          );
        }}
      />
      {!presetApplied && <Loader fullHeight />}
      <Box sx={{ visibility: presetApplied ? 'visible' : 'hidden' }} height="100%">
        <GAgGrid<RowData>
          ref={ref}
          defaultColDef={defaultColDef}
          {...rest}
          onGridReady={(event): void => {
            // we need grid ref with api to apply preset
            // so render invisible grid and loader to avoid "data flickers" then apply preset and show grid
            applyPresetToReport(currentPresetOrDefault);
            props.onGridReady?.(event);
          }}
        />
      </Box>
    </Stack>
  );
}

export default GAgGridPresets;
