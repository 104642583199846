import type { GButtonProps } from '../technical/inputs/GButton/GButton.props.ts';
import { type ReactElement, useState } from 'react';
import {
  useManagementDashboardInputSuspenseQuery,
  useUpdateJournalDimensionMutation,
} from '../../generated/graphql.tsx';
import { useFeedback } from '../technical/Feedback/UseFeedback.tsx';
import { Select } from '../technical/inputs';
import { createDimensionAutocompleteOptionsFromSubFunds } from '../portfolio/fund/SubFundService.tsx';

export const JournalModeChanger = (props: {
  width: Required<GButtonProps>['width'];
}): ReactElement => {
  const input = useManagementDashboardInputSuspenseQuery();
  const portfolio = input.data.portfolio;

  const [activeDimension, setActiveDimension] = useState<string | null>(
    portfolio.journal.preferences.dimension ?? null
  );

  const { showGraphqlError } = useFeedback();
  const [updateJournalPreferences] = useUpdateJournalDimensionMutation();

  return (
    <Select
      {...createDimensionAutocompleteOptionsFromSubFunds(portfolio.subFunds.list)}
      // color is unsupported
      width={props.width}
      menuWidth={'normal'}
      placeholder={!activeDimension ? 'Organization' : ''}
      label={'Dimension'}
      showLabelAboveInput
      showClearable
      value={activeDimension}
      onChange={(value) => {
        const previousDimension = activeDimension;
        setActiveDimension(value ?? null);
        updateJournalPreferences({ variables: { dimension: value } }).catch((e) => {
          setActiveDimension(previousDimension);
          showGraphqlError(e);
        });
      }}
    />
  );
};
