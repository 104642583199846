import { useRef, type ReactElement, type ReactNode } from 'react';

import { REPLAY_UNMASK_CLASS_NAME } from 'setup/initializeAll.ts';
import { Box } from '@mui/joy';
import useResizeObserver from '@react-hook/resize-observer';

export const NAVBAR_ID = 'NAVBAR_ID';

const Navbar = ({ children }: { children: ReactNode }): ReactElement => {
  const ref = useRef(null);
  const paddingY = '0.5rem';

  useResizeObserver(ref, (entry) => {
    // include element padding in height
    // I could use borderBox, but it's borderBoxSize in Safari 15, only in 15.4
    document.body.style.setProperty('--header-height', `calc(${entry.contentRect.height}px + ${paddingY} * 2)`);
  });

  return (
    <Box
      component={'nav'}
      id={NAVBAR_ID}
      className={REPLAY_UNMASK_CLASS_NAME}
      ref={ref}
      sx={(theme) => ({
        background: theme.palette.background.body,
        filter: 'drop-shadow(0 0 4px rgba(0, 0, 0, .1))',
        flexShrink: 0,
        padding: `${paddingY} 1.5rem`,
        position: 'sticky',
        top: '0',
        zIndex: 'var(--navbar-z-index)',
      })}
    >
      {children}
    </Box>
  );
};

export default Navbar;
