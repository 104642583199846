import type { ITradingActivityReportQuery } from '../../../generated/graphql.tsx';
import type { TupleKeyMap } from '../../TupleKeyMap.ts';
import { mapSubAccountAndDimensionToSubFund } from '../report/Report.utils.tsx';
import { dateReadableValueGetter } from '../../technical/grids/agGrid.utils.tsx';
import {
  accountColumn,
  clusterColumn,
  nameColumn,
  subAccountColumn,
  subFundGroupColumn,
  symbolColumn,
  underlyingAssetColumn,
  venueColumn,
} from '../../technical/grids/SharedReportColumns.tsx';
import { formatEnum } from '../../formatter.utils.ts';
import type { ReactElement } from 'react';
import type { ColDef, ColGroupDef, ValueGetterParams } from 'ag-grid-community';
import { bignumber } from 'mathjs';
import GAgGridPresets from '../../technical/grids/GAgGridPresets.tsx';
import { UserSettings } from '../../management/UserSettings.types.ts';
import { defaultPresets } from './tradingActivityDefaultPreset.ts';

type RowData = ITradingActivityReportQuery['bookkeeping']['tradingActivity'][number];

export const TradingActivityReport = ({
  rows,
  subFunds,
  assetClusters,
  assetAndGroupClusterMapToGroup,
}: {
  rows: ITradingActivityReportQuery['bookkeeping']['tradingActivity'];
  subFunds: ITradingActivityReportQuery['portfolio']['subFunds']['list'];
  assetClusters: string[];
  assetAndGroupClusterMapToGroup: TupleKeyMap<[string, string], string>;
}): ReactElement => {
  const { subAccountAndDimensionToSubFund, subFundDimensions } = mapSubAccountAndDimensionToSubFund(subFunds);
  const columns: (ColDef<RowData> | ColGroupDef<RowData>)[] = [
    {
      headerName: 'Date',
      field: 'day',
      type: 'dateColumn',
      initialSort: 'desc',
      valueGetter: (params: ValueGetterParams<RowData>): string | undefined =>
        params.data ? dateReadableValueGetter(params.data.day) : undefined,
    },
    {
      headerName: 'Account Details',
      colId: 'account-details',
      marryChildren: true,
      children: [accountColumn(), subAccountColumn({ initialHide: true }), venueColumn({ initialHide: true })],
    },
    {
      headerName: 'Sub-funds',
      colId: 'sub-funds',
      marryChildren: true,
      children: subFundDimensions.map((subFundDimension) =>
        subFundGroupColumn<RowData>(subFundDimension, subAccountAndDimensionToSubFund)
      ),
    },
    {
      headerName: 'Asset Details',
      colId: 'asset-details',
      marryChildren: true,
      children: [
        nameColumn({ initialHide: false }),
        symbolColumn(),
        underlyingAssetColumn(),
        ...assetClusters.map((cluster) => clusterColumn<RowData>(cluster, assetAndGroupClusterMapToGroup)),
      ],
    },
    {
      headerName: 'Amount',
      type: ['numericColumn', 'extendedNumericColumn'],
      valueGetter: (params): number | undefined => {
        const amount = params.data?.amount;
        if (!amount) {
          return undefined;
        }

        return bignumber(amount).toNumber();
      },
    },
    {
      headerName: 'Price',
      type: ['numericColumn', 'cashColumn'],
      valueGetter: (params): number | undefined => {
        const price = params.data?.price;
        if (!price) {
          return undefined;
        }

        return bignumber(price).toNumber();
      },
    },
    {
      headerName: 'Value',
      type: ['numericColumn', 'cashColumn'],
      valueGetter: (params): number | undefined => {
        const value = params.data?.value;
        if (!value) {
          return undefined;
        }

        return bignumber(value).toNumber();
      },
    },
    {
      headerName: 'Notional',
      type: ['numericColumn', 'cashColumn'],
      valueGetter: (params): number | undefined => {
        const value = params.data?.value;
        if (!value) {
          return undefined;
        }

        return bignumber(value).abs().toNumber();
      },
    },
    {
      colId: 'userType',
      headerName: 'Transaction type',
      type: 'textColumn',
      valueGetter: (params): string | undefined => {
        return formatEnum(params.data?.userType);
      },
    },
    {
      colId: 'legCount',
      headerName: 'Activity count',
      type: ['numericColumn', 'extendedNumericColumn'],
      valueGetter: (params): number | undefined => {
        const amount = params.data?.legCount;
        if (!amount) {
          return undefined;
        }

        return bignumber(amount).toNumber();
      },
    },
    {
      colId: 'state',
      headerName: 'State',
      type: 'textColumn',
      valueGetter: (params): string | undefined => {
        return formatEnum(params.data?.state);
      },
    },
    {
      colId: 'legType',
      headerName: 'Leg Type',
      type: 'textColumn',
      valueGetter: (params): string | undefined => {
        return formatEnum(params.data?.legType);
      },
    },
    {
      colId: 'side',
      headerName: 'Position Side',
      type: 'textColumn',
      valueGetter: (params): string | undefined => {
        return formatEnum(params.data?.side);
      },
    },
    {
      colId: 'attributedToAsset',
      headerName: 'Attributed to asset',
      type: 'textColumn',
      minWidth: 160,
      field: 'attributedToAssetId.symbol',
    },
  ];

  return (
    <GAgGridPresets
      presetSettingsKey={UserSettings.TradingActivityPresets}
      defaultPresets={defaultPresets}
      rowData={rows}
      sideBar={{
        toolPanels: ['columns', 'filters'],
      }}
      enableCharts
      cellSelection
      defaultColDef={{
        resizable: true,
        sortable: true,
        filter: true,
      }}
      autoSizeStrategy={{ type: 'fitCellContents' }}
      columnDefs={columns}
    />
  );
};
