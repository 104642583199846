import { Card, CardContent, Box } from '@mui/joy';
import type { ReactElement, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
interface SectionPanelProps {
  title?: ReactNode;
  sx?: Record<string, unknown>;
  children?: ReactNode;
  link?: string;
  onAction?: () => void;
}

const SectionPanel = ({ title, children, sx, link, onAction }: SectionPanelProps): ReactElement => {
  const navigate = useNavigate();

  const fullSectionClickable = !!link || !!onAction;
  const handleClick = (): void => {
    if (fullSectionClickable) {
      if (link) {
        navigate(link);
      } else if (onAction) {
        onAction();
      }
    }
  };

  return (
    <Card
      variant="outlined"
      sx={{
        height: '100%',
        ...(fullSectionClickable
          ? {
              cursor: 'pointer',
              '&:hover': {
                boxShadow: 'md',
              },
            }
          : {}),
        ...sx,
      }}
      onClick={fullSectionClickable ? handleClick : undefined}
      role={fullSectionClickable ? 'button' : undefined}
      tabIndex={fullSectionClickable ? 0 : undefined}
    >
      {title && <Box p={2}>{title}</Box>}
      <CardContent sx={{ height: '100%' }}>{children}</CardContent>
    </Card>
  );
};

export default SectionPanel;
