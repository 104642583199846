import type { IPortfolioRegimeQuery } from 'generated/graphql.tsx';
import type { BacktestingConfiguration } from '../BacktestConfiguration.types.ts';
import { memo, type ReactElement, Suspense, useState } from 'react';
import { Box, Stack } from '@mui/joy';
import { Select } from '../../../../technical/inputs';
import PortfolioRegimeResult from './PortfolioRegimeResult.tsx';
import Loader from 'components/technical/Loader/Loader.tsx';

const PortfolioRegime = ({
  backtestingConfig,
  regimes,
}: {
  backtestingConfig: BacktestingConfiguration;
  regimes: IPortfolioRegimeQuery['portfolio']['supportedRegimeDefinitions'];
}): ReactElement => {
  const [regime, setRegime] = useState<{ id: string; regimes: string[]; name: string } | null>(regimes[0] ?? null);

  if (regimes.length === 0) {
    return <></>;
  }

  return (
    <Stack>
      {regimes.length !== 1 && (
        <Box ml={'auto'}>
          <Select
            label={'regime'}
            options={regimes.map((regime) => ({
              value: regime,
              key: regime.id,
              label: regime.name,
            }))}
            value={regime}
            onChange={setRegime}
            width={'normal'}
          />
        </Box>
      )}
      <Suspense fallback={<Loader />}>
        {!!regime && <PortfolioRegimeResult backtestingConfig={backtestingConfig} regime={regime} />}
      </Suspense>
    </Stack>
  );
};

export default memo(PortfolioRegime);
