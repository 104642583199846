import { Box, Card, Grid, Stack, Typography } from '@mui/joy';
import type { ColDef, ColGroupDef, ValueGetterParams } from 'ag-grid-community';
import GAgGrid from 'components/technical/grids/GAgGrid.tsx';
import type Highcharts from 'highcharts';
import isNil from 'lodash/fp/isNil';
import { type Dispatch, type ReactElement, type SetStateAction, useMemo, useState } from 'react';
import { formatNumber, formatterForName } from 'components/formatter.utils';
import { getFormat, getName } from 'components/metrics/MetricsData';

import {
  defaultTooltipPointColor,
  getHighchartColor,
  type HighchartSeries,
  tooltipFormat,
} from 'components/technical/charts/HighChartsWrapper/Highchart.utils';
import HighChartsWrapper from 'components/technical/charts/HighChartsWrapper/HighChartsWrapper';
import {
  PORTFOLIO_EXPECTED_DOWNSIDE_RISK_METRIC,
  PORTFOLIO_EXPECTED_HISTORIC_CVAR99,
  PORTFOLIO_EXPECTED_HISTORIC_VAR99,
  PORTFOLIO_EXPECTED_VOLATILITY_METRIC,
} from '../../../metrics/PortfolioRiskMeasures.ts';
import { defaultRowSpacing } from '../../../StackSpacing.ts';
import GSwitch from '../../../technical/inputs/GSwitch.tsx';
import { getObjectiveLabel, type Objective } from '../objective.utils.ts';
import StaticSingleAutocomplete from 'components/technical/inputs/Autocomplete/StaticSingleAutocomplete.tsx';
import {
  type AssetOptimization,
  getSolutionName,
  isGivenPortfolio,
  type PortfolioOptimization,
} from './Results.types.ts';
import { useFinalColorScheme } from '../../../../useFinalColorScheme.ts';
import { createColumnToolDef } from '../../../technical/grids/agGrid.utils.tsx';

const GRID_MIN_HEIGHT_PX = 450;

type SolutionRow = {
  id: number;
  name: string;
  riskMetrics: Record<string, number>;
  objectiveValues: Array<{ value: number }>;
  nonDominated: boolean;
  suggested: boolean;
};

const defaultCol: ColDef<SolutionRow> = {
  resizable: true,
  sortable: true,
  enablePivot: false,
};

const metrics = [
  PORTFOLIO_EXPECTED_VOLATILITY_METRIC,
  PORTFOLIO_EXPECTED_DOWNSIDE_RISK_METRIC,
  PORTFOLIO_EXPECTED_HISTORIC_VAR99,
  PORTFOLIO_EXPECTED_HISTORIC_CVAR99,
];

const sideBar = {
  toolPanels: [
    createColumnToolDef({
      suppressRowGroups: true,
      suppressValues: true,
      suppressPivotMode: true,
    }),
  ],
};

const getFullMetricLabel = ({
  measure,
  benchmark,
}: {
  measure: string;
  benchmark?: { name: string } | { symbol: string } | null;
}): string => {
  if (!benchmark) {
    return measure;
  }

  const prefix = 'name' in benchmark ? benchmark.name : benchmark.symbol;
  return `${measure}_${prefix.toLowerCase()}`;
};

const calculateMaxMetric = (rows: SolutionRow[]): Record<string, number> => {
  const metricToValues: Record<string, number> = {};
  for (const row of rows) {
    for (const [metric, value] of Object.entries(row.riskMetrics)) {
      metricToValues[metric] = Math.max(metricToValues[metric] ?? 0, Math.abs(value));
    }
  }

  return metricToValues;
};

const legendMaxHight = 80;

interface MarkerOptions {
  color: string;
  marker: {
    radius: number;
    symbol: string;
    lineWidth: number;
    lineColor?: string;
  };
}

function getSolutionMarkerOptions(colorScheme: 'dark' | 'light', solution: SolutionRow): MarkerOptions | undefined {
  if (isGivenPortfolio(solution)) {
    return {
      color: getHighchartColor(colorScheme, 0),
      marker: {
        radius: 6,
        symbol: 'triangle-down',
        lineWidth: 0,
      },
    };
  }

  if (!solution.nonDominated) {
    return {
      color: 'grey',
      marker: {
        radius: 3,
        symbol: 'cross',
        lineWidth: 1,
        lineColor: 'grey',
      },
    };
  }

  if (solution.suggested) {
    return {
      color: getHighchartColor(colorScheme, 3),
      marker: {
        radius: 5,
        symbol: 'circle',
        lineWidth: 0,
      },
    };
  }

  return {
    color: getHighchartColor(colorScheme, 4),
    marker: {
      radius: 3,
      symbol: 'circle',
      lineWidth: 0,
    },
  };
}

const OptimizerCompareSolutions = ({
  optimization,
  nonDominated,
  setNonDominated,
  suggested,
  setSuggested,
  objectives,
}: {
  optimization: PortfolioOptimization | AssetOptimization;
  nonDominated: boolean;
  setNonDominated: Dispatch<SetStateAction<boolean>>;
  suggested: boolean;
  setSuggested: Dispatch<SetStateAction<boolean>>;
  objectives: Objective[];
}): ReactElement => {
  const colorScheme = useFinalColorScheme();
  const { output, givenPortfolioOutput } = optimization;

  const solutions = [...output];
  if (givenPortfolioOutput) {
    solutions.unshift(givenPortfolioOutput);
  }

  const rows = solutions.map((solution): SolutionRow => {
    const risk = Object.fromEntries(solution.riskMeasures.map((risk) => [getFullMetricLabel(risk), risk.value]));
    return {
      id: solution.solutionId,
      name: getSolutionName(solution.solutionId),
      riskMetrics: risk,
      objectiveValues: solution.objectiveValues,
      nonDominated: solution.nonDominated,
      suggested: solution.suggested,
    };
  });

  const metricToMaxValue = calculateMaxMetric(rows);

  const columns: (ColDef<SolutionRow> | ColGroupDef<SolutionRow>)[] = useMemo(
    () => [
      {
        headerName: 'Solution',
        field: 'name',
        lockVisible: true,
        lockPinned: true,
      },
      {
        headerName: 'Risk metrics',
        marryChildren: true,
        children: metrics.map(
          (met): ColDef<SolutionRow> => ({
            headerName: getName(met),
            type: ['numericColumn'],
            colId: met,
            valueGetter: (params: ValueGetterParams<SolutionRow, string>): number | undefined => {
              if (!params.data) {
                return undefined;
              }

              return params.data.riskMetrics[met];
            },
            valueFormatter: (params): string => formatterForName(getFormat(met))(params.value),
          })
        ),
      },
      {
        headerName: 'Objectives',
        marryChildren: true,
        children: objectives.map((objective, i) => ({
          headerName: getObjectiveLabel(objective, 'short'),
          type: ['numericColumn', 'extendedNumericColumn'],
          valueGetter: (params: ValueGetterParams<SolutionRow, string>): number | undefined => {
            if (!params.data) {
              return undefined;
            }

            return params.data.objectiveValues[i]?.value;
          },
        })),
      },
    ],
    [objectives]
  );

  const [visiblePolarChartMetrics, setVisiblePolarChartMetrics] = useState<string[]>(metrics);

  const [[xAxisObjectiveIndex, yAxisObjectiveIndex], setSelectedObjectivesForXYAxis] = useState<[number, number]>([
    0, 1,
  ]);
  const showObjectiveValuesCompareChart = objectives.length > 1;
  const xAxisObjectiveLabel = showObjectiveValuesCompareChart
    ? getObjectiveLabel(objectives[xAxisObjectiveIndex], 'short')
    : '';
  const yAxisObjectiveLabel = showObjectiveValuesCompareChart
    ? getObjectiveLabel(objectives[yAxisObjectiveIndex], 'short')
    : '';

  return (
    <>
      <Stack gap={defaultRowSpacing} direction="row">
        <Typography level="h3">Compare solutions</Typography>
        <Stack marginLeft="auto" direction="row" gap={defaultRowSpacing}>
          <GSwitch label="Non-dominated" value={nonDominated} onChange={(e) => setNonDominated(e.target.checked)} />
          <GSwitch label="Suggested" value={suggested} onChange={(e) => setSuggested(e.target.checked)} />
        </Stack>
      </Stack>
      <Grid container>
        {showObjectiveValuesCompareChart && (
          <Grid md={6} xs={12}>
            <Card style={{ height: '100%' }}>
              <Stack gap={defaultRowSpacing} direction="row" justifyContent="space-evenly">
                <StaticSingleAutocomplete
                  label="X-axis"
                  value={xAxisObjectiveIndex}
                  onChange={(newXObjective): void => {
                    if (!isNil(newXObjective)) {
                      // if new X objective is the same as the current Y objective, swap them
                      const newYObjective =
                        yAxisObjectiveIndex === newXObjective ? xAxisObjectiveIndex : yAxisObjectiveIndex;
                      setSelectedObjectivesForXYAxis([newXObjective, newYObjective]);
                    }
                  }}
                  width="xl3"
                  options={objectives.map((objective, i) => {
                    const label = getObjectiveLabel(objective, 'short');
                    return { value: i, key: label, label, searchText: label };
                  })}
                />
                <StaticSingleAutocomplete
                  label="Y-axis"
                  value={yAxisObjectiveIndex}
                  onChange={(newYObjective): void => {
                    if (!isNil(newYObjective)) {
                      // if new Y objective is the same as the current X objective, swap them
                      const newXObjective =
                        xAxisObjectiveIndex === newYObjective ? yAxisObjectiveIndex : xAxisObjectiveIndex;
                      setSelectedObjectivesForXYAxis([newXObjective, newYObjective]);
                    }
                  }}
                  width="xl3"
                  options={objectives.map((objective, i) => {
                    const label = getObjectiveLabel(objective, 'short');
                    return { value: i, key: label, label, searchText: label };
                  })}
                />
              </Stack>
              {/* chart displays one point for each solution, point XY coordinates are selected objective values (pareto chart) */}
              <HighChartsWrapper
                data={rows}
                loading={false}
                calculateChartData={(data): HighchartSeries[] =>
                  data.map((row) => ({
                    name: row.name,
                    type: 'scatter' as const,
                    ...getSolutionMarkerOptions(colorScheme, row),
                    data: [
                      {
                        x: row.objectiveValues[xAxisObjectiveIndex].value,
                        y: row.objectiveValues[yAxisObjectiveIndex].value,
                      },
                    ],
                  }))
                }
                calculateOptions={(): Highcharts.Options => ({
                  boost: {
                    // chart boost issue: >=50 series - markers shown only on hover
                    seriesThreshold: Number.MAX_VALUE,
                  },
                  chart: {
                    type: 'scatter',
                  },
                  legend: {
                    maxHeight: legendMaxHight,
                  },

                  xAxis: {
                    title: {
                      text: xAxisObjectiveLabel,
                    },
                    minPadding: 0.05,
                    maxPadding: 0.07, // extra padding because of menu icon
                  },
                  yAxis: {
                    title: {
                      text: yAxisObjectiveLabel,
                    },
                  },
                  tooltip: {
                    formatter: function (): string {
                      const solution = rows.find((row) => row.name === this.series.name)!;
                      const remainingObjectives = solution.objectiveValues
                        .map((objective, index) => ({ value: objective.value, index }))
                        .filter(({ index }) => index !== xAxisObjectiveIndex && index !== yAxisObjectiveIndex);

                      let category = '';
                      if (!solution.nonDominated && !isGivenPortfolio(solution)) {
                        category = '(dominated)';
                      }

                      if (solution.suggested) {
                        category = '(suggested)';
                      }

                      const tooltip = [
                        `${defaultTooltipPointColor(this.point.color)}&nbsp;<b>${this.series.name}</b>&nbsp${category}`,
                        `${xAxisObjectiveLabel}: ${formatNumber(this.x)}`,
                        `${yAxisObjectiveLabel}: ${formatNumber(this.y)}`,
                      ];

                      if (remainingObjectives.length > 0) {
                        tooltip.push('Remaining objectives:');
                        tooltip.push(
                          ...remainingObjectives.map(
                            ({ value, index }) =>
                              `${getObjectiveLabel(objectives[index], 'short')}: ${formatNumber(value)}`
                          )
                        );
                      }

                      return tooltip.filter(Boolean).join('<br>');
                    },
                  },
                })}
              />
            </Card>
          </Grid>
        )}
        <Grid md={showObjectiveValuesCompareChart ? 6 : 12} xs={12}>
          <Card style={{ height: '100%' }}>
            <HighChartsWrapper
              data={rows}
              loading={false}
              calculateChartData={(data): HighchartSeries[] =>
                data.map(
                  (solution): HighchartSeries => ({
                    name: solution.name,
                    data: metrics
                      .filter((metric) => visiblePolarChartMetrics.includes(metric))
                      .map((met) => {
                        const value = solution.riskMetrics[met];
                        const formatter = formatterForName(getFormat(met));
                        return {
                          y: !isNil(value) ? value / metricToMaxValue[met] : undefined,
                          textValue: formatter(value),
                        };
                      }),
                    pointPlacement: 'on', // make charts first category point north, to the header
                    type: 'line',
                  })
                )
              }
              calculateOptions={(): Highcharts.Options => ({
                boost: {
                  // polar chart boost issue: >=50 series cause plot misalignment to the left top corner
                  seriesThreshold: Number.MAX_VALUE,
                },
                chart: {
                  polar: true,
                  type: 'line',
                  height: 510, // align by bottom with pareto chart, pareto takes less space because of objective dropdowns
                },
                xAxis: {
                  categories: metrics
                    .filter((metric) => visiblePolarChartMetrics.includes(metric))
                    .map((met) => getName(met)),
                  opposite: true,
                  lineWidth: 0,
                },
                yAxis: {
                  gridLineInterpolation: 'polygon',
                  lineWidth: 0,
                  labels: {
                    enabled: false,
                  },
                },
                ...tooltipFormat,
                pane: {
                  size: '80%',
                },
                legend: {
                  maxHeight: legendMaxHight,
                },
              })}
            />
          </Card>
        </Grid>
      </Grid>
      <Box height={GRID_MIN_HEIGHT_PX}>
        <GAgGrid
          rowData={rows}
          columnDefs={columns}
          defaultColDef={defaultCol}
          autoSizeStrategy={{ type: 'fitCellContents' }}
          onColumnVisible={(e) => {
            const visibleMetricsColumns = e.api
              .getColumnState()
              .filter((col) => col.colId.startsWith('pmet:') && !col.hide)
              .map((col) => col.colId);
            // polar chart with less than 3 metrics is not readable
            setVisiblePolarChartMetrics(visibleMetricsColumns.length < 3 ? metrics : visibleMetricsColumns);
          }}
          sideBar={sideBar}
          pivotPanelShow="never"
        />
      </Box>
    </>
  );
};

export default OptimizerCompareSolutions;
