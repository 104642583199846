import { Stack } from '@mui/joy';
import type { ColDef } from 'ag-grid-community';
import type { ReactElement } from 'react';
import GButton from 'components/technical/inputs/GButton/GButton.tsx';
import type { PortfolioRow } from '../initialPortfolio/InitialPortfolioStep.tsx';
import { defaultRowSpacing } from '../../../../../StackSpacing.ts';
import Universe, { createInInitialPortfolioColumn } from '../../universe/Universe.tsx';
import {
  portfolioDefinitionNameColumn,
  portfolioDefinitionRebalancingRuleColumn,
  portfolioDefinitionTypeColumn,
} from '../PortfolioDefinitionSharedColumns.ts';
import { useFormContext } from 'react-hook-form';
import type { AssetOptimizerInputFields } from '../../asset/AssetOptimizer.validation.ts';

const PortfolioUniverseStep = ({
  optimizableFunds,
  goToNextStep,
}: {
  optimizableFunds: PortfolioRow[];
  goToNextStep: () => void;
}): ReactElement => {
  const { watch } = useFormContext<AssetOptimizerInputFields>();
  const givenPortfolio = watch('givenPortfolio');

  const columns: ColDef<PortfolioRow>[] = [
    portfolioDefinitionNameColumn,
    portfolioDefinitionTypeColumn,
    portfolioDefinitionRebalancingRuleColumn,
    createInInitialPortfolioColumn(givenPortfolio),
  ];

  return (
    <Stack spacing={defaultRowSpacing}>
      <Universe columnDefs={columns} rowData={optimizableFunds} />
      <GButton onClick={goToNextStep} sx={{ marginLeft: 'auto' }}>
        Next
      </GButton>
    </Stack>
  );
};

export default PortfolioUniverseStep;
