import { useState, type ReactElement, type ComponentType } from 'react';
import type { SxProps } from '@mui/joy/styles/types';
import type { RenderDialogFunction } from '../GButton/DialogButton';

type WithDialogProps = {
  renderDialog: RenderDialogFunction;
  sx?: SxProps;
};

const WithDialog = <P extends object>(WrappedComponent: ComponentType<P>) => {
  return function WithDialogComponent({ renderDialog, sx, ...props }: WithDialogProps & P): ReactElement {
    // biome-ignore lint/correctness/useHookAtTopLevel: WithDialogComponent is hoc with component as an argument
    const [open, setOpen] = useState(false);

    return (
      <>
        <WrappedComponent onClick={() => setOpen(true)} {...(props as P)} sx={sx} />

        {open &&
          renderDialog({
            onClose: () => setOpen(false),
          })}
      </>
    );
  };
};

export default WithDialog;
