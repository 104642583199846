import type { ReactElement } from 'react';
import { useForm } from 'react-hook-form';
import { type FormInputFields, type FormOutputFields, schema } from './MarketRegimeForm.validation.ts';
import gYupResolver from '../../technical/form/gYupResolver.ts';
import GFormProvider from '../../technical/form/GFormProvider.tsx';
import { Stack } from '@mui/joy';
import { defaultRowSpacing } from '../../StackSpacing.ts';
import SubmitButton from '../../technical/form/SubmitButton.tsx';
import type { StaticAutocompleteOption } from '../../technical/inputs/Autocomplete/StaticSingleAutocomplete.props.ts';
import { HEIGHT_PX } from '../../copilot/lab/PortfolioDefinitionLabel.tsx';
import FormStaticMultiAutocomplete from '../../technical/form/FormStaticMultiAutocomplete.tsx';
import type { NotVerifiedAsset } from '../asset/AssetLabelService.ts';
import { createAssetSelectOptions } from '../asset/AssetService.tsx';
import { FormDateInput } from '../../technical/form/FormDateInput.tsx';
import dayjs from 'dayjs';
import uniqBy from 'lodash/fp/uniqBy';

interface Asset extends NotVerifiedAsset {
  id: string;
  symbol: string;
}
export interface ModelData {
  id: string;
  name: string;
  benchmark?: { id: string; symbol: string } | null;
}

const createModelOptions = (
  models: ModelData[]
): {
  options: StaticAutocompleteOption<ModelData>[];
  optionHeight: number;
  limitTags: number;
  isValueEqual: (a: ModelData | undefined | null, b: ModelData | undefined | null) => boolean;
} => {
  return {
    options: models.map((mod) => ({
      label: mod.name,
      value: mod,
      searchText: mod.name,
      key: mod.id,
    })),
    optionHeight: HEIGHT_PX,
    limitTags: 2,
    isValueEqual: (a, b) => (!a && !b) || a?.id === b?.id,
  };
};

const MarketRegimeForm = ({
  onSubmit,
  models,
  availableAssets,
}: {
  onSubmit: (val: FormOutputFields) => void;
  models: ModelData[];
  availableAssets: Asset[];
}): ReactElement => {
  const modelOptions = createModelOptions(models);
  const assetOptions = createAssetSelectOptions<{ id: string; symbol: string; name?: string | null }>(availableAssets);

  const methods = useForm<FormInputFields>({
    resolver: gYupResolver(schema),
    defaultValues: {
      since: dayjs.utc().subtract(30, 'day'),
      to: null,
      models: models.length === 1 ? [models[0]] : [],
      assets: models.length === 1 && models[0].benchmark ? [models[0].benchmark] : [],
    },
  });

  const handleFormSubmit = async (input: FormInputFields): Promise<void> => {
    onSubmit({ ...(input as unknown as FormOutputFields) });
  };

  return (
    <GFormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(handleFormSubmit)}>
        <Stack gap={defaultRowSpacing}>
          <Stack direction={'row'} flexWrap={'wrap'} columnGap={1} rowGap={defaultRowSpacing}>
            <FormDateInput
              showClearable
              width="normal"
              name="since"
              label="From"
              onChange={() => {
                methods.trigger('to');
              }}
            />
            <FormDateInput width="normal" name="to" label="To" showClearable />
          </Stack>
          <Stack direction={'row'} flexWrap={'wrap'} columnGap={1} rowGap={defaultRowSpacing}>
            <FormStaticMultiAutocomplete<FormInputFields, 'models'>
              name="models"
              label="Model"
              width="xl2"
              showLabelAboveInput={false}
              {...modelOptions}
              onChange={(models) => {
                const assets = methods.getValues('assets');
                const lastModel = models.at(-1);
                const additionalBenchmark = lastModel?.benchmark ? [lastModel.benchmark] : [];
                const combined = [...assets, ...additionalBenchmark];
                methods.setValue('assets', uniqBy('id', combined));
              }}
            />
            <FormStaticMultiAutocomplete<FormInputFields, 'assets'>
              label="Benchmark"
              width="xl2"
              name="assets"
              showClearable
              onChange={(value) => {
                const selectedAssets = methods.getValues('assets');
                const combined = uniqBy('id', [...selectedAssets, ...value]);
                methods.setValue('assets', combined as FormInputFields['assets']);
              }}
              {...assetOptions}
              limitTags={4}
            />
          </Stack>
          <SubmitButton width={'small'}>Run</SubmitButton>
        </Stack>
      </form>
    </GFormProvider>
  );
};

export default MarketRegimeForm;
