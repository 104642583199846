import { Stack } from '@mui/joy';
import dayjs from 'dayjs';
import { type FunctionComponent, useState } from 'react';
import { convertDateInUtcToUTCISODate } from 'components/date.utils';
import { Select } from 'components/technical/inputs';
import { useDefaultErrorHandling } from 'components/technical/UseDefaultErrorHandling';
import { type IExchangesQuery, useOptionsQuery } from 'generated/graphql';

import OptionsList from './OptionsList';
import { createVenueOptions } from '../../../../../venue/VenueService.tsx';

const DEFAULT_SELECTED_VENUE = 'cex:deribit';

const OptionsListContainer: FunctionComponent<{ venue: string; assetId: string }> = ({ venue, assetId }) => {
  const { loaded, data, Fallback } = useDefaultErrorHandling(
    useOptionsQuery({
      variables: {
        baseAssetId: assetId,
        exchanges: [venue],
        expirationSince: convertDateInUtcToUTCISODate(dayjs().utc().add(1, 'day')).toString(),
      },
    })
  );

  if (!loaded) {
    return <Fallback />;
  }

  return <OptionsList pricedOptions={data.assets.options.pricedOptions} venue={venue} />;
};

const OptionsTab: FunctionComponent<{
  venues: IExchangesQuery['assets']['options']['exchanges'];
  assetId: string;
}> = ({ venues, assetId }) => {
  const venueLabels = venues.map(({ label }) => label);
  const venueOptions = createVenueOptions(venueLabels);
  const [selectedVenue, setSelectedVenue] = useState(DEFAULT_SELECTED_VENUE);

  return (
    <Stack gap={1.5}>
      <Select
        value={selectedVenue}
        options={venueOptions}
        onChange={(val): void => setSelectedVenue(val)}
        width="normal"
      />
      <OptionsListContainer venue={selectedVenue} assetId={assetId} />
    </Stack>
  );
};

export default OptionsTab;
