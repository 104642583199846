import { Box, Tooltip } from '@mui/joy';
import FormControl from '@mui/joy/FormControl';
import Input from '@mui/joy/Input';
import type { ChangeEvent, ReactElement } from 'react';
import type { GInputProps } from './GInput.props';
import InputError from '../InputError';
import InputLabel from '../InputLabel';
import { calculatePlaceholder, shouldRenderInputLabel } from '../LabelService.ts';
import widthSx from '../../../width.styles.ts';

export const NO_LABEL_HEIGHT_PX = 32;

const GInput = (props: GInputProps): ReactElement => {
  // we don't want to pass required prop to Input - it enables default browser error handling and we don't get
  // validation errors for other fields
  const { label, placeholder, showLabelAboveInput, error, errorInTooltip, required, width, ref, ...rest } = props;
  const allWidthSx = {
    fullWidth: {
      width: '100%',
    },
    ...widthSx,
  };

  const input = (
    <Box sx={allWidthSx[props.width ?? 'fullWidth']}>
      <FormControl error={!!props.error} disabled={props.disabled} size={props.size} color={props.color}>
        {shouldRenderInputLabel(props) ? <InputLabel label={label} width={width} /> : <></>}
        <Input
          placeholder={calculatePlaceholder(props)}
          {...rest}
          error={!!props.error}
          fullWidth
          slotProps={{
            input: {
              ref: ref,
            },
            ...rest.slotProps,
          }}
          onChange={(e: ChangeEvent<HTMLInputElement>): void => {
            if (props.type === 'number' && e.target.value === '' && (e.target as HTMLInputElement).validity.valid) {
              props.onChange?.(null);
              return;
            }

            props.onChange?.(e.target.value);
          }}
        />
        {!errorInTooltip && <InputError error={props.error} />}
      </FormControl>
    </Box>
  );

  return errorInTooltip ? (
    <Tooltip title={error} color="danger">
      {input}
    </Tooltip>
  ) : (
    input
  );
};

export default GInput;
