import type { BacktestingConfiguration } from '../BacktestConfiguration.types.ts';
import type { ReactElement } from 'react';
import SectionColumn from '../../../../technical/layout/Column/SectionColumn.tsx';
import HeaderBar from '../../../../technical/HeaderBar/HeaderBar.tsx';
import MarketRegimeResult from '../../../../market/regime/MarketRegimeResult.tsx';
import { usePortfolioRegimeResultSuspenseQuery } from '../../../../../generated/graphql.tsx';
import { PortfolioRegimeWarning } from './PortfolioRegimeWarning.tsx';
import { PortfolioRegimeMetricGrid } from './PortfolioRegimeMetricGrid.tsx';
import PortfolioRegimeReturns from './PortfolioRegimeReturns.tsx';

const PortfolioRegimeResult = ({
  backtestingConfig,
  regime,
}: {
  backtestingConfig: BacktestingConfiguration;
  regime: { id: string; regimes: string[]; name: string };
}): ReactElement => {
  const result = usePortfolioRegimeResultSuspenseQuery({
    variables: {
      input: {
        dateRange: {
          since: backtestingConfig.range.since,
          to: backtestingConfig.range.to,
        },
        portfolioDefinitionIds: backtestingConfig.portfolios.map((p) => p.id),
        regimeDefinitionId: regime.id,
        requestedRegimes: regime.regimes,
      },
    },
  });

  const risk = result.data.portfolio.regimeRisk;

  return (
    <>
      <SectionColumn>
        <PortfolioRegimeWarning risk={risk} />
        <HeaderBar title="Regime probability" />
        <MarketRegimeResult
          input={{
            since: backtestingConfig.range.since,
            to: backtestingConfig.range.to,
            models: [regime],
            assets: [],
          }}
        />
      </SectionColumn>
      <SectionColumn>
        <HeaderBar title="Metrics" />
        <PortfolioRegimeMetricGrid risk={risk} />
        <HeaderBar title="Returns" />
        <PortfolioRegimeReturns risk={risk} />
      </SectionColumn>
    </>
  );
};

export default PortfolioRegimeResult;
