import { type BigNumber, bignumber } from 'mathjs';
import { usePrecisePriceQuery } from '../../../generated/graphql.tsx';
import { isValidDayjsDate } from '../../date.utils.ts';
import dayjs, { type Dayjs } from 'dayjs';
import isNil from 'lodash/fp/isNil';

export type MarketAutocompleteQuery =
  | {
      loading: true;
      error: boolean;
      price: undefined;
    }
  | {
      loading: false;
      error: true;
      price: undefined;
    }
  | {
      loading: false;
      error: false;
      price?: {
        value: BigNumber;
        time: string;
      };
    };

export function useMarketValueAutocomplete(
  time: Dayjs | null | undefined,
  assetId: string | undefined | null
): MarketAutocompleteQuery {
  const isValidDate = !isNil(time) && isValidDayjsDate(time);
  const { data, loading, error } = usePrecisePriceQuery({
    variables: {
      assetId: assetId ?? '',
      time: isValidDate ? dayjs(time).toISOString() : '',
    },
    skip: !isValidDate || !assetId,
  });

  if (loading) {
    return {
      loading,
      error: error !== undefined,
      price: undefined,
    };
  }

  if (data === undefined) {
    return {
      loading: false,
      error: true,
      price: undefined,
    };
  }

  if (data.assets.precisePrice) {
    return {
      price: {
        value: bignumber(data.assets.precisePrice.price),
        time: data.assets.precisePrice.time,
      },
      loading: false,
      error: false,
    };
  }

  return { price: undefined, loading: false, error: false };
}
