import { Card, Stack } from '@mui/joy';
import dayjs, { type Dayjs } from 'dayjs';
import { uniq } from 'lodash/fp';
import isNil from 'lodash/fp/isNil';
import { type FunctionComponent, useState } from 'react';
import { parseUtcDate } from 'components/date.utils';
import { formatPercentage } from 'components/formatter.utils';
import { getDefaultRange } from 'components/predefinedDateRanges.ts';
import { defaultRowSpacing } from 'components/StackSpacing.ts';
import ActionsHeaderBar from 'components/technical/actions/ActionsHeaderBar';
import {
  dateTimeAxisFormat,
  dateTimeExportFormat,
  type HighchartSeries,
  tooltipFormat,
} from 'components/technical/charts/HighChartsWrapper/Highchart.utils';
import HighChartsContainer from 'components/technical/charts/HighChartsWrapper/HighChartsWrapper';
import { Select } from 'components/technical/inputs';
import StaticMultiAutocomplete from 'components/technical/inputs/Autocomplete/StaticMultiAutocomplete.tsx';
import PredefinedDateRangeButtonsWithCalendar from 'components/technical/inputs/date/PredefinedDateRangeButtonsWithCalendar.tsx';
import { useSubAccountAssetFilters } from 'components/technical/SubAccountAssetFilterDrawer/UseSubAccountAssetFilters.tsx';
import {
  type IReturnsAndAttributionQuery,
  type ISupportedDateTimeWindow,
  useReturnsAndAttributionQuery,
} from 'generated/graphql';

import { DEFAULT_CLUSTER } from './attribution/PerformanceAttribution.tsx';
import { getClusterOptions } from '../../market/asset/groups/GroupService.ts';
import { convertDateRangeToSinceToDate } from 'components/technical/inputs/date/dateRange.utils.ts';
import type Highcharts from 'highcharts';

type ReportSide = 'long' | 'short' | 'total';
const allSidesInOrder = ['long', 'short', 'total'] as const;

const calculateChartData =
  (side: ReportSide) =>
  (data: IReturnsAndAttributionQuery): HighchartSeries[] => {
    const performanceClusterAttributionsDaily = data.portfolio.performanceClusterAttributionsDaily[side];

    const categories = uniq(
      performanceClusterAttributionsDaily.flatMap((row) => row.categoryValues.map((cat) => cat.category))
    );

    const chartData: HighchartSeries[] = [
      {
        name: 'Returns',
        data: performanceClusterAttributionsDaily.map((row) => {
          const value = row.categoryValues.reduce((acc, cur) => acc + cur.value, 0);

          return {
            x: dayjs.utc(row.date.toString()).valueOf(),
            y: value * 100,
            textValue: formatPercentage(value),
          };
        }),
        type: 'line',
        zIndex: 1,
      },
      ...categories.map((category) => ({
        data: performanceClusterAttributionsDaily.map((row) => {
          const categoryValues = row.categoryValues.find((cat) => cat.category === category);
          return {
            x: dayjs.utc(row.date.toString()).valueOf(),
            y: !isNil(categoryValues) ? categoryValues.value * 100 : 0,
            textValue: formatPercentage(categoryValues?.value ?? 0),
          };
        }),
        name: category,
        type: 'column' as const,
      })),
    ];

    return chartData;
  };

const calculateOptions = (side: ReportSide) => (): Highcharts.Options => {
  return {
    ...dateTimeAxisFormat,
    ...dateTimeExportFormat('performance-attribution'),
    ...tooltipFormat,
    plotOptions: {
      column: {
        stacking: 'normal',
      },
    },
    yAxis: {
      labels: {
        format: '{value:.2f}%',
      },
      title: {
        text: `Daily returns - ${side}`,
      },
    },
  };
};

export const ATTRIBUTION_CHART_TITLE = 'Performance attribution';

export type PerformanceAttributionChartProps = {
  supportedTimeWindow: Pick<ISupportedDateTimeWindow, 'since' | 'until'> | undefined | null;
  supportedClusters: string[];
};

const PerformanceAttributionChart: FunctionComponent<PerformanceAttributionChartProps> = ({
  supportedClusters,
  supportedTimeWindow,
}) => {
  const classificationOptions = getClusterOptions(supportedClusters);

  const defaultDateRange = getDefaultRange();
  const [dateRange, setDateRange] = useState<[Dayjs, Dayjs] | null>(defaultDateRange.value);
  const [sides, setSides] = useState<ReportSide[]>(['total']);

  const [classification, setClassification] = useState<string>(
    classificationOptions.find((item) => item.value === DEFAULT_CLUSTER)!.value
  );

  const { subAccountAssetFilters } = useSubAccountAssetFilters();

  const queryOutput = useReturnsAndAttributionQuery({
    variables: {
      ...convertDateRangeToSinceToDate(dateRange),
      clusterType: classification,
      subAccountAssetFilters,
    },
  });

  return (
    <Stack spacing={1.5}>
      <ActionsHeaderBar title={ATTRIBUTION_CHART_TITLE} />
      <Card>
        <Stack spacing={1.5}>
          <Stack direction="row" justifyContent="space-between" alignItems="flex-end" spacing={defaultRowSpacing}>
            <Select
              value={classification}
              onChange={(value): void => setClassification(value)}
              options={classificationOptions}
              width="minContent"
            />
            <StaticMultiAutocomplete
              width="xl2"
              onChange={setSides}
              value={sides}
              limitTags={2}
              options={[
                {
                  label: 'Total',
                  searchText: 'Total',
                  value: 'total',
                  key: 'Total',
                },
                {
                  label: 'Short',
                  searchText: 'Short',
                  value: 'short',
                  key: 'Short',
                },
                {
                  label: 'Long',
                  searchText: 'Long',
                  value: 'long',
                  key: 'Long',
                },
              ]}
            />
            <PredefinedDateRangeButtonsWithCalendar
              {...(supportedTimeWindow && {
                minDate: parseUtcDate(supportedTimeWindow.since),
                maxDate: parseUtcDate(supportedTimeWindow.until),
              })}
              defaultValue={defaultDateRange}
              onChange={(val): void => setDateRange(val)}
            />
          </Stack>
          <Stack spacing={2}>
            {allSidesInOrder.map((side) =>
              sides.includes(side) ? (
                <HighChartsContainer<IReturnsAndAttributionQuery>
                  {...queryOutput}
                  key={`${classification}-${side}`}
                  calculateOptions={calculateOptions(side)}
                  calculateChartData={calculateChartData(side)}
                />
              ) : null
            )}
          </Stack>
        </Stack>
      </Card>
    </Stack>
  );
};

export default PerformanceAttributionChart;
