import isEqual from 'lodash/fp/isEqual';
import { useEffect, useRef, useState } from 'react';

import type { GTablePaginator } from './GTable.props.ts';

export const DEFAULT_PAGE_SIZES = [10, 25, 50];

export const useTablePaginator = ({
  filters,
  defaultPageSize,
}: {
  filters?: unknown;
  defaultPageSize?: number;
}): {
  tablePaginator: GTablePaginator;
  page: {
    limit: number;
    offset: number;
  };
} => {
  const previousFilters = useRef<unknown>(null);
  const [{ pageIndex, pageSize }, setPagination] = useState({
    pageIndex: 0,
    pageSize: defaultPageSize ?? DEFAULT_PAGE_SIZES[0],
  });

  useEffect(() => {
    if (!isEqual(filters, previousFilters.current)) {
      previousFilters.current = filters;
      setPagination({
        pageIndex: 0,
        pageSize,
      });
    }
  }, [filters, pageSize]);

  return {
    tablePaginator: {
      state: {
        pageIndex,
        pageSize,
      },
      onChange: setPagination,
    },
    page: {
      offset: pageIndex * pageSize,
      limit: pageSize,
    },
  };
};

export const pageOptions = DEFAULT_PAGE_SIZES.map((value) => ({
  value: value.toString(),
  label: <>Per page {value.toString()}</>,
  key: value.toString(),
}));
