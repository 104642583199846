import { FormControl } from '@mui/joy';
import type { Dayjs } from 'dayjs';
import { type ForwardedRef, type FunctionComponent, useState } from 'react';

import 'react-datepicker/dist/react-datepicker.css';
import type { BaseDateInputProps } from './BaseDateInputProps';
import DateInputCalendar from './DateInputCalendar';
import { DateInputDropdown } from './DateInputDropdown.tsx';
import InputError from '../InputError';

export type DateTimeInputProps = BaseDateInputProps & {
  value: Dayjs | null;
  onChange?: (val: Dayjs | null) => void;
  ref?: ForwardedRef<HTMLInputElement>;
};

const DateTimeInput: FunctionComponent<DateTimeInputProps> = (props) => {
  const { onChange, minDate, maxDate, value, showTime, ref, ...rest } = props;
  const [open, setOpen] = useState(false);

  const format = showTime ? 'dateTime' : 'date';
  return (
    <FormControl error={Boolean(props.error)}>
      <DateInputDropdown
        {...rest}
        format={format}
        value={value}
        setOpen={setOpen}
        open={open}
        onChange={onChange}
        ref={ref}
      >
        <DateInputCalendar
          disabled={props.disabled}
          minDate={minDate ?? undefined}
          maxDate={maxDate ?? undefined}
          defaultValue={value}
          onConfirm={(newValue): void => {
            onChange?.(newValue);
            setOpen(false);
          }}
          onCancel={(): void => {
            setOpen(false);
          }}
          time={showTime}
        />
      </DateInputDropdown>
      <InputError error={props.error} />
    </FormControl>
  );
};

export default DateTimeInput;
