import { type ApolloClient, useApolloClient } from '@apollo/client';
import isNil from 'lodash/fp/isNil';
import { useEffect, useRef, useState } from 'react';
import { useMatches } from 'react-router';
import { v4 as uuid } from 'uuid';

import type { Handle, MatchedRoute, ResolvedRoute } from './Route.types';

const ignoredPaths = ['/', '*'];

type HandleWithRequiredBreadcrumb = Omit<Handle, 'breadcrumb'> & { breadcrumb: NonNullable<Handle['breadcrumb']> };

const calculateBreadcrumbResolvedPaths = (
  routes: MatchedRoute[],
  apolloClient: ApolloClient<object>
): Promise<ResolvedRoute>[] => {
  return routes
    .filter(
      (route): route is Omit<MatchedRoute, 'handle'> & { handle: HandleWithRequiredBreadcrumb } =>
        !!route.pathname && !isNil(route.handle?.breadcrumb) && !ignoredPaths.includes(route.pathname)
    )
    .map(async (routeMatch) => {
      const rest = {
        title: routeMatch.handle?.title,
        pathname: routeMatch.pathname,
        docs: routeMatch.handle.docs,
      };

      const breadcrumb = routeMatch.handle.breadcrumb;
      if (!breadcrumb) {
        return {
          breadcrumb: '',
          ...rest,
        };
      }

      if (typeof breadcrumb === 'string') {
        return {
          breadcrumb: breadcrumb,
          ...rest,
        };
      }

      const resolvedBreadcrumb = await breadcrumb(routeMatch.params, apolloClient);

      return {
        breadcrumb: resolvedBreadcrumb,
        ...rest,
      };
    });
};

export const useBreadcrumbMatch = (): ResolvedRoute[] => {
  const [crumbs, setCrumbs] = useState<ResolvedRoute[]>([]);
  const routes = useMatches() as MatchedRoute[];
  const lastId = useRef(uuid());
  const apolloClient = useApolloClient();

  useEffect(() => {
    const id = uuid();
    lastId.current = id;
    const generateCrumbs = async (): Promise<void> => {
      const resolvedRoutes: ResolvedRoute[] = await Promise.all(calculateBreadcrumbResolvedPaths(routes, apolloClient));
      if (id === lastId.current) {
        setCrumbs(resolvedRoutes);
      }
    };

    generateCrumbs();
  }, [routes, apolloClient]);

  return crumbs;
};
