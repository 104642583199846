import { Stack } from '@mui/joy';
import { bignumber } from 'mathjs';
import type { FunctionComponent } from 'react';
import { UserSettings } from 'components/management/UserSettings.types';
import GAgGridPresets from 'components/technical/grids/GAgGridPresets';
import { useSubAccountAssetFilters } from 'components/technical/SubAccountAssetFilterDrawer/UseSubAccountAssetFilters';
import { useDefaultErrorHandling } from 'components/technical/UseDefaultErrorHandling';
import { type IOpenPositionsDerivativesQuery, useOpenPositionsDerivativesQuery } from 'generated/graphql';

import { positionsDerivativesDefaultPresets } from './defaultPresets';
import {
  accountColumn,
  amountColumn,
  balanceColumn,
  clusterColumn,
  exposureColumn,
  initialMarginColumn,
  leverageColumn,
  maintenanceMarginColumn,
  nameColumn,
  notionalColumn,
  priceChangesGroupColumns,
  sideColumn,
  subAccountColumn,
  subFundGroupColumn,
  symbolColumn,
  underlyingAssetColumn,
  unitEntryPriceColumn,
  unitMarketPriceColumn,
  unrealizedPnlColumn,
  venueColumn,
  weightColumn,
} from '../../technical/grids/SharedReportColumns';
import { usePriceChanges } from './UsePriceChanges';
import { useReportAssetGroup } from '../../UseReportAssetGroups';
import { mapSubAccountAndDimensionToSubFund } from 'components/bookkeeping/report/Report.utils';

const GROUP_COLUMN_MIN_WIDTH = 220;
type RowData = IOpenPositionsDerivativesQuery['portfolio']['positions']['positions'][number];
const PositionsDerivatives: FunctionComponent = () => {
  const { subAccountAssetFilters } = useSubAccountAssetFilters();
  const derivativePositionsQueryResult = useDefaultErrorHandling(
    useOpenPositionsDerivativesQuery({
      variables: {
        subAccountAssetFilters,
      },
    })
  );

  const reportAssetGroup = useReportAssetGroup();

  const assetIds =
    derivativePositionsQueryResult.data?.portfolio.positions.positions.map((position) => position.asset.id) ?? [];

  const pricesChangesResult = usePriceChanges(assetIds);

  if (!derivativePositionsQueryResult.loaded) {
    return <derivativePositionsQueryResult.Fallback />;
  }
  if (!reportAssetGroup.loaded) {
    return <reportAssetGroup.Fallback />;
  }
  if (!pricesChangesResult.loaded) {
    return <pricesChangesResult.Fallback />;
  }

  const filteredReportData = derivativePositionsQueryResult.data.portfolio.positions.positions.filter((position) =>
    Boolean(position.derivative)
  );

  const totalBalance = bignumber(derivativePositionsQueryResult.data.portfolio.positions.summary.balance.total);

  const subFunds = derivativePositionsQueryResult.data.portfolio.subFunds.list;
  const { subAccountAndDimensionToSubFund, subFundDimensions } = mapSubAccountAndDimensionToSubFund(subFunds);

  return (
    <Stack spacing={1.5} height="100%">
      <GAgGridPresets<RowData>
        defaultPresets={positionsDerivativesDefaultPresets}
        presetSettingsKey={UserSettings.PositionsDerivativesPresets}
        rowData={filteredReportData}
        groupDefaultExpanded={-1}
        sideBar={{
          toolPanels: ['columns', 'filters'],
        }}
        enableCharts
        cellSelection
        autoGroupColumnDef={{
          minWidth: GROUP_COLUMN_MIN_WIDTH,
        }}
        defaultColDef={{
          resizable: true,
          sortable: true,
          filter: true,
        }}
        columnDefs={[
          {
            headerName: 'Asset Details',
            colId: 'asset-details',
            marryChildren: true,
            children: [
              nameColumn(),
              symbolColumn(),
              underlyingAssetColumn<RowData>({
                initialRowGroup: true,
                initialRowGroupIndex: 0,
              }),
              ...reportAssetGroup.clusters.map((cluster) =>
                clusterColumn<RowData>(cluster, reportAssetGroup.assetAndGroupClusterMapToGroup)
              ),
            ],
          },
          {
            headerName: 'Account Details',
            colId: 'account-details',
            marryChildren: true,
            children: [accountColumn(), subAccountColumn(), venueColumn()],
          },
          {
            headerName: 'Sub-funds',
            colId: 'sub-funds',
            marryChildren: true,
            children: subFundDimensions.map((subFundDimension) =>
              subFundGroupColumn(subFundDimension, subAccountAndDimensionToSubFund)
            ),
          },
          sideColumn(),
          {
            headerName: 'Current positions',
            colId: 'currentPositions',
            marryChildren: true,
            children: [
              balanceColumn(),
              exposureColumn({ sideAware: true, initialHide: false }),
              amountColumn({ sideAware: true, initialHide: false }),
              unrealizedPnlColumn(),

              exposureColumn({ sideAware: false, initialHide: true }),
              amountColumn({ sideAware: false, initialHide: true }),
              initialMarginColumn(),
              maintenanceMarginColumn(),
              leverageColumn(),
              notionalColumn(),
              unitEntryPriceColumn(),
              unitMarketPriceColumn(),
              weightColumn(totalBalance),
            ],
          },
          ...priceChangesGroupColumns<RowData>(
            pricesChangesResult.priceSummary,
            pricesChangesResult.priceChanges24hByAssetId
          ),
        ]}
      />
    </Stack>
  );
};

export default PositionsDerivatives;
